import { render, staticRenderFns } from "./conta-corrente.vue?vue&type=template&id=1682f19b&scoped=true&"
import script from "./conta-corrente.vue?vue&type=script&lang=js&"
export * from "./conta-corrente.vue?vue&type=script&lang=js&"
import style0 from "./conta-corrente.vue?vue&type=style&index=0&id=1682f19b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1682f19b",
  null
  
)

export default component.exports