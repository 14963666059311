<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCOPIAREXAMES.COPIARCONFIGS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALCOPIAREXAMES.ATENCAO')}}</h5>
                <p>{{$t('MODALCOPIAREXAMES.ATENCAOAVISO')}}</p>
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALCOPIAREXAMES.COPIARCONFIGS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded
                              v-model="equipamentoSelecionado">
                        <option v-for="(equipamento,index) in filteredEquipamentos" :key="index"
                                :value="equipamento.id">
                            {{ equipamento.id }} - {{ equipamento.nome }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      :loading="salvandoDados"
                      @click="confirmarCopiaExame">{{$t('MODALCOPIAREXAMES.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALCOPIAREXAMES.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            selecionado: Number
        },
        data() {
            return {
                listaEquipamentos: [],
                equipamentoSelecionado: [],
                erroCopiaExame: null,
                salvandoDados: false
            }
        },
        computed: {
            filteredEquipamentos() {
                return this.listaEquipamentos;
            }
        },
        mounted() {
            this.loadListaEquipamentos()
        },
        methods: {
            loadListaEquipamentos() {
                this.$http.get('/api/search/Equipamentos')
                    .then(m => {
                        this.listaEquipamentos = m.data
                    })
            },            
            confirmarCopiaExame() {
                this.salvandoDados = true
                this.$http.get(`/api/interface/copiarExame?equipamentoId=${this.equipamentoSelecionado}&equipamentoAtual=${this.selecionado}`)
                .then((res) => {
                    this.salvandoDados = false
                    if (res.body.erro) {
                        this.erroCopiaExame = res.body.erro
                    } else {
                        this.$emit('loadConfigFunction')
                        this.$emit('close')
                    }
                })
            }
        }
    }
</script>