<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALOBSERVACAOCONVENIO.OBSERVACOESCONVENIO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block has-text-justified">
                    {{ this.observacoesConvenio }}
                </p>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close')">{{$t('MODALOBSERVACAOCONVENIO.FECHAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            observacoesConvenio: String,
        },
    }
</script>