<template>
    <section>
        <div class="is-hero-bar is-main-hero">
            <div class="">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-11">
                                    <label class="label">{{$t('ORCAMENTO.EXAMES')}}</label>
                                    <b-autocomplete class="is-outlined is-fullwidth" :data="data"
                                                    ref="autocomplete"
                                                    v-model="autocomplete"
                                                    field="exameNome"
                                                    :loading="isFetching"
                                                    @typing="getAsyncExame"
                                                    icon="magnify"                                  
                                                    @select="selectItem">
                                        <template slot="empty">
                                            {{$t('ORCAMENTO.EXAMESENCONTRADOS')}}
                                        </template>
                                        <template slot-scope="props">
                                            <div class="is-flex is-justified-between">
                                                <span>{{ props.option.exameNome }}</span>
                                                <span class="tag">{{ props.option.exameApelido }}</span>
                                                <span class="tag">{{ props.option.exameId }}</span>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                </div>

                                <div class="column is-1">
                                    <label class="label" style="visibility: hidden;">{{$t('ORCAMENTO.EXAME')}}</label>
                                    <b-dropdown native-type="button" :triggers="['hover']" aria-role="list" expanded>
                                        <button type="button" native-type="button" class="button is-primary is-fullwidth" slot="trigger">
                                            <b-icon icon="menu-down"></b-icon>
                                        </button>
                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Apelido'">{{$t('ORCAMENTO.APELIDO')}}</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Nome'">{{$t('ORCAMENTO.NOME')}}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div class="columns" v-if="exames != null && exames.length > 0">
                                <div class="panel column">
                                    <div class="panel-heading columns is-mobile">
                                        <div class="column is-6">{{$t('ORCAMENTO.APELIDO')}}</div>
                                        <div class="column is-5 has-text-right">{{$t('ORCAMENTO.PRECO')}}</div>
                                        <div class="column is-1"></div>
                                    </div>
                                    <div class="panel-block columns is-mobile" v-for="(exame, index) in exames" :key="index">
                                        <div class="column is-6">
                                            <b-tag type="is-primary">
                                                {{ exame.exameApelido }}
                                            </b-tag>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            {{ exame.examePreco | currency  }}
                                        </div>
                                        <div class="column is-1 has-text-right">
                                            <b-button
                                                icon-left="delete"
                                                size="is-small"
                                                type="is-danger"
                                                @click.prevent.stop="removerExame(exame, index)"></b-button>
                                        </div>
                                    </div>
                                    <div class="panel-heading columns is-mobile">
                                        <div class="column is-7">{{$t('ORCAMENTO.TOTALEXAME')}}: {{exames.length}}</div>
                                        <div class="column is-5 has-text-right">{{ totalExames | currency }} </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <div class="box">
                                <div class="panel" v-if="gruposExames == null || gruposExames.length == 0">
                                    <div class="panel-block">
                                        <b-skeleton width="20%" animated></b-skeleton>
                                    </div>
                                    <div class="panel-block">
                                        <b-skeleton width="40%" animated></b-skeleton>
                                    </div>
                                    <div class="panel-block">
                                        <b-skeleton width="80%" animated></b-skeleton>
                                    </div>
                                    <div class="panel-block">
                                        <b-skeleton animated></b-skeleton>
                                    </div>
                                </div>
                                <nav class="panel" v-for="(grupo, index) in gruposExames" :key="index">
                                    <p class="panel-heading" @click="incluirGrupo(grupo)">
                                        {{ grupo.nome }}
                                    </p>
                                    <a class="panel-block" @click="incluirGrupo(grupo)">
                                        <p>
                                            <template v-for="item in grupo.grupoItens">
                                                <b-tag v-if="item.bloqueado" :title="$t('ORCAMENTO.BLOQUEADO')" type="is-danger" :key="item.exame.id">
                                                    {{ item.exame.apelido }}
                                                </b-tag>
                                                <b-tag v-else type="is-primary" :key="item.exame.id">
                                                    {{ item.exame.apelido }}
                                                </b-tag>
                                            </template>
                                        </p>
                                    </a>
                                </nav>
                            </div>
                        </article>
                    </div>
                </div>
                

            </div>
        </div>
    </section>
</template>


<script>
    import debounce from 'lodash/debounce'
    import { mapActions, mapState } from 'vuex'    

    export default {
        data() {
            return {
                exames: [],
                desconto: null,
                convenioId: null,
                data: [],
                gruposExames: [],
                procurarExamePor: 'Apelido',
                salvandoDados: false,
                totalExames: 0,
                isFetching: false,
                grupos: false,
                autocomplete: null
            };
        },
        computed: {    
            ...mapState([
                'config',
                'usuario'
            ])
        },
        created() {
            this.$http.get('/api/portal/orcamento')
                .then(res => {
                    this.convenioId = res.data.convenioId;
                })

            this.$http.get('/api/search/gruposexames')
                .then(res => {
                    this.gruposExames = res.data;
                });
                            
        },       
        methods: {
            getAsyncExame: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetching = true
                this.$http.get(`/Search/OrcamentoExame?nome=${name}&tipo=${this.procurarExamePor}&convenioId=${this.convenioId}`)
                    .then(({ data }) => {
                        this.data = []
                        data.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),            
            selectItem(option) {
                this.incluirExame(option);
            },
            incluirExame(exame){
                if(exame != null && this.exames.filter(x => x.exameId == exame.exameId).length > 0){
                    this.$buefy.dialog.confirm({
                        title: 'Exame já incluído',
                        message: 'Confirma a inclusão do exame: <b>' + exame.exameApelido + '</b> ?',
                        confirmText: 'Confirmar',
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: "Cancelar",                                               
                        onConfirm: () => {
                            this.confirmaInclusaoExame(exame);
                        }
                    });
                }
                else {
                    this.confirmaInclusaoExame(exame);
                }
            },
            confirmaInclusaoExame(option) {
                if(option != null){
                    this.exames.push({
                        bloqueado: option.bloqueado,
                        exameApelido: option.exameApelido,
                        exameCodigo: option.exameCodigo,
                        exameId: option.exameId,
                        exameNome: option.exameNome,
                        examePreco: option.preco != null ? option.preco : option.examePreco,
                        id: 0
                    });

                    this.recalcularTotal();

                }
                
                setTimeout(() => this.autocomplete = '', 10);
            },
            incluirGrupo(grupo){
                grupo.grupoItens.forEach((item) => {                    
                    this.$http.get(`/api/Search/OrcamentoExameId?id=${item.exame.id}&convenioId=${this.convenioId}`)
                        .then(({ data }) => {
                            data.forEach((item) => {
                                this.incluirExame(item);
                            });
                        })
                });
            },
            removerExame(exame, index){
                this.exames = this.exames?.filter((ex, i) => i != index);
                this.recalcularTotal();
            },
            recalcularTotal() {
                this.totalExames = 0;
                this.exames.forEach((v) => {
                    this.totalExames += parseFloat(v.examePreco?.toString());
                });
                this.totalExames = this.totalExames.toFixed(2);
            },
        }
    }

</script>