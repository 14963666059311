<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">  
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.COMENTARIO')">
                                        <b-input type="text" v-model="model.comentario"></b-input>
                                    </b-field>
                                </div>                     
                            </div>
                            <div class="columns">     
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.COMANDO')">
                                        <b-input type="text" v-model="model.comando"></b-input>
                                    </b-field>
                                </div>                    
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.MIN')">
                                        <b-input type="text" v-model="model.minutos"></b-input>
                                    </b-field>
                                </div>  
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.HORAS')">
                                        <b-input type="text" v-model="model.horas"></b-input>
                                    </b-field>
                                </div>    
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.DIA')">
                                        <b-input type="text" v-model="model.dias"></b-input>
                                    </b-field>
                                </div>  
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.MES')">
                                        <b-input type="text" v-model="model.mes"></b-input>
                                    </b-field>
                                </div>  
                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTOTAREFA.DIASEMANA')">
                                        <b-input type="text" v-model="model.diaSemana"></b-input>
                                    </b-field>
                                </div>               
                            </div>
                        </article>

                        <article class="tile is-child">
                            <div class="box">
                                <b-field :label="$t('AGENDAMENTOTAREFA.CONFIG')">
                                    <b-input maxlength="10000" type="textarea" v-model="agendamentoTarefaConfiguracoes" readonly disabled></b-input>
                                </b-field>
                            </div>
                        </article>
                    </div>

                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('AGENDAMENTOTAREFA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('AGENDAMENTOTAREFA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import store from '@/store';

    export default {
        data() {
            return {
                model: {
                    criadoEm: null,
                    domId: 'configArea',
                    tentativas: null,
                    minutos: null,
                    horas: null,
                    dias: null,
                    mes: null,
                    diaSemana: null,
                    comando: null,
                    comentario: null
                },
                salvandoDados: false,
            };
        },
        components: {
            titlebar,            
        },
        created() {
            this.$http.get('/api/agendamentos/index')
                .then(res => {
                    this.model = res.data;     

                    const agendamento = {
                        acao: 1,
                        domId: this.model.domId,
                        connId: this.usuario.id
                    };

                    this.$http.post('/api/agendamentos/refreshconfigs', agendamento)
                        .then(res => {
                            this.model = res.data;                    
                        })                 
                })
                
        },
        computed: {
            titleStack() {
                return [
                    this.$t('AGENDAMENTOTAREFA.MANUTENCAO'),
                    this.$t('AGENDAMENTOTAREFA.AGENDATAREFA')
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'usuario',
                'agendamentoTarefaConfiguracoes'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {

                if(this.model.comando.indexOf('LaborLis.Integrador.Lembrete.dll') == -1){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDAMENTOTAREFA.NAOPERMITIDO'),
                        type: 'is-danger',
                        queue: false
                    })
                    return;
                }

                let html = "#" + this.model.comentario + "\n";
                html += " " + ((typeof this.model.minutos === 'undefined' || this.model.minutos == null) ? "*" : this.model.minutos);
                html += " " + ((typeof this.model.horas === 'undefined' || this.model.horas == null) ? "*" : this.model.horas);
                html += " " + ((typeof this.model.dias === 'undefined' || this.model.dias == null) ? "*" : this.model.dias);
                html += " " + ((typeof this.model.mes === 'undefined' || this.model.mes == null) ? "*" : this.model.mes);
                html += " " + ((typeof this.model.diaSemana === 'undefined' || this.model.diaSemana == null) ? "*" : this.model.diaSemana);
                html += " " + ((typeof this.model.comando === 'undefined' || this.model.comando == null) ? "*" : this.model.comando);

                store.commit("SET_AGENDAMENTOTAREFACONFIG", html);

                const agendamento = {
                    acao: 2,
                    domId: this.model.domId,
                    connId: this.usuario.id,
                    valor: this.agendamentoTarefaConfiguracoes
                };

                this.$http.post('/api/agendamentos/refreshconfigs', agendamento)
                    .then(() => {
                        this.$router.push({ name: 'manutencao' })
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDAMENTOTAREFA.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    
            }        
        }
    }
</script>