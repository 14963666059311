<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="notification is-info" v-if="salvandoDados">
          <button class="delete"></button>
          {{ $t("MARCASCADASTRO.SALVANDO") }}
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-12">
            <article class="tile is-child box">
              <div class="columns">
                <div class="column is-half">
                  <ValidationProvider
                    rules="required"
                    name="nome"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('MARCASCADASTRO.NOME')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input type="text" v-model="model.nome"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </article>
          </div>
        </div>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-success"
                v-show="isInRole('marca-alterar')"
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                {{ $t("MARCASCADASTRO.SALVAR") }}
              </b-button>
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                {{ $t("MARCASCADASTRO.CANCELAR") }}
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
  import { mapGetters, mapState } from "vuex";
  import titlebar from "@/components/titlebar.vue";

  export default {
    components: {
      titlebar,
    },
    data() {
      return {
        model: {
          id: 0,
          nome: null,
        },
        salvandoDados: false,
        retornarMarca: Boolean(this.$route.query.retornarMarca) ?? false,
      };
    },
    created() {
      if (this.$route.params.id) {
        this.$http
          .get("/api/estoque/marca?id=" + this.$route.params.id)
          .then((res) => {
            this.model = res.data;
          });
      }
    },
    computed: {
      titleStack() {
        return [
          this.$t("ESTOQUE.TITULO"),
          this.$t("MARCASCADASTRO.TITULO"),
          this.$route.params.id ?? this.$t('SISTEMA.NOVO')        ];
      },
      ...mapGetters(["isInRole"]),
      ...mapState(["unidades"]),
    },
    methods: {
      onSubmit() {
        this.salvandoDados = true;
        let dadosObrigatorios = true;

        if (this.model.id == null) {
          this.model.id = 0;
        }

        if (!this.model.nome) {
          dadosObrigatorios = false;
        }

        if (dadosObrigatorios) {          
          this.$http
            .post(`/api/estoque/marca`, this.model)
            .then((res) => {
              this.salvandoDados = false;

              if(this.retornarMarca){
                  localStorage.retornarMarca = res.data.id;   
                  window.close();
              }else{
                this.$router.back();
              }

              
            })
            .catch((error) => {
              this.salvandoDados = false;
              throw error;
            });
        } else {
          this.$buefy.dialog.alert({
            title: this.$t("MARCASCADASTRO.ATENCAO"),
            message: this.$t("MARCASCADASTRO.CAMPOSOBRIGATORIOS"),
            type: "is-warning",
            hasIcon: true,
          });
          this.salvandoDados = false;
          return null;
        }
      },
    },
  };
</script>
