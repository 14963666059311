<template>
    <b-navbar type="is-navbar-main navbar-default" is-active>
        <template slot="brand">
            <span v-if="!config.logoBase64" class="navbar-item">
                <img src="/logo-icon.png" />
            </span>
            <a class="navbar-item" @click="redirecionaHome()">
                <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" height="30" />
                <span v-else>{{ config.nomeDoLaboratorio || "LaborLis" }}</span>
            </a>
        </template>
        <template slot="end">
            <a class="navbar-item" v-if="usuario.tipos.filter(tipo => tipo == 'Convenio').length > 0" @click="TOGGLE_MODAL_IMPRESSAO">
                <b-icon icon="printer"></b-icon>
            </a>

            <b-modal :active.sync="modalImpressaoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <modal-impressao></modal-impressao>
            </b-modal>

            <a class="navbar-link is-arrowless" @click="TOGGLE_MODAL_USUARIO_PORTAL">
                <b-icon icon="account"></b-icon>
            </a>

            <b-modal :active.sync="modalUsuarioPortalIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <modal-usuario-portal></modal-usuario-portal>
            </b-modal>

        </template>
    </b-navbar>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { mapMutations } from 'vuex'
    import modalImpressao from '@/components/modalImpressao.vue'
    import modalUsuarioPortal from '@/components/portal/modalUsuarioPortal.vue'
    
    export default {
        components: {
            modalImpressao,
            modalUsuarioPortal
        },
        computed: {            
            ...mapState([
                'config',
                'usuario'
            ]),
            modalImpressaoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalImpressaoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_IMPRESSAO')
                }
            },
            modalUsuarioPortalIsOpened: {
                get() {
                    return this.$store.state.componentes.modalUsuarioPortalIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_USUARIO_PORTAL')
                }
            }
        },
        methods: {
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_IMPRESSAO',
                'TOGGLE_MODAL_USUARIO_PORTAL'
            ]),
            ...mapMutations('componentes', [
                'SET_ACTIVE_MENU_PORTAL'
            ]),  
             
            redirecionaHome(){

                this.$store.commit('componentes/SET_ACTIVE_MENU_PORTAL',0);
                if(this.usuario.tipos[0] == 'Convenio'){
                    this.$router.push({ name: 'portalconvenio' });
                }
                else if(this.usuario.tipos[0] == 'Local'){
                    this.$router.push({ name: 'portallocal' });
                }
                else if(this.usuario.tipos[0] == 'Medico'){
                    this.$router.push({ name: 'portalmedico' });
                }
                else if(this.usuario.tipos[0] == 'Paciente'){
                    this.$router.push({ name: 'portalpaciente' });
                }
                else if(this.usuario.tipos[0] == 'Funcionario'){
                    this.$router.push({ name: 'Sobre' });
                }
            }
        }
    }
</script>