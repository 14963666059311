<template>
    <section>
        <section class="hero is-primary is-navbar-main navbar-default">
            <div class="hero-body">
                <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" height="30" class="is-pulled-right" />
                <p class="title is-spaced">
                    {{ greetings }} {{$t('TOTENATENDIMENTO.OPCAODESEJADA')}}
                </p>
                <p class="subtitle">
                    {{ config.nomeDoLaboratorio }}
                </p>
                <b-taglist attached>
                    <b-tag size="is-medium">{{ currentDate }}</b-tag>
                    <b-tag type="is-dark" size="is-medium">{{ currentTime }}</b-tag>
                    <b-tag size="is-medium">{{ nomeUnidade }}</b-tag>
                </b-taglist>
            </div>
        </section>
        <section class="section is-medium">
            <div class="columns is-multiline is-centered">
                <div class="column is-6 has-text-centered" v-for="item in tipos" :key="item.id">
                    <b-button size="is-large" :loading="isLoading" v-bind:style="{ background: buttonStyle(item.cor) }" style="width:300px;" @click="solicitar(item)">{{ item.nome }}</b-button>
                </div>
            </div>
        </section>
        <b-navbar fixed-bottom>
            <template #end>
                <b-navbar-item tag="div">
                    <b-button icon-left="cog" @click="TOGGLE_MODAL_IMPRESSAO"></b-button>
                </b-navbar-item>

                <b-modal :active.sync="modalImpressaoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                    <modal-impressao></modal-impressao>
                </b-modal>

            </template>
        </b-navbar>
    </section>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import modalImpressao from '@/components/modalImpressao.vue'

    export default {
        data() {
            return {
                tipos: [],
                currentTime: null,
                currentDate: null,
                greetings: null,
                timeInterval: null,
                isLoading: false
            }
        },
        components: {
            modalImpressao
        },
        computed: {
            ...mapState([
                'config',
                'unidades'
            ]),
            modalImpressaoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalImpressaoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_IMPRESSAO')
                }
            },
            nomeUnidade() {
                return this.unidades != undefined
                    ? this.unidades.find(x => x.id == parseInt(this.$route.params.id))?.nome
                    : ''
            }
        },
        methods: {
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_IMPRESSAO'
            ]),
            buttonStyle(color) {
                return `#${color}`;
            },
            updateCurrentTime() {
                this.currentTime = this.$moment().format('HH:mm')
                this.currentDate = this.$moment().format('DD/MM/YYYY')
                const agora = new Date()
                if (agora.getHours() < 12)
                    this.greetings = this.$t('GREETINGS.BOMDIA')
                else if (agora.getHours() < 18)
                    this.greetings = this.$t('GREETINGS.BOATARDE')
                else
                    this.greetings = this.$t('GREETINGS.BOANOITE')
            },
            listarTipos() {
                this.$http.get('/api/filaatendimento/listarTipos?unidadeId=' + this.$route.params.id)
                    .then(res => {
                        if (res.data != null) {
                            this.tipos = res.data
                        }
                    })
            },
            solicitar(tipo) {
                this.isLoading = true
                this.$notificacaoHub.invoke("SolicitarSenhaAtendimento",
                    parseInt(this.$route.params.id),
                    tipo.id)
                    .then(() => {
                        this.isLoading = false
                    })
                    .catch(e => {
                        this.isLoading = false
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: e.toString(),
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })
            }
        },
        mounted() {
            this.updateCurrentTime()
            this.timeInterval = setInterval(() => this.updateCurrentTime(), 1 * 10000)
            this.listarTipos()
            this.iniciarHub()
        },
        beforeDestroy: function () {
            clearInterval(this.timeInterval)
            this.$notificacaoHub.stop()
        }
    }

</script>