<template>
    <b-field label="CEP" 
                :type="{ 'is-danger': naoLocalizado, 'is-success': encontrado }"
                :message="{ 'CEP não encontrado': naoLocalizado }">
        <b-input :loading="buscando" 
                    v-model="interno.cep"
                    @blur="buscarCep"
                    @keyup="$emit('update:cep', interno.cep);"
                    v-mask="'#####-###'"
                    :icon-right="!buscando && !encontrado && !naoLocalizado ? 'earth' : ''"></b-input>
    </b-field>
</template>

<script>
    export default {
        components: {

        },
        props: {
            cep: String,
        },
        data() {
            return {
                interno: {
                    cep: this.cep,
                    endereco: this.endereco,
                    bairro: this.bairro
                },

                buscando: false,
                encontrado: false,
                naoLocalizado: false
            }
        },
        watch: {
            cep() {
                this.interno.cep = this.cep
            },
            endereco() {
                this.interno.endereco = this.endereco
            },
            bairro() {
                this.interno.bairro = this.bairro
            }
        },
        methods: {
            buscarCep() {
                this.buscando = false
                this.encontrado = false
                this.naoLocalizado = false

                if (/^[0-9]{5}-[0-9]{3}$/.test(this.interno.cep)) {
                    this.buscando = true;
                    const url = 'https://viacep.com.br/ws/' + this.interno.cep + '/json/';
                    
                    fetch(url)
                        .then(res => res.json())
                        .then(endereco => {
                            this.buscando = false
                            if (endereco.erro) {
                                this.naoLocalizado = true
                            } else {
                                this.encontrado = true
                                this.$emit('update:cep', endereco.cep)
                                this.$emit('update:endereco', endereco.logradouro)
                                this.$emit('update:bairro', endereco.bairro)
                                this.$emit('update:cidadeid', parseInt(endereco.ibge))
                                this.$emit('update:cidadenome', endereco.localidade + " - " + endereco.uf)
                                this.interno.endereco = endereco.logradouro;
                                this.interno.bairro = endereco.bairro;
                            }
                        })
                }
            }
        }
    }
</script>