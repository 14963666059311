<template>
    <section>
        <titlebar :title-stack="titleStack">
            <template v-slot:right>
                <copiar-exames :loadConfigFunction="loadConfig" :chave="model.id"></copiar-exames> 
                <historico-alteracoes :tabela="'Equipment'" :chave="model.id"></historico-alteracoes> 
            </template>
        </titlebar>
        <b-notification type="is-warning" v-if="!equipamentoDicom">
            <b>{{$t('CONFIG.ATENCAO')}}!</b>
            {{$t('CONFIG.NOTIFICACAO')}}
        </b-notification>
        <div class="box">
            <div class="columns is-multiline is-mobile">
                <div class="column is-full-mobile">
                    <b-field :label="$t('CONFIG.NOME')">
                        <b-input type="text" v-model="model.applicationName"></b-input>
                    </b-field>
                </div>
                <div class="column is-half-mobile">
                    <b-field :label="facilityNameLabel">
                        <b-input type="text" v-model="model.facilityName"></b-input>
                    </b-field>
                </div>
                <div class="column is-half-mobile">
                    <b-field :label="$t('CONFIG.VERSAO')">
                        <b-input type="text" v-model="model.version"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns is-multiline is-mobile" v-if="!equipamentoDicom">
                <div class="column is-full-mobile">
                    <b-field :label="$t('CONFIG.TIPOCONEXAO')">
                        <b-select v-model="model.connectionType">
                            <option v-for="option, index in connectionTypes"
                                    :value="option[0]"
                                    :key="index">
                                {{ option[1] }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <template v-if="model.connectionType == 0">
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.PORTA')">
                            <b-input v-model="model.serialPortName"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.BITSEGUNDO')">
                            <b-input v-model="model.serialBaudRate"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.PARIDADE')">
                            <b-select v-model="model.serialParity">
                                <option v-for="option, index in paridades"
                                        :value="option[0]"
                                        :key="index">
                                    {{ option[1] }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.BITSDADOS')">
                            <b-input v-model="model.serialDataBits"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field  :label="$t('CONFIG.BITSPARADA')">
                            <b-input v-model="model.serialStopBits"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.CONTROLEFLUXO')">
                            <b-select v-model="model.serialHandshake">
                                <option v-for="option, index in handshakes"
                                        :value="option[0]"
                                        :key="index">
                                    {{ option[1] }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </template>
                <template v-if="model.connectionType == 1 || model.connectionType == 3">
                    <div class="column is-4">
                        <b-field :label="$t('CONFIG.PORTA')">
                            <b-input v-model="model.tcpPortNumber"></b-input>
                        </b-field>
                    </div>
                </template>
                <template v-if="model.connectionType == 2">
                    <div class="column is-full-mobile">
                        <b-field :label="$t('CONFIG.DIRETORIOIMPORTACAO')">
                            <b-input v-model="model.fileImportDir"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-full-mobile">
                        <b-field :label="$t('CONFIG.DIRETORIOEXPORTACAO')">
                            <b-input v-model="model.fileExportFullPath"></b-input>
                        </b-field>
                    </div>
                </template>
            </div>
            <hr v-if="!equipamentoDicom" />
            <div class="columns" v-if="!equipamentoDicom">
                <div class="column">
                    <b-field>
                        <b-checkbox v-model="model.sendResultUnits">
                            {{$t('CONFIG.ENVIARESULTADOUNIDADE')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.autoSendResultsWithoutConfirm">                            
                            <span v-html="$t('CONFIG.ENVIARTODOS')"></span>
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.sampleLink">
                            {{$t('CONFIG.SEMAMOSTRAVINCULADA')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.autoSendNormalResults">
                            {{$t('CONFIG.ENVIARESULTADONORMAL')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-input :placeholder="$t('CONFIG.VALORNORMAL')" v-model="model.normalInterpretationCode" :disabled="!model.autoSendNormalResults"></b-input>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.reSendOnlyMissingTests">
                            {{$t('CONFIG.REENVIAR')}}
                        </b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-checkbox v-model="model.autoReSendAllTests">
                            {{$t('CONFIG.REENVIARTODOS')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.dontShowComments">
                            {{$t('CONFIG.OCULTARCOMENTARIOS')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.ignoreBlankResults">
                            {{$t('CONFIG.IGNORAREMBRANCO')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.ignoreAndRepeatResultsWith">
                            {{$t('CONFIG.IGNORARREPETIR')}}
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-input :placeholder="$t('CONFIG.VALORREPETIR')" v-model="model.ignoreAndRepeatResultsWithVal" :disabled="!model.ignoreAndRepeatResultsWith"></b-input>
                    </b-field>
                </div>
                <div class="column">
                     <b-field>
                        <b-checkbox v-model="model.sendOnlyDelivered">
                            {{$t('CONFIG.APENASMATERIALENTREGUE')}}
                        </b-checkbox>
                    </b-field>
                </div>
            </div>
            <div class="columns" v-if="!equipamentoDicom">
                <div class="column is-2">
                    <b-field>
                        <b-checkbox v-model="model.sumOnConfirmation">
                            {{$t('CONFIG.TOTALIZADOR')}}
                        </b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-input :placeholder="$t('CONFIG.TITULO')" v-model="model.sumOnConfirmationTitle" :disabled="!model.sumOnConfirmation"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-input :placeholder="$t('CONFIG.FORMULA')" v-model="model.sumOnConfirmationFormula" :disabled="!model.sumOnConfirmation"></b-input>
                    </b-field>
                </div>
            </div>
            <hr />            
            <div class="panel-heading">
                {{$t('CONFIG.EXAMES')}}
            </div>
            <div v-for="item, index in model.testCodeConfig" :key="index">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.CODIGO')">
                            <b-input v-model="item.testCode"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.NOME')">
                            <b-input v-model="item.testName"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.CODIGOEXAME')">
                            <b-input v-model="item.lisTestCode" @blur="getExameId($event, item)"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.NOMEEXAME')">
                            <b-autocomplete :data="listaExames"
                                            field="nome"
                                            v-model="item.lisTestName"
                                            :loading="isFetchingListaExames"
                                            @typing="getAsyncExames($event, item)"
                                            @select="selectExame($event, item)">
                                <template slot="empty">
                                    {{$t('CONFIG.NENHUMEXAME')}}
                                </template>
                                <template slot-scope="props">
                                    <div class="is-flex is-justified-between">
                                        <span class="tag is-black">{{ props.option.apelido }}</span>
                                        <span>{{ props.option.nome }}</span>
                                        <span class="tag">{{ props.option.id }}</span>
                                    </div>
                                </template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-mobile is-multiline" v-if="!equipamentoDicom">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.CODIGOCAMPO')">
                            <b-input v-model="item.lisResultCode" @blur="getMascaraCampoId($event, item)"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field :label="$t('CONFIG.NOMECAMPO')">
                            <b-autocomplete :data="listaCampos"
                                            field="nome"
                                            v-model="item.lisResultName"
                                            :loading="isFetchingListaCampos"
                                            @typing="getAsyncCampos($event, item)"
                                            @select="selectCampo($event, item)">
                                <template slot="empty">
                                    {{$t('CONFIG.NENHUMCAMPO')}}
                                </template>
                                <template slot-scope="props">
                                    <div class="is-flex is-justified-between">
                                        <div>
                                            <span class="tag is-black" :title="$t('CONFIG.APELIDOEXAME')">{{ props.option.exameApelido }}</span>
                                            <span class="tag is-info" :title="$t('CONFIG.VERSAOMASCARA')">{{ props.option.mascaraVersao }}</span>
                                        </div>
                                        <span>{{ props.option.nome }}</span>
                                        <span class="tag">{{ props.option.id }}</span>
                                    </div>
                                </template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.VERSAOMASCARA')">
                            <b-input v-model="item.lisResultVersion"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.AMOSTRANUMERO')">
                            <b-input v-model="item.sampleNumber"></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-mobile is-multiline" v-if="!equipamentoDicom">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.ORDENACAO')">
                            <b-input v-model="item.displayPosition" numeric></b-input>
                        </b-field>
                    </div>

                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.DECIMAIS')">
                            <b-input v-model="item.decimals" numeric></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field :label="$t('CONFIG.UNIDADE')">
                            <b-input v-model="item.units"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-4-desktop is-full-mobile">
                        <b-field :label="$t('CONFIG.FORMULACALCULO')">
                            <b-input v-model="item.calculationFormula"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-1-desktop is-half-mobile">
                        <br>
                        <b-field>
                            <b-checkbox v-model="item.receiveOnly">
                                {{$t('CONFIG.SORECEBE')}}
                            </b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-1-desktop is-half-mobile">
                        <br>
                        <b-field>
                            <b-checkbox v-model="item.sendSigned">
                                {{$t('CONFIG.ENVIAASSINADO')}}
                            </b-checkbox>
                        </b-field>
                    </div>
                </div>
                <hr />
            </div>
            <p class="has-text-centered">
                <b-button icon-left="plus" @click="model.testCodeConfig.push({})">{{$t('CONFIG.INCUIREXAME')}}</b-button>
            </p>
            <div  v-if="!equipamentoDicom">
                <hr />
                <div class="panel-heading">
                    {{$t('CONFIG.MATERIAIS')}}
                </div>
                <div v-for="item, index in model.specimenSource" :key="index">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-half-mobile">
                            <b-field :label="$t('CONFIG.NOME')">
                                <b-input v-model="item.name"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-half-mobile">
                            <b-field :label="$t('CONFIG.NOMEMATERIAL')">
                                <b-autocomplete :data="listaMateriais"
                                                field="nome"
                                                v-model="item.lisName"
                                                :loading="isFetchingListaMateriais"
                                                @typing="getAsyncMateriais($event, item)"
                                                @select="selectMaterial($event, item)">
                                    <template slot="empty">
                                        {{$t('CONFIG.NENHUMMATERIAL')}}
                                    </template>
                                    <template slot-scope="props">
                                        <div class="is-flex is-justified-between">
                                            <span>{{ props.option.nome }}</span>
                                            <span class="tag">{{ props.option.id }}</span>
                                        </div>
                                    </template>
                                </b-autocomplete>
                            </b-field>
                        </div>
                    </div>
                    <hr />
                </div>
                <p class="has-text-centered">
                    <b-button icon-left="plus" @click="model.specimenSource.push({})">{{$t('CONFIG.INCLUIRMATERIAL')}}</b-button>
                </p>
            </div>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              :loading="salvandoDados"
                              native-type="submit"
                              @click="submit"
                              icon-left="check-circle">
                        {{$t('CONFIG.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.back();">
                        {{$t('CONFIG.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<style scoped>
.is-right {
  float: right !important
}

.display-inline-block{
  display: inline-block !important;
}



</style>

<script>
    import debounce from 'lodash/debounce'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import copiarExames from '@/components/copiarExames.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    testCodeConfig: [{
                        lisResultCode: null,
                        lisResultName: null,
                        lisTestCode: null,
                        lisTestName: null,
                        lisResultVersion: null,
                        sampleNumber: null,
                        decimals: null,
                        units: null
                    }],
                    specimenSource: [{
                        id: null,
                        name: null,
                        lisname: null
                    }],
                    dontOverwriteSignedResults: false,
                },

                isFetchingListaCampos: false,
                isFetchingListaExames: false,
                isFetchingListaMateriais: false,

                listaCampos: [],
                listaExames: [],
                listaMateriais: [],

                removerTestCodeConfig: [],
                removerSpecimenSource: []
            }
        },
        components: {
            titlebar,
            historicoAlteracoes,
            copiarExames
        },
        created() {
            this.loadConfig();
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONFIG.INTERFACE'),
                    this.$t('CONFIG.CONFIGURACAO'),
                    this.$route.params.id
                ]
            },
            connectionTypes() {
                return [
                    [0, 'Serial'],
                    [1, 'TCP'],
                    [2, 'File'],
                    [3, 'TCPCripto'],
                    [4, 'Database']
                ]
            },
            paridades() {
                return [
                    [0, this.$t('CONFIG.NENHUMA')],
                    [1, this.$t('CONFIG.IMPAR')],
                    [2, this.$t('CONFIG.PAR')],
                    [3, this.$t('CONFIG.MARCA')],
                    [4, this.$t('CONFIG.ESPACO')]
                ]
            },
            handshakes() {
                return [
                    [0, this.$t('CONFIG.NENHUMA')],
                    [1, 'XOnXOff'],
                    [2, 'RTS'],
                    [3, 'RTS/XOnXOff']
                ]
            },
            equipamentoDicom() {
                return this.model.equipmentID == 0
            },
            facilityNameLabel() {
                return this.equipamentoDicom
                    ? "Application Entity Title (AET)"
                    : this.$t('CONFIG.INSTALACAO')
            }
        },
        methods: {
            loadConfig(){
                this.$http.get('/api/interface/config?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data
                    })
            },
            getAsyncCampos: debounce(function (name, item) {
                if (!name.length) {
                    this.listaCampos = []
                    return
                }
                this.isFetchingListaCampos = true
                let paramExameId = ''
                if (item.lisTestCode) {
                    paramExameId = `&exameId=${item.lisTestCode}`
                }
                this.$http.get(`/Search/MascaraCampo?nome=${name}` + paramExameId)
                    .then(({ data }) => {
                        this.listaCampos = data
                    })
                    .catch((error) => {
                        this.listaCampos = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaCampos = false
                    })
            }, 500),

            getMascaraCampoId(event, item) {
                if (item.lisResultCode == null)
                    return
                this.isFetchingListaCampos = true
                this.$http.get(`/Search/MascaraCampoId?id=${item.lisResultCode}`)
                    .then(({ data }) => {
                        this.selectCampo(data, item)
                    })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaCampos = false
                    })
            },

            selectCampo(option, item) {
                item.lisResultCode = option.id
                item.lisResultName = option.nome
                item.lisTestCode = option.exameId
                item.lisTestName = option.exameNome
                item.lisResultVersion = option.mascaraVersao
                item.sampleNumber = option.recipienteId
                item.decimals = option.decimais
                item.units = option.unidade
            },

            getAsyncExames: debounce(function (name) {
                if (!name.length) {
                    this.listaExames = []
                    return
                }
                this.isFetchingListaExames = true
                this.$http.get(`/Search/Exame?texto=${name}`)
                    .then(({ data }) => {
                        this.listaExames = data
                    })
                    .catch((error) => {
                        this.listaExames = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaExames = false
                    })
            }, 500),

            getExameId(event, item) {
                if (item.lisTestCode == null)
                    return
                this.isFetchingListaExames = true
                this.$http.get(`/Search/ExameId?id=${item.lisTestCode}`)
                    .then(({ data }) => {
                        this.selectExame(data, item)
                    })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaExames = false
                    })
            },

            selectExame(option, item) {
                item.lisTestCode = option.id
                item.lisTestName = option.nome
            },

            getAsyncMateriais: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetchingListaMateriais = true
                this.$http.get(`/Search/Material?nome=${name}`)
                    .then(({ data }) => {
                        this.listaMateriais = data
                    })
                    .finally(() => {
                        this.isFetchingListaMateriais = false
                    })
            }, 500),

            submit() {
                this.$http.post('/api/interface/config', this.model)
                    .then(() => {
                        this.$router.push({ name: 'equipamentos' })
                    })
                    .catch(err => {
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CONFIG.NENHUMA') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            }
        }
    }
</script>