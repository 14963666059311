<template>
  <section>
    <div class="columns">
      <div class="column">
        <div v-if="isInRole('prontuario-editar') && isEvent" class="panel-heading is-flex is-justify-content-end">
          <b-button @click="toggleReadOnly" class="button has-background-white-ter is-flex i" type="button">
              <b-icon icon="file-edit-outline" size="is-small"></b-icon>
          </b-button>
        </div>
        <ckeditor
          editorUrl="https://cdn.ckeditor.com/4.19.1/full-all/ckeditor.js"
          :id="id"
          :config="editorConfig"
          v-model="reportHtml"
        ></ckeditor>
      </div>
    </div>
    <nav class="level">
      <div class="level-item">
        <p class="buttons">
            <b-button v-if="!readOnly && isEvent" type="is-success"
                :loading="salvandoDados"
                @click="salvarEventoEditado();"
                icon-left="check-circle">
                {{$t('SISTEMA.SALVAR')}}
            </b-button>
            <b-button v-if="!readOnly && isEvent" type="is-danger"
                native-type="button"
                icon-left="close-circle"
                >
                {{$t('SISTEMA.CANCELAR')}}
            </b-button>
        </p>
      </div>
    </nav>
  </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import CKEditor from 'ckeditor4-vue'
    import utilResizing  from '../assets/js/views/estudos-laudo/utilResizing.js'

    export default {
      props: {
        editorReadOnly:Boolean,
        isEvent: Boolean,
        id: Number,
        reportString: String,
      },
      data() {
        return {
          salvandoDados: false,
          readOnly: this.editorReadOnly,
          editorConfig: {
            // An array of stylesheets to style the WYSIWYG area.
            // Note: it is recommended to keep your own styles in a separate file in order to make future updates painless.
            contentsCss: [ 'https://cdn.ckeditor.com/4.19.1/full-all/contents.css', '/css/ckeditor-document.css', '/home/resultadocss' ],

            // Define the toolbar: https://ckeditor.com/docs/ckeditor4/latest/features/toolbar.html
            // The full preset from CDN which we used as a base provides more features than we need.
            // Also by default it comes with a 3-line toolbar. Here we put all buttons in a single row.
            toolbar: [
                { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'styles', items: [ 'Font', 'FontSize' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                { name: 'insert', items: [ 'Image', 'Table' ] },
                { name: 'mode', items: [ 'Source' ] }
            ],

            on: {
              paste: async function (ev) {
                  if(ev.data.dataValue.includes('data:image/png;base64'))
                  {
                      ev.stop();
                      const width = document.getElementById("width").value;
                      const height = document.getElementById("height").value;
                      
                      const splitBase64 = ev.data.dataValue.split(',');
                      const base64 = splitBase64[1].replace('" />','');
                      const resized = await utilResizing.resizeBase64Img('data:image/png;base64,'+base64,width,height);

                      ev.editor.insertHtml(`<img data-cke-saved-src="${resized}" src="${resized}"/>`)
                  }
              },
            },


            startupFocus: true,

            // Since we define all configuration options here, let's instruct CKEditor to not load config.js which it does by default.
            // One HTTP request less will result in a faster startup time.
            // For more information check https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-customConfig
            customConfig: '',

            // Sometimes applications that convert HTML to PDF prefer setting image width through attributes instead of CSS styles.
            // For more information check:
            //  - About Advanced Content Filter: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_advanced_content_filter.html
            //  - About Disallowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_disallowed_content.html
            //  - About Allowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_allowed_content_rules.html
            disallowedContent: 'img{width,height,float}',
            extraAllowedContent: 'img[width,height,align]',

            // Enabling extra plugins, available in the full-all preset: https://ckeditor.com/cke4/presets-all
            extraPlugins: 'tableresize,uploadimage,uploadfile',

            /*********************** File management support ***********************/
            // In order to turn on support for file uploads, CKEditor has to be configured to use some server side
            // solution with file upload/management capabilities, like for example CKFinder.
            // For more information see https://ckeditor.com/docs/ckeditor4/latest/guide/dev_ckfinder_integration.html

            // Uncomment and correct these lines after you setup your local CKFinder instance.
            // filebrowserBrowseUrl: 'http://example.com/ckfinder/ckfinder.html',
            // filebrowserUploadUrl: 'http://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
            /*********************** File management support ***********************/

            // Make the editing area bigger than default.
            height: 800,
            // This is optional, but will let us define multiple different styles for multiple editors using the same CSS file.
            bodyClass: 'document-editor',

            // Reduce the list of block elements listed in the Format dropdown to the most commonly used.
            // eslint-disable-next-line @typescript-eslint/camelcase
            format_tags: 'p;h1;h2;h3;pre',

            // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
            removeDialogTabs: 'image:advanced;link:advanced',

            // Define the list of styles which should be available in the Styles dropdown list.
            // If the "class" attribute is used to style an element, make sure to define the style for the class in "mystyles.css"
            // (and on your website so that it rendered in the same way).
            // Note: by default CKEditor looks for styles.js file. Defining stylesSet inline (as below) stops CKEditor from loading
            // that file, which means one HTTP request less (and a faster startup).
            // For more information see https://ckeditor.com/docs/ckeditor4/latest/features/styles.html
            stylesSet: [
                /* Inline Styles */
                { name: 'Marker', element: 'span', attributes: { 'class': 'marker' } },
                { name: 'Cited Work', element: 'cite' },
                { name: 'Inline Quotation', element: 'q' },

                /* Object Styles */
                {
                    name: 'Special Container',
                    element: 'div',
                    styles: {
                        padding: '5px 10px',
                        background: '#eee',
                        border: '1px solid #ccc'
                    }
                },
                {
                    name: 'Compact table',
                    element: 'table',
                    attributes: {
                        cellpadding: '5',
                        cellspacing: '0',
                        border: '1',
                        bordercolor: '#ccc'
                    },
                    styles: {
                        'border-collapse': 'collapse'
                    }
                },
                { name: 'Borderless Table', element: 'table', styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
                { name: 'Square Bulleted List', element: 'ul', styles: { 'list-style-type': 'square' } }
            ]
          },
          reportHtml: this.reportString,
        }
      },
      computed: {
        ...mapGetters([
          'isInRole'
        ]),
      },
      watch: {
        reportString(){
          this.reportHtml = this.reportString;
        },
        reportHtml() {
          this.$emit("update:reportString", this.reportHtml);
        },
      },
      components: {
        'ckeditor': CKEditor.component,
      }, 
      mounted() {
        if(this.editorReadOnly) {
          this.readOnly = this.editorReadOnly;
          document.getElementById(this.id).style['pointer-events'] = 'none';
        }
      },
      methods: {
        toggleReadOnly() {
          this.readOnly = !this.readOnly;
          document.getElementById(this.id).style['pointer-events'] = this.readOnly ? 'none' : ''
        },
        salvarEventoEditado() {
          this.readOnly = !this.readOnly;
          document.getElementById(this.id).style['pointer-events'] = 'none';
          
          this.$emit('updateEvento');
          this.salvandoDados = false;
        }
      }
    }
</script>