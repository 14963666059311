<template>
    <section>
        <div v-for="node in nodes" :key="node.name" :style="{'margin-left': `${depth * 20}px`}" class="node">
            <div>
                <span class="typeClicked" @click="nodeClicked(node)">
                    <i v-if="isExpanded(node)" class="mdi mdi-minus"></i>
                    <span v-else-if="node && node.filho"></span>
                    <i v-else class="mdi mdi-plus"></i>
                </span>
                <span class="type tag is-default">
                    {{node.name}}
                </span>
                <b-button type="type is-info" size="is-small"
                    icon-left="file-edit-outline"
                    title="Editar"
                    @click="$emit('clickEdit', node)">
                </b-button>   
                <b-button type="type is-primary" size="is-small"
                    icon-left="plus"
                    title="Adicionar filho"
                    v-if="!node.filho"
                    @click="$emit('clickPlus', node)">
                </b-button>   
                <TreeView 
                    v-if="isExpanded(node) && node.children"
                    :nodes="node.children"
                    :depth="depth + 1"
                    @click="(node) => $emit('click', node)"
                    @clickPlus="(node) => $emit('clickPlus', node)"
                    @clickEdit="(node) => $emit('clickEdit', node)"
                />
            </div>
        </div>
    </section>   
</template>
  
<style scoped>
.node {
    text-align: left;
    font-size: 18px;
}
.typeClicked {
    margin-right: 10px;
}
.type {
    margin-right: 5px;
    margin-bottom: 2px;
}
</style>

<script>
    export default {
        name: "TreeView",
        props: {
            nodes: Array,
            depth: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                expanded: [],
            };
        },
        methods: {
            isExpanded(node) {
                return this.expanded.indexOf(node) !== -1;
            },
            nodeClicked(node) {
                if (!this.isExpanded(node)) {
                    this.expanded.push(node);
                } else {
                    this.expanded.splice(this.expanded.indexOf(node));
                }
            }
        },
    };
</script>
  