<template>
    <section class="">
        <div class="columns box">
            <div class="column is-6">
                <b-field>
                    <b-input :placeholder="$t('CATALOGOEXAMES.APELIDONOME')"
                        v-model="procurar"
                        icon="magnify"
                        @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column is-4">
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirExame">
                    <b-field>
                        <b-input :placeholder="$t('CATALOGOEXAMES.CODIGO')"
                                v-model="id"
                                type="number"
                                icon-right="edit"
                                icon-right-clickable
                                @icon-right-click="abrirExame">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 aria-next-label="Próxima página"
                 aria-previous-label="Página anterior"
                 aria-page-label="Página"
                 aria-current-label="Página atual"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template>
                <b-table-column v-slot="props" field="id" :label="$t('CATALOGOEXAMES.ID')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="Apelido" :label="$t('CATALOGOEXAMES.APELIDO')" sortable>
                    {{ props.row.apelido }}
                </b-table-column>

                <b-table-column v-slot="props" field="Nome" :label="$t('CATALOGOEXAMES.NOME')" sortable>
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'portalvisualizarexame', params: {id: props.row.id} }" class="button is-small is-primary" :title="$t('CATALOGOEXAMES.VISUALIZAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section> 
</template>

<script>
    import { mapActions, mapState } from 'vuex'  
    import debounce from 'lodash/debounce' 

    export default {
        data() {
            return {
                ordenarPor: 'Apelido',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                perPage: 20,
                model: [],
                page: 1,
                total: 0,
                pageCount: null,                
                loading: false,
                procurar: '',
                id: null
            }
        },
        computed: {             
            ...mapState([
                'config',
                'usuario'
            ])
        },
        methods: {
            loadAsyncData: debounce(function () {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/portal/CatalogoExames?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 500),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirExame(){
                this.$router.push({ name: 'portalvisualizarexame', params: {id: this.id} });
            }
        },
        created() {
            this.loadAsyncData()
                        
        }
    }
</script>