<template>
    <section>
        <titlebar :title-stack="titleStack"> </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Configurações da Liberação Automática">
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field Label="Habilitada">
                                        <b-switch v-model="model.habilitada">
                                            Habilitada
                                        </b-switch>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field Label="Usa Valores originais de Referência">
                                        <b-switch v-model="model.usaValoresOriginaisDeRef">
                                            Usa Valores originais de Referência
                                        </b-switch>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Range Delta Check">
                                        <b-input placeholder=""
                                                 v-model="model.rangeDeltaCheck"
                                                 type="number">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>
                            <!-- <div class="columns"> -->
                            <div class="column">
                                <searchIdName label="Usuário Responsável"
                                              table="Usuario"
                                              :id.sync="model.usuarioResponsavel.id"></searchIdName>
                            </div>
                            <!-- </div> -->
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back()">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss" scoped>
    .form-botao {
        @media (min-width: 576px) {
            margin-top: 1.5rem;
        }
    }
</style>

<script>
    import { mapGetters, mapState } from "vuex";
    import liberacaoAutomatica from "@/components/manutencao/liberacaoAutomatica.vue";
    import titlebar from "@/components/titlebar.vue";
    import searchIdName from "@/components/searchIdName.vue";
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";

    export default {
        data() {
            return {
                model: {
                    lista: [],
                    campos: [],
                    mascaras: [],
                },
                apelido: null,
                salvandoDados: false,
                mascaras: [],
                opcoesSexo: [
                    { value: null, text: "Ambos" },
                    { value: "M", text: "Masculino" },
                    { value: "F", text: "Feminino" },
                ],
                mascaraSelecionada: {
                    campos: []
                },
                campoSelecionado: { id: null },
                exibirValoresDeReferencia: false,
                valoresDeReferencia: [],
                campos: [],
                novosItems: [],
                carregando: false
            };
        },
        components: {
            titlebar,
            searchIdName
        },
        mounted() {
            this.apelido = this.$route.params?.id;
        },
        created() {
            this.$http
                .get(
                    "/api/manutencao/liberacaoAutomaticaConfiguracoes"
                )
                .then((res) => {
                    this.model = res.data;
                });

        },
        computed: {
            titleStack() {
                return ["Manutenção", "Configurar Liberação Automática"];
            },
            ...mapState(["unidadeId", "config", "integradores"]),
            ...mapGetters(["isInRole"])
        },
        methods: {
            onSubmit() {

                const newItem = Object.assign({}, this.model);

                this.$http
                    .post(`/api/manutencao/liberacaoAutomaticaConfiguracoes?usuarioResponsavelId=${newItem.usuarioResponsavel?.id ? newItem.usuarioResponsavel.id : 0}`, newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch((err) => {
                        console.error(err);
                        Object.keys(err.body.errors).forEach((x) => {
                            this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                        });
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: "Erro ao salvar configuração: " + err.body.title,
                            type: "is-danger",
                            queue: false,
                        });
                    });
            }

        }
    }



</script>
