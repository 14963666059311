<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ this.$t('RECEBIMENTOAMOSTRAS.AMOSTRANAOCHECADATITULO') }}</p>
        </header>
        <section class="modal-card-body">
            <p v-html="msgAmostrasNomes"></p>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-flex-end">
            <b-button type="is-primary" @click="confirmarEnviarEmail" :loading="salvandoDados">{{ this.$t('RECEBIMENTOAMOSTRAS.ENVIAREMAIL') }}</b-button>
            <b-button type="is-success" @click="continuar">{{ this.$t('RECEBIMENTOAMOSTRAS.CONTINUAR') }}</b-button>
            <b-button type="is-danger" @click="cancelar">{{ this.$t('RECEBIMENTOAMOSTRAS.CANCELAR') }}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    .modal-card {
        max-width: 500px; 
    }
</style>

  <script>
  export default {
    props: {
        amostras: Array,
        amostraNaoChecada: Array,
        recebimentoDeAmostras: Array
    },
    data() {
      return {
        msgAmostrasNomes: '',
      };
    },
    mounted() {
        const amostrasCodigoDeBarras = this.amostraNaoChecada.map(amostra => amostra.amostraCodigoDeBarras).join(', ');
        this.msgAmostrasNomes = `${this.$t('RECEBIMENTOAMOSTRAS.AMOSTRANAOCHECADA')}<br><strong>${amostrasCodigoDeBarras}</strong>`;
    },
    methods: {
      continuar() {
        this.$emit('receberAmostrasApi', this.recebimentoDeAmostras);
        this.$emit('close');
      },
      confirmarEnviarEmail(){
        this.$http.post('/api/atendimento/confirmarEnviarEmail', this.amostraNaoChecada);
        this.amostras = [];
        this.$emit('receberAmostrasApi', this.recebimentoDeAmostras);
        this.$emit('atualizarAmostras', this.amostras)
    
        this.$emit('close');
      },
      cancelar() {
        this.$emit('close');
      },
    },
  };
  </script>