<template>
    <section >                   
        <filtros :tipo="'RelacaoExames'" @filtrar="filtrar($event)"></filtros>  
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>
        <div v-if="(model!=null && model.lista.length>0)" class="tile is-ancestor centralizar">
            <div class="tile is-12 is-centered is-vertical is-parent">
                <div class="tile is-child box"  style="margin-bottom: 5px !important;">
                    <p class="title">{{$t('RELACAOEXAME.EXAMESPERIODO')}} {{model.sub}}</p>
                </div>


                <!-- LINHAS CONTEUDOS -->
                <div class="b-table" >
                    <div class="table-wrapper has-mobile-cards">
                        <table class="table">
                            <tbody> 
                                <span  style="display: contents;" v-for="guia in model.guias" :key="guia.guiaId">
                                    <tr class="portal-mes">
                                        <td colspan="1">

                                            <router-link class="is-block" :to="{ name: 'portalconvenioguia', params: { id: guia.guiaId}}" >
                                                {{ guia.guiaId}}
                                            </router-link>      
                                            <small>{{ guia.guiaReferencia }}</small> 
                                        </td>
                                        <td colspan="2"><b>{{guia.pacienteNome}}</b></td>                                   
                                        <td colspan="2"><b>{{$moment(guia.dataRetorno).format("DD/MM/YYYY")}}</b></td>                                   
                                    </tr>                                    
                                    <tr v-for="exame in model.lista.filter(x => x.guiaId == guia.guiaId)" :key="exame.PacienteId" >
                                        <td class="is-fullwidth-mobile " style="width:40%"> 
                                            <p>{{exame.exameNome}}</p>
                                            <small>{{exame.exameCodigo}}</small> 
                                        </td>   
                                        <td class="is-fullwidth-mobile " style="width:2%; cursor: pointer;"> 
                                            <span class="guiaExameStatus"  :title="exame.guiaExame.statusTitle" :style="exame.guiaExame.statusHtmlStyle">
                                                {{exame.guiaExame.statusSigla}}
                                            </span>                                                                                 
                                        <td class="is-fullwidth-mobile " style="width:20%"> {{exame.materialNome}} </td>   
                                        <td class="is-fullwidth-mobile " style="width:30%"> {{$t('RELACAOEXAME.SOLICITADO')}}: {{$moment(exame.dataDeSolicitacao).format("DD/MM/YYYY HH:mm")}} </td>   
                                        
                                        <span class="is-fullwidth-mobile" style="display: contents;"  v-if="!model.escondePreco">
                                            <td class="is-fullwidth-mobile" style="width:10%"> R${{exame.preco.toFixed(2).replace('.', ',')}} </td>                                   
                                        </span>

                                    </tr>                                     

                                </span>
                                
                                <tr>
                                    <td colspan="4" > <b> {{$t('RELACAOEXAME.TOTAIS')}}: {{model.guias.length}} {{$t('RELACAOEXAME.GUIAS')}}, {{model.pacientes.length}} {{$t('RELACAOEXAME.PACIENTES')}} {{model.totalExames}} exames </b></td>
                                    <td class="is-fullwidth-mobile" v-if="!model.escondePreco" style="width:10%">R${{model.totalValor.toFixed(2).replace('.', ',')}}</td>                                   
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>        


            </div>
        </div>


        
            
  
        </div>
    </section> 
</template>


<style scoped>


    .guiaExameStatus {
        float: right;
        width: 2em;
        text-align: center;
        border: solid 1px silver;
        border-radius: 14px;
        /* height: 2em; */
        font-weight: bold;
    }

  
</style>


<script>
    import { mapActions, mapState } from 'vuex'
    
    import filtros from '@/components/portal/filtros.vue'    
            
    export default {
        data() {
            return {
                model: null,                
                isLoading: false,
                
            }
        },
        computed: {         
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {            
            filtros            
        },
        methods: {

                        
            filtrar(filtros){                

                let filtrosUrl =  '?dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss');                    

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                }

                if(filtros.local.id != null){
                    filtrosUrl += '&localDeOrigemId=' + filtros.local.id;
                }

                this.carregarLista(filtrosUrl);
            },            
            carregarLista(filtrosUrl){

                this.isLoading = true;
                this.$http.get('/api/portal/RelacaoDeExames' + filtrosUrl)
                    .then(res => {
                        this.model = res.data; 
                        
                        this.model.guias = [];
                        this.model.pacientes = [];
                        this.model.totalExames = 0.0;                        
                        this.model.totalValor = 0.0;

                        
                        this.model.lista.forEach(x => {

                            x.preco = (x.preco >= 0) ? x.preco : 0.0;

                            if(!this.model.pacientes.includes(x.pacienteId)){
                                this.model.pacientes.push(x.pacienteId);
                            }

                            if(!this.model.guias.find( y => y.guiaId == x.guiaId )){
                                this.model.guias.push({ 
                                    guiaId: x.guiaId, 
                                    pacienteNome: x.pacienteNome, 
                                    dataRetorno:x.dataRetorno,
                                    guiaReferencia: x.guiaReferencia
                                });
                            }                            
                            
                            this.model.totalExames++;
                            this.model.totalValor += x.preco ;

                        });

 
                        this.isLoading = false;
                    });


            },

        }       
    }
</script>