<template>
    <div class="modal-card">
        <header class="modal-card-head is-danger">
            <p class="modal-card-title">{{$t('CHECKPOINT.ALIQUOTAAMOSTRA')}}</p>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column is-6">
                    <label class="label">{{$t('SOLICITARNOVAAMOSTRA.AMOSTRABASE')}}</label>
                    <p>{{this.amostra.amostraCodigoDeBarras}}</p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-3">
                    <b-field label="Motivo">
                        <b-select expanded :placeholder="$t('SOLICITARNOVAAMOSTRA.SELECIONEMOTIVO')" v-model="motivo">
                            <option v-for="mot in motivos"
                                    :key="mot.descricaoInterno"
                                    :value="mot.descricaoInterno">
                                {{ mot.descricaoInterno }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-1">
                    <b-field label="Quantidade">
                        <b-input type="number" v-model="quantidadeDeAmostras" ></b-input>
                    </b-field>
                </div>  

                <div class="column is-3">
                    <b-field label="Material">
                        <b-select expanded :placeholder="$t('SOLICITARNOVAAMOSTRA.SELECIONEMATERIAL')" v-model="material">
                            <option v-for="mat in materiais"
                                    :key="mat.descricao"
                                    :value="mat.descricao">
                                {{ mat.descricao }}
                            </option>
                        </b-select>
                    </b-field>
                </div>                              
                
                <div class="column is-3">
                    <label class="label">{{$t('SOLICITARNOVAAMOSTRA.EXAMES')}}</label>
                    <b-field>
                        <b-taginput
                            v-model="examesSelecionados"
                            :data="exames"
                            autocomplete
                            :allow-new="false"
                            :open-on-focus="true"
                            field="exames"
                            icon="label"
                            :placeholder="$t('SOLICITARNOVAAMOSTRA.SELECIONAEXAMES')">
                        </b-taginput>
                    </b-field>
                </div>     
            </div>
            <hr>
            <label class="label">{{$t('SOLICITARNOVAAMOSTRA.OBSERVACOES')}}:</label>
            <div class="control">
                <textarea class="textarea" v-model="observacao"></textarea>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" v-if="!salvandoDados" type="button" @click="gerarNovasAmostras()">{{$t('SISTEMA.CONFIRMAR')}}</button>
            <button class="button is-success is-loading" v-else type="button">{{$t('SISTEMA.CONFIRMAR')}}</button>
            <button class="button" type="button" @click="$emit('close')">{{$t('SOLICITARNOVAAMOSTRA.FECHAR')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            amostra: Object
        },
                
        data() {
            return {
                motivos: [],
                materiais:[],
                cancelarAmostraBase: false,
                motivo: null,
                material: null,
                observacao: null,
                exames: this.amostra.itens.map(x =>x.exame.apelido),
                examesSelecionados: [],
                salvandoDados: false,
                quantidadeDeAmostras: 1
            }
        },
        computed: {
            ...mapState([
                'config',
            ])
        },
        mounted() {
            this.examesSelecionados = this.exames;
            this.materiais.push({descricao: this.amostra.material.nome + " - " + this.amostra.conservante.nome});
            
            this.loadMotivos()
        },
        methods: {
            loadMotivos() {
                this.$http.get('/api/search/MotivosAliquota')
                    .then(res => {
                        this.motivos = res.data
                    })
            },
            async gerarNovasAmostras(){
                if(this.motivo == null || this.motivo == '' || this.motivo == undefined){
                    this.$buefy.dialog.alert({
                        title: this.$t('SOLICITARNOVAAMOSTRA.MOTIVOOBRIGATORIO'),
                        message: this.$t('SOLICITARNOVAAMOSTRA.MENSAGEMMOTIVOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }


                if(this.material == null || this.material == '' || this.material == undefined){
                    this.$buefy.dialog.alert({
                        title: this.$t('SOLICITARNOVAAMOSTRA.MATERIALOBRIGATORIO'),
                        message: this.$t('SOLICITARNOVAAMOSTRA.MENSAGEMMATERIALOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }
                
                this.salvandoDados = true;

                const incluirAmostra = {
                    id: this.amostra.id,
                    amostraMae: this.amostra.id,
                    guiaId:  this.amostra.itens[0].guiaExame.guiaId, 
                    motivoDescricao: this.motivo, 
                    observacao: this.observacao,
                    cancelaAmostraBase: this.cancelarAmostraBase,
                    examesNovaAmostra: this.examesNovaAmostra(),
                    amostraNumero : null
                };

                for(let i = 0; i < this.quantidadeDeAmostras;i++){
                
                    incluirAmostra.amostraNumero = (i+2);
                    await this.$http.post(`/api/atendimento/IncluirAmostra`, incluirAmostra )
                        .then(({ data }) => {
                            
                            this.$emit('loadAmostras',data.codigoBarras);

                        })
                        .catch((error) => {
                            this.salvandoDados = false;
                            this.$buefy.toast.open({
                                    duration: 5000,
                                    message: error,
                                    type: 'is-danger'
                                })
                        });

                }
                
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('SOLICITARNOVAAMOSTRA.SUCESSO'),
                        type: 'is-success'
                    })

                        this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('SOLICITARNOVAAMOSTRA.EMAILENVIADO'),
                        type: 'is-success'
                    })                    

                
                this.$emit('close'); 
                 
            },
            examesNovaAmostra(){
                const examesNova = this.amostra.itens.filter(item => this.examesSelecionados.filter(selecionado => selecionado == item.exame.apelido).length > 0).map(x => x.guiaExame);

                const itensExamesNova = examesNova.map(x => x.item);

                return itensExamesNova;
            }
        }
    }
</script>