<template>
    <section>
        <div class="columns">
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !recebimentosAtrasadosCarregado && recebimentosAtrasados.length == 0" class="card is-warning">
                    <button class="button is-warning is-fullwidth" @click="carregarParcelasAtrasadas">
                        {{$t('MENUFINANCEIRO.BTNLISTARRECEBIMENTOSATRASADOS')}}
                    </button>
                </div>
                <b-collapse v-if="recebimentosAtrasados.length > 0" 
                            class="card"
                            animation="slide"
                            :open="isRecebimentosAtrasadosOpen"
                            @open="isRecebimentosAtrasadosOpen = !isRecebimentosAtrasadosOpen">
                    <div slot="trigger"
                         slot-scope="props"
                         class="card-header has-background-warning"
                         role="button">
                        <p class="card-header-title">
                            {{$t('MENUFINANCEIRO.RECEBIMENTOSATRASADOS')}}
                        </p>
                        <a class="card-header-icon">
                            <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                            <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                        </a>
                    </div>
                    <div class="panel">
                        <div v-for="(item, index) in recebimentosAtrasados" v-bind:key="index">
                            <router-link v-if="isInRole('receber-alterar')" :to="{ name: 'contas-receber', params: { id : item.id }}" class="panel-block is-justified-between">
                                <div>
                                    <span class="tag is-rounded is-light">{{item.dataDeVencimento | moment('DD/MM/YYYY')}}</span>
                                    <span>{{item.nome}}</span>
                                </div>
                                <span>{{item.valor | currency}}</span>
                            </router-link>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !pagamentosProximosCarregado && pagamentosProximos.length == 0" class="card is-warning">
                    <button class="button is-warning is-fullwidth" @click="carregarParcelasFuturas">{{$t('MENUFINANCEIRO.BTNLISTARPARCELASFUTURAS')}}</button>
                </div>
                <b-collapse v-if="pagamentosProximos.length > 0"
                            class="card"
                            animation="slide"
                            :open="isPagamentosProximosOpen"
                            @open="isPagamentosProximosOpen = !isPagamentosProximosOpen">
                    <div slot="trigger"
                         slot-scope="props"
                         class="card-header has-background-warning"
                         role="button">
                        <p class="card-header-title">
                            {{$t('MENUFINANCEIRO.PAGAMENTOSPROXIMOS')}}
                        </p>
                        <a class="card-header-icon">
                            <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                            <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                        </a>
                    </div>
                    <div class="panel">
                        <div v-for="(item, index) in pagamentosProximos" v-bind:key="index">
                            <router-link v-if="isInRole('pagar-alterar')" :to="{ name: 'contaspagar', params: { id : item.id }}" class="panel-block is-justified-between">
                                <div>
                                    <span class="tag is-rounded is-light">{{item.dataDeVencimento | moment('DD/MM/YYYY')}}</span>
                                    <span>{{item.descricao}}</span>
                                    <p>
                                        <small :title="$t('MENUFINANCEIRO.FORNECEDOR')">{{item.fornecedor}}</small>
                                    </p>
                                </div>
                                <span>{{item.valor | currency}}</span>
                            </router-link>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('receber-alterar') || isInRole('receber-consultar') || isInRole('receber-alterar-recepcao')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUFINANCEIRO.CONTASRECEBER')}} 
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'lista-contas-receber', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 v-model="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-contas-receber' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('receber-alterar')" :to="{ name: 'contas-receber' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link v-if="isInRole('receber-faturar')" :to="{ name: 'receber-faturar' }" class="button is-info is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNFATURAR')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('receber-parcela-lote-alterar') || isInRole('receber-parcela-lote-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.LOTECONTARECEBER')}} 
                    </p>
                    <form action="/financeiro/lista-parcela-lote" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'lista-parcela-lote' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('receber-parcela-lote-alterar')" :to="{ name: 'receber-parcela-lote' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                </div>
               
            </div>
            <div class="column">
                <div v-if="isInRole('pagar-alterar') || isInRole('pagar-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUFINANCEIRO.CONTASPAGAR')}}
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'listarpagar', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                    name="procurar" 
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'listarpagar' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('pagar-alterar')" :to="{ name: 'contaspagar' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>                 
                </div>
                <div v-if="isInRole('rateio-alterar') || isInRole('rateio-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.RATEIO')}} 
                    </p>
                    <form action="/financeiro/rateios" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'rateios' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('rateio-alterar')" :to="{ name: 'rateio' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                </div>                 
            </div>
            <div class="column">
                <div v-if="isInRole('pagar-parcela-lote-alterar') || isInRole('pagar-parcela-lote-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.LOTECONTAPAGAR')}}
                    </p>
                    <form action="/financeiro/lista-parcela-lote-pagar" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'lista-parcela-lote-pagar' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('pagar-parcela-lote-alterar')" :to="{ name: 'pagar-parcela-lote' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                    <p class="panel-heading">
                        <small>{{$t('MENUFINANCEIRO.BAIXAPAGAMENTOSPERIODO')}}</small>
                    </p>
                    <div class="panel-block">
                        <b-datepicker editable :placeholder="$t('MENUFINANCEIRO.PERIODO')"
                                      v-model="baixaPagamentosDatas"
                                      position="is-bottom-right"
                                      range>
                        </b-datepicker>
                    </div>
                    <router-link v-if="isInRole('pagar-baixar')" :to="{ name: 'baixapagamentos', 
                        params: { 
                            dataInicial: baixaPagamentosDatas[0],
                            dataFinal: baixaPagamentosDatas[1]
                        }}" class="panel-block button is-link">
                        <button class="button is-link is-fullwidth" type="button">{{$t('MENUFINANCEIRO.BTNBAIXAR')}}</button>
                    </router-link>                       
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('contaCorrente-alterar') || isInRole('contaCorrente-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.CONTACORRENTESCARTAO')}}
                    </p>
                    <form action="/financeiro/lista-conta-corrente" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-conta-corrente' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('contaCorrente-alterar')" :to="{ name: 'conta-corrente' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUFINANCEIRO.CENTRORECEITASEDESPESAS')}}
                    </p>
                    <div v-if="isInRole('categoriaFinanceira-alterar') || isInRole('categoriaFinanceira-consultar')" class="panel-block is-justified-between">
                        <router-link :to="{ name: 'categoria-financeira' }" class="button is-info is-fullwidth">
                            {{$t('MENUFINANCEIRO.CATEGORIAFINANCEIRA')}} 
                        </router-link>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'centro-resultados' }" class="button is-info is-fullwidth">
                            {{$t('MENUFINANCEIRO.CENTRORESULTADOS')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('bandeira-alterar') || isInRole('bandeira-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.BANDEIRAS')}} 
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'bandeiras', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                    name="procurar" 
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'bandeiras' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('bandeira-alterar')" :to="{ name: 'bandeira' }" class="button is-link is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="panel">
                    <p class="panel-heading background-2">
                        {{$t('CONCILIACAOBANCARIA.CONCILIACAO')}} 
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'importacoes-ofx' }" class="button is-light is-fullwidth">
                            {{$t('CONCILIACAOBANCARIA.IMPORTACOES')}}
                        </router-link>
                        <router-link :to="{ name: 'importar-ofx' }" class="button is-link is-fullwidth">
                            {{$t('CONCILIACAOBANCARIA.IMPORTAROFX')}}
                        </router-link>
                    </div>
                    <div v-if="isInRole('conciliacaoBancaria-conciliar-consultar')" class="panel-block is-justified-between">
                        <router-link :to="{ name: 'extrato-conciliacao' }" class="button is-info is-fullwidth">
                            {{$t('CONCILIACAOBANCARIA.CONCILIAR')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-2">
                <div class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.ACOMPANHAMENTO')}}
                    </p>
                    <div v-if="isInRole('relatorio-repasse-medico')" class="panel-block">
                        <router-link :to="{ name: 'repasses-medicos' }" class="button is-info is-fullwidth">
                            <span>{{$t('MENUFINANCEIRO.REPASSEMEDICO')}}</span>
                        </router-link>
                    </div>
                    <div v-if="isInRole('fluxodecaixa') || isInRole('receber-relacaoDeDebito') || isInRole('fechamentodecaixa')" class="panel-block">
                        <router-link :to="{ name: 'fluxo-caixa' }" class="button is-info is-fullwidth">
                            <span>{{$t('MENUFINANCEIRO.BTNFLUXOCAIXA')}}</span>
                        </router-link>
                    </div>                  
                </div>
            </div>

            <div class="column is-5">
                <div v-if="isInRole('estatisticasFinanceiro')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.BTNESTATISTICAS')}}
                    </p>
                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="estatisticasDatas[0]"
                                 :dataFinal.sync="estatisticasDatas[1]" />
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-checkbox v-model="somenteParticulares"
                                    size="is-small">
                            {{$t('MENUFINANCEIRO.SOMENTEPARTICULARES')}}
                        </b-checkbox>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'estatisticas', 
                            params: { 
                                dataInicial: this.$moment(estatisticasDatas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                                dataFinal: this.$moment(estatisticasDatas[1]).format('YYYY-MM-DDTHH:mm:ss'),
                                somenteParticulares: somenteParticulares
                            }}" class="button is-primary">
                            <button class="button is-primary" type="button">{{$t('MENUFINANCEIRO.BTNESTATISTICAS')}}</button>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="column is-3">
                <div class="panel">
                    <p class="panel-heading background-2">
                        {{$t('MENUFINANCEIRO.DRETITULO')}} 
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'dre-lista' }" class="button is-light is-fullwidth">
                            {{$t('SISTEMA.LISTAR')}}
                        </router-link>
                        <router-link v-if="isInRole('pagar-parcela-lote-alterar')" :to="{ name: 'dre' }" class="button is-link is-fullwidth">
                            {{$t('SISTEMA.INCLUIR')}} 
                        </router-link>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'emitir-dre' }" class="button is-info is-fullwidth">
                            {{$t('MENUFINANCEIRO.EMITIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        data() {
            return {
                procurar: null,
                recebimentosAtrasados: [],
                pagamentosProximos: [],
                baixaPagamentosDatas: [
                    this.$moment().subtract(3, 'days').toDate(),
                    this.$moment().toDate()
                ],
                relatorioDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                estatisticasDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],

                isRecebimentosAtrasadosOpen: false,
                isPagamentosProximosOpen: false,
                somenteParticulares: false,
                pagamentosProximosCarregado: false,
                recebimentosAtrasadosCarregado: false
            }
        },
        components: {
            periodoHora
        },
        computed: {
            ...mapState([
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            ...mapActions([
                'get'
            ]),
            carregarParcelasAtrasadas(){
                this.recebimentosAtrasadosCarregado = true;
                
                this.$http.get('api/Dashboard/receberParcelasAtrasadas')
                    .then(res => {
                        this.recebimentosAtrasados = res.data
                    });
            },
            carregarParcelasFuturas(){    
                this.pagamentosProximosCarregado = true;

                this.$http.get('api/Dashboard/pagarParcelasFuturas')
                    .then(res => {
                        this.pagamentosProximos = res.data
                    });
            }
        },
        mounted() {
            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarParcelasAtrasadas();
                this.carregarParcelasFuturas();
            }
        }
    }
</script>