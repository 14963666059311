<template>
    <section>
        <label class="label">{{agendamentosPendentes.length}} Agendamentos pendentes</label>
        <div v-for="(item, index) in agendamentosPendentes" v-bind:key="index">
            <div class="columns receberAgendamentoPendente">
                <div class="column is-2">
                    <label class="label">{{item.id}}</label>
                    <small>{{$moment(item.dataCriacao).format("DD/MM/YYYY HH:mm")}}</small>
                </div>  
                <div class="column is-9">
                    <div class="columns is-multiline">
                        <div class="column is-2">
                            <span>Funcionário: </span>
                            <br /><small>{{item.filtrosObj.funcionarioId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Data inicial: </span>
                            <br /><small>{{$moment(item.filtrosObj.dataInicial).format("DD/MM/YYYY HH:mm")}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Data final: </span>
                            <br /><small>{{$moment(item.filtrosObj.dataFinal).format("DD/MM/YYYY HH:mm")}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Ordenar por: </span>
                            <br /><small>{{item.filtrosObj.ordenaPor}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Filtrar por: </span>
                            <br /><small>{{item.filtrosObj.filtraPor}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Convênio: </span>
                            <br /><small>{{item.filtrosObj.convenioId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Unidade: </span>
                            <br /><small>{{item.filtrosObj.unidadeId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Local: </span>
                            <br /><small>{{item.filtrosObj.localDeOrigemId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Setor: </span>
                            <br /><small>{{item.filtrosObj.setorId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Departamento: </span>
                            <br /><small>{{item.filtrosObj.departamentoId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Tipo de convênio: </span>
                            <br /><small>{{item.filtrosObj.tipoDeConvenioId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Guias: </span>
                            <br /><small>{{item.filtrosObj.guiaId}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Somente faturados: </span>
                            <br /><small>{{item.filtrosObj.somenteFaturados}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Somente resultado assinado: </span>
                            <br /><small>{{item.filtrosObj.somenteResultadoAssinado}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Material entregue: </span>
                            <br /><small>{{item.filtrosObj.materialEntregue}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Incluir convênio particular: </span>
                            <br /><small>{{item.filtrosObj.incluirConvenioParticular}}</small>
                        </div>
                        <div class="column is-2">
                            <span>Somente guia conferida: </span>
                            <br /><small>{{item.filtrosObj.somenteGuiaConferida}}</small>
                        </div>
                    </div>
                </div>  
                <div class="column is-1">
                    <b-button class="is-danger"
                            size="is-small"
                            :loading="loading"
                            @click="cancelar(item, index)">Cancelar</b-button>
                </div> 
            </div>
        </div>
    </section>
</template>


<script>
    export default {
        data() {
            return {
                agendamentosPendentes: [],
                loading: false
            }
        },
        created() {
            this.$http.get('/api/financeiro/agendamentosPendentes')
                .then(res => {
                    this.agendamentosPendentes = res.data;

                    this.agendamentosPendentes.forEach(item => {
                        item.filtrosObj = JSON.parse(item.filtros);
                    });
                });
        },
        methods:{
            cancelar(item, index){
                this.loading = true;
                this.$http.post(`/api/financeiro/cancelarAgendamentoPendente`, item )
                    .then(() => {
                        this.loading = false;
                        this.agendamentosPendentes.splice(index, 1);
                    })
                    .catch((error) => {
                        this.loading = false;
                        throw error
                    }); 
            }
        }
    }
</script>