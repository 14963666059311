<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="columns box">
            <div class="column is-6">
                <searchIdName @item="selecionouContaCorrente" :id.sync="contaCorrente.id" :item.sync="contaCorrente" table="ContaCorrente" :label="$t('CONCILIACAOBANCARIA.CONTACORRENTE')"/>
            </div>
            <div class="column is-4">
                <b-field :label="$t('CONCILIACAOBANCARIA.APARTIRDE')">
                    <b-datepicker @input="listarExtratoBancarioParaConciliacao" v-model="aPartirDe"></b-datepicker>
                </b-field>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="isLoading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="changePage"
                 :aria-next-label="$t('CONCILIACAOBANCARIA.PROXPAGINA')"
                 :aria-previous-label="$t('CONCILIACAOBANCARIA.PAGANTERIOR')"
                 :aria-page-label="$t('CONCILIACAOBANCARIA.PAGINA')"
                 :aria-current-label="$t('CONCILIACAOBANCARIA.PAGATUAL')"
                 focusable
            >
            <template>
                <b-table-column  v-slot="props" field="Id" :label="$t('CONCILIACAOBANCARIA.DATASALDO')">
                    {{ props.row.saldoBancario.dataSaldo | moment("DD/MM/YYYY") }}
                </b-table-column>
                <b-table-column  v-slot="props" field="Nome" :label="$t('CONCILIACAOBANCARIA.SITUACAOSALDO')">
                    {{ props.row.estaConciliado ? $t('CONCILIACAOBANCARIA.ESTACONCILIADO') : $t('CONCILIACAOBANCARIA.NAOESTACONCILIADO')}}
                </b-table-column>
                  <b-table-column  v-slot="props" field="PeriodoInicial" :label="$t('CONCILIACAOBANCARIA.SALDO')">
                    {{ props.row.saldoBancario.saldo / 100 | currency }}
                </b-table-column>
                <b-table-column v-slot="props" custom-key="actions" numeric v-if="isInRole('conciliacaoBancaria-conciliar')">
                    <router-link :to="{ name: 'conciliacao-diaria', params: { dataTransacao: $moment(props.row.saldoBancario.dataSaldo).format('YYYY-MM-DD'), contaCorrenteId:props.row.saldoBancario.contaCorrente.id }}" class="button is-small is-primary" title="Conciliar">
                        <b-icon icon="link" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
        
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                model: {},
                contaCorrente: {
                    id: null,
                    nome: null
                },
                aPartirDe: new Date(),
                total: 0,
                isLoading: false,
                page: 1,
                perPage: 20,
            };
        },
        components: {
            titlebar,
            searchIdName,
        },
        created() {
            if (this.$route.params.contaCorrenteId)
                this.contaCorrente.id = this.$route.params.contaCorrenteId
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    this.$t('CONCILIACAOBANCARIA.CONCILIACAO'),
                    this.$t('CONCILIACAOBANCARIA.EXTRATOCONCILIACAO')
                ]
            },          
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            listarExtratoBancarioParaConciliacao() {
                if (!this.contaCorrente.id) return

                const data = this.$moment(this.aPartirDe).format()
                const params = [
                    `page=${this.page}`,
                    `contaCorrenteId=${this.contaCorrente.id}`,
                    `dataInicial=${data}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/ConciliacaoBancaria/listarExtratoBancario?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.listarExtratoBancarioParaConciliacao()
                        }
                        this.total = this.perPage * data.pageCount
                        this.isLoading = false
                        this.$router.replace({query: {page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.isLoading = false
                        throw error
                    })
            },
            changePage(page) {
                this.page = page
                this.listarExtratoBancarioParaConciliacao()
            },
            selecionouContaCorrente(item)
            {
                if (item.data != null)
                {
                    this.$router.replace({params: { contaCorrenteId: item.data.id}})
                    this.isLoading = true
                    this.$http.get(`/api/ConciliacaoBancaria/BuscarUltimaTransacaoPendente?contaCorrenteId=${item.data.id}`)
                    .then(({ data }) => {
                        if (data) {
                            this.aPartirDe = new Date(data.dataTransacao)
                            this.listarExtratoBancarioParaConciliacao()
                        } 
                        else {
                            this.model = {}
                            this.isLoading = false
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        throw error
                    })
                }
            }
        }
    }
</script>