<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALREAGENDAMENTOEXAMES.REAGENDAMENTOEXAMES')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALREAGENDAMENTOEXAMES.ATENCAO')}}</h5>
                <p>{{$t('MODALREAGENDAMENTOEXAMES.AVISOREAGENDAMENTO')}}</p>
            </b-notification>
            <b-notification v-if="erroReagendamentoExames"
                            type="is-danger">
                {{ erroReagendamentoExames }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALREAGENDAMENTOEXAMES.SELECIONAEXAMESREAGENDAMENTO')">
                    </b-field>
                </p>
                 <div class="panel-block"> 
                    <b-field>
                        <b-taginput
                            v-model="examesSelecionados"
                            :data="exames"
                            autocomplete
                            :allow-new="false"
                            :open-on-focus="true"
                            field="exames"
                            icon="label"
                            :placeholder="$t('SISTEMA.SELECIONAEXAMES')">
                        </b-taginput>
                    </b-field>
                </div>
            </div>       
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="verificaExamesSelecionados()">{{$t('MODALREAGENDAMENTOEXAMES.CONFIRMARREAGENDAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            evento: Object             
        },
        data() {
            return {
                erroReagendamentoExames: null,
                examesSelecionados: [],
                exames: this.evento.guiaAgendaExames.map(y => y.apelido)
            }
        },
        mounted() {
            this.examesSelecionados = this.exames
        },
        methods: {
            verificaExamesSelecionados()
            {
                if (!this.examesSelecionados.length) {
                   this.$buefy.toast.open({
                        duration: 10000,
                        message: this.$t('MODALREAGENDAMENTOEXAMES.REAGENDAMENTOEXAMESERROMESSAGE'),
                        type: 'is-danger',
                        queue: false
                    })
                    
                   return; 
                }
                else {
                    this.confirmarReagendamentoExames();
                }
            },

            confirmarReagendamentoExames() 
            {
                if(this.examesSelecionados!=null){
                    try {
                        const examesNova = this.evento.guiaAgendaExames.filter(item => this.examesSelecionados.filter(selecionado => selecionado == item.apelido).length > 0).map(x => x.apelido);
                        const reagendarTodosExames = this.evento.guiaAgendaExames.every(item =>
                            examesNova.includes(item.apelido)
                        );
                        this.evento.guiaAgendaExames = this.evento.guiaAgendaExames.filter(item => examesNova.includes(item.apelido));
                        this.evento.guiaAgendaExamesNome = this.evento.guiaAgendaExamesNome.filter(item => examesNova.includes(item));
                        
                        //se for true não entra no if, todos os exames serão reagendados e o agendamento anterior será cancelado
                        //caso contrário, será reagendado apenas os exames selecionados e no agendamento anterior serão excluídos os exames selecionados
                        if(!reagendarTodosExames){
                            this.evento.reagendar = true;
                        }

                        this.$router.push({ name: 'inspecao',params: { reagendamento: this.evento }  });   
                        this.$emit('close')                        
                    } catch (e) {
                        console.error(e);
                    }
                }
            },
            

        }
    }
</script>