<template>
    <pesquisasClinicas portal="true"></pesquisasClinicas>
</template>


<script>
    import pesquisasClinicas from '@/views/atendimento/pesquisaclinica.vue'

    export default {
        data() {
            return {
            };
        },
        components: {
            pesquisasClinicas
        }
    }

</script>