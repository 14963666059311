<template>
    <section >   
        <titlebar :title-stack="titleStack"></titlebar>    
              
        <filtros :tipo="'Integracoes'" @filtrar="filtrar($event)"></filtros>  
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>


        <div v-if="(model!=null && model.lista.length>0)" class="tile is-ancestor centralizar">
            <div class="tile is-12 is-centered is-vertical is-parent">
                <div class="tile is-child box">
                    <p class="title" style="display: contents;">{{model.sub}}</p>                     
                </div>             

            </div>
        </div>

        <b-table v-if="(model!=null && model.lista.length > 0)" :data="model.lista"
                 :loading="isLoading"
                 striped
                 hoverable>

            <template>

                <b-table-column v-slot="props" field="guiaId" label="Guia">
                    <router-link v-show="( props.row.guiaId !=null)"  :to="{ name: 'portalconvenioguia', params: { id: props.row.guiaId }}"  class="button-effect" >
                        {{ props.row.guiaId }}
                    </router-link>                    
                    
                </b-table-column>                

                <b-table-column v-slot="props" field="codigo" label="Código">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="guiaId" label="Código">
                    {{ props.row.id }}
                </b-table-column>                                   

                <b-table-column v-slot="props" field="tipo" label="Tipo">
                    {{ props.row.tipo }}
                </b-table-column>

                <b-table-column v-slot="props" field="mensagem" label="Mensagem">
                    {{ props.row.mensagem }}
                </b-table-column>

                <b-table-column v-slot="props" field="data" label="Data">
                    {{ $moment(props.row.timestamp).format("DD/MM/YYYY HH:mm") }}                    
                </b-table-column>

                                          
            </template>
        </b-table>        

            
  
        </div>
    </section> 
</template>


<style scoped>

    .button-effect:hover {
        background-color: #e9efe9 !important;
    }
    .button-effect{
        cursor: pointer !important;
        color: blue;
    }

</style>


<script>
    import { mapActions, mapState } from 'vuex'
    
    import filtros from '@/components/portal/filtros.vue';
    import titlebar from '@/components/titlebar.vue';
            

    export default {
        data() {
            return {
                model: null,                
                isLoading: false,
                checkboxPosition: 'left',
                checkedRows: [],                
                
            }
        },
        
        computed: {
            titleStack() {
                return [
                    'Portal',
                    'Relatório de Integrações'                                        
                ]
            },                
            ...mapState([
                'config',
                'usuario'
                
            ])
        },
        components: {            
            filtros,
            titlebar

        },
        methods: {
  
                        
            filtrar(filtros){         
                
                if(filtros.integradorId){
                
                    const filtrosUrl =  '?dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                        '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')+ '&integradorId=' + filtros.integradorId;      

                    this.carregarLista(filtrosUrl);
                }
            },            
            carregarLista(filtrosUrl){

                this.isLoading = true;
                this.$http.get('/api/portal/RelatorioDeIntegracao' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;                                              
                        this.isLoading = false;                        
                    });


            },

        }       
    }
</script>