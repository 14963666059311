<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('REGRAAGENDAMENTOLISTA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="buttons">
                    <router-link v-if="isInRole('regras-agendamento-alterar')" :to="{ name: 'regradeagendamento' }" class="button is-primary">
                        {{$t('REGRAAGENDAMENTOLISTA.INCLUIR')}}
                    </router-link>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('REGRAAGENDAMENTOLISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('REGRAAGENDAMENTOLISTA.PROXPAGINA')"
                 :aria-previous-label="$t('REGRAAGENDAMENTOLISTA.PAGANTERIOR')"
                 :aria-page-label="$t('REGRAAGENDAMENTOLISTA.PAGINA')"
                 :aria-current-label="$t('REGRAAGENDAMENTOLISTA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column v-slot="props" field="id" :label="$t('REGRAAGENDAMENTOLISTA.CODIGO')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="Nome" :label="$t('REGRAAGENDAMENTOLISTA.NOME')" sortable>
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Nome" :label="$t('REGRAAGENDAMENTOLISTA.SLOT')" sortable>
                    {{ props.row.slot }}
                </b-table-column>                

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'regradeagendamento', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('REGRAAGENDAMENTOLISTA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? "",
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REGRAAGENDAMENTOLISTA.MANUTENCAO'),
                    this.$t('REGRAAGENDAMENTOLISTA.TIPOAGENDAMENTO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/regrasAgendamentos?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/manutencao/regraagendamento/${this.id}` })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>