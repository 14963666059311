<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('DRELISTA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="buttons">
                    <router-link :to="{ name: 'dre' }" class="button is-primary">
                        {{$t('SISTEMA.INCLUIR')}}
                    </router-link>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('DRELISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('DRELISTA.PROXPAGINA')"
                 :aria-previous-label="$t('DRELISTA.PAGANTERIOR')"
                 :aria-page-label="$t('DRELISTA.PAGINA')"
                 :aria-current-label="$t('DRELISTA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 focusable
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="id" :label="$t('DRELISTA.CODIGO')">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Nome" :label="$t('DRELISTA.NOME')">
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Banco" :label="$t('DRELISTA.SEQUENCIA')">
                    {{ props.row.sequencia }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Agencia" :label="$t('DRELISTA.OPERADOR')">
                    {{ props.row.operador == 1 
                        ? '+' 
                        : props.row.operador == 2 
                            ? '-'
                            : '=' 
                    }}
                </b-table-column>     

                <b-table-column  field="desativado" label="">
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        <b-icon v-show="props.row.desativado" type="is-danger" icon="close-circle" title="Desativado"></b-icon>
                    </template>                       
                </b-table-column>

                <b-table-column  v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'dre', params: { id: props.row.id }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from "@/components/titlebar.vue";

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: '',
                ordenarPor: 'Sequencia',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null
            }
        },
        computed: {
            titleStack() {
                return [this.$t('SIDEBAR.FINANCEIRO'),
                this.$t('DRELISTA.TITULO')];
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        components: {
            titlebar
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `page=${this.page}`,
                ].join('&')

                this.loading = true
                this.$http.get(`/api/financeiro/ListaDres?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/financeiro/dre/${this.id}` })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>