<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('IMPOSTOCADASTRO.SALVANDOCADASTRO')}}
                </div>
        
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('IMPOSTOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="64" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                       
                        </article>
                    </div>
                </div>

                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('IMPOSTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('IMPOSTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    nome: null
                }
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONDICOESCADASTRO.MANUTENCAO'),
                    this.$t('IMPOSTOCADASTRO.IMPOSTO'),
                    this.$t('SISTEMA.NOVO')
                ]
            }
        },        
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/imposto`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            }
        }
    }
</script>