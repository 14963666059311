<template>
    <div>
        <div>
            <b-message type="is-info" has-icon v-if="pesquisas != null && pesquisas.length > 0">
               {{$t('PORTAL.PESQUISA')}}:
                <p class="buttons">
                    <b-button v-for="(pesquisa, index) in pesquisas" :key="index" type="is-primary"
                              native-type="button"
                              @click="$router.push({ path: `/portal/pesquisassatisfacao/${pesquisa.id}` })">
                        {{pesquisa.nome}}
                    </b-button>
                </p>
            </b-message>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            pesquisas: Array,
            tipo: String
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        }
    }

</script>