<template>
    <div class="control">
        <div v-for="(item, index) in interno.items" :key="index">
            <b-field>
                <b-input type="text"
                         v-model="item[campoValor]"
                         @blur="buscarCbos(item)"
                         :loading="item.buscando"
                         v-on:keydown.enter.prevent.stop="buscarCbos(item)"
                         maxlength="6"
                         :has-counter="false"
                         :ref="'cbos' + index"></b-input>
                <div class="input" v-if="item.descricao">{{ item.descricao }}</div>
            </b-field>
        </div>
        <b-button @click="adicionar()" title="Adicionar" expanded>
            Incluir CBO-S
        </b-button>
    </div>
</template>

<script>

    import { mapGetters, mapState } from 'vuex'
    export default {
        components: {},
        props: {
            items: null,
            campoChave: null,
            campoValor: null,
        },
        data() {
            return {
                interno: {
                    value: null,
                    items: this.items?.length ? this.items : [],
                    buscando: false,
                },
            };
        },
        watch: {
            items() {
                this.interno = {
                    items: this.items?.length ? this.items : [],
                    value: null,
                    buscando: false,
                };
            },
        },
        computed: {
            ...mapState([                
                'config'            
            ])
        },        
        methods: {
            async adicionar() {
                try {
                    this.interno.buscando = true;

                    const newItem = { [this.campoChave]: 0, [this.campoValor]: null };
                    this.interno.items.push(newItem);
                    this.emitUpdate(this.interno.items, this.campoChave, this.campoValor);

                    this.$nextTick(() => {
                        this.$refs['cbos' + (this.interno.items.length - 1)][0].focus()
                    })

                } finally {
                    this.interno.buscando = false;
                }
            },
            async buscarCbos(item) {
                
                if (!item) return;
                item.buscando = false;
                item.encontrado = false;
                item.naoLocalizado = false;
                //Removendo caso o usuário tenha apagado os valores
                if (!item[this.campoValor]?.length) {
                    this.interno.items = this.interno.items.filter(
                        (i) => i[this.campoValor] != item[this.campoValor]
                    );
                    return;
                }
             
                let result = { data: null };
                try {
                    item.buscando = true;
                    const url =  this.config.cboUrl+item[this.campoValor];                    
                    result = await this.$http
                        .get(url)
                        .catch((e) => {
                            throw e;
                        });
                    item.encontrado = true;
                } catch (ex) {
                    item.buscando = false;
                    item.naoLocalizado = true;
                    return null;
                } finally {
                    item.buscando = false;
                    this.$forceUpdate();
                }

                return result?.data;
            },
            emitUpdate(items, chave, valor) {
                this.$emit(
                    "update:items",
                    items
                );
            },
        },
    };
</script>
