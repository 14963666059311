<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-9">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REDUCAOACRESCIMOCADASTRO.NOME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REDUCAOACRESCIMOCADASTRO.NOME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REDUCAOACRESCIMOCADASTRO.PRIMEIROEXAME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REDUCAOACRESCIMOCADASTRO.PRIMEIROEXAME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="number" v-model="model.primeiroExame" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REDUCAOACRESCIMOCADASTRO.SEGUNDOEXAME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REDUCAOACRESCIMOCADASTRO.SEGUNDOEXAME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="number" v-model="model.segundoExame" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('REDUCAOACRESCIMOCADASTRO.TERCEIROEXAME')">
                                <b-input type="number" step="0.01" v-model="model.terceiroExame" :has-counter="true"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('REDUCAOACRESCIMOCADASTRO.DEMAIS')">
                                <b-input type="number" step="0.01" v-model="model.demais" :has-counter="true"></b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="column">
                        <searchIdName class="column is-12" :id.sync="model.setorId.id" table="Setor" 
                            :label="$t('REDUCAOACRESCIMOCADASTRO.SETOR')" />
                    </div>

                    <div class="column">
                        <searchIdName class="column is-12" :id.sync="model.tabelaId.id" table="TabelaPreco" 
                            :label="$t('REDUCAOACRESCIMOCADASTRO.TABELA')" />
                    </div>

                </article>
            </div>

            <div class="tile is-parent">
                <article class="tile is-child">
                    <article class="panel">
                        <div class="panel-block">
                            <b-switch v-model="model.ch">
                                {{$t('REDUCAOACRESCIMOCADASTRO.CH')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.co">
                                {{$t('REDUCAOACRESCIMOCADASTRO.CO')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.porte">
                                {{$t('REDUCAOACRESCIMOCADASTRO.PORTE')}}
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.filme">
                                {{$t('REDUCAOACRESCIMOCADASTRO.FILME')}}
                            </b-switch>
                        </div>
                    </article>
                </article>
            </div>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                v-if="isInRole('reducaoacrescimo-alterar')"
                                native-type="button"
                                icon-left="check-circle"
                                :loading="loading"
                                @click="onSubmit()">
                        {{$t('EXAMECADASTRO.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                         {{$t('EXAMECADASTRO.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue';

    export default {
        data() {
            return {
                model: {
                    nome: null,
                    primeiroExame: null,
                    segundoExame: null,
                    terceiroExame: null,
                    demais: null,
                    tabelaId: {
                        id: null
                    },
                    setorId: {
                        id: null
                    }
                },
                loading: false
            }
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
            this.$http.get('/api/manutencao/reducaoacrescimo?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REDUCAOACRESCIMOCADASTRO.MANUTENCAO'),
                    this.$t('REDUCAOACRESCIMOCADASTRO.REDUCAOACRESCIMO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.loading = true;
                
                if(!this.model.nome){                    
                    this.$buefy.toast.open({
                        duration: 3000,
                        message: "Insira o nome da Redução/Acrescimo!",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.primeiroExame){
                    this.$buefy.toast.open({
                        message: "Insira o Primeiro Exame!",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;        
                }
                
                else if(!this.model.segundoExame){
                    this.$buefy.toast.open({
                        message: "Insira o Segundo Exame!",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;        
                }

                else if(!this.model.setorId.id){
                    this.$buefy.toast.open({
                        message: "Insira o ID do setor!",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;        
                }

                else if(!this.model.tabelaId.id){
                    this.$buefy.toast.open({
                        message: "Insira o ID da tabela!",
                        type: 'is-warning'
                    })                    
                    this.loading = false;
                    
                    return null;        
                }         
                
                const newItem = Object.assign({}, this.model);   

                this.$http.post('/api/manutencao/reducaoacrescimo', newItem)
                    .then(() => {
                        this.$route.params.id ? this.$router.back() : this.$router.push({ name: 'reducoesacrescimos' })
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('REDUCAOACRESCIMOCADASTRO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                        
                        this.loading = false;
                    })

            }
        }

    }
</script>