<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"> </titlebar>
        <buscaLancamentoFinanceiroParaConciliar 
            v-if="conciliacaoSelecionadaParaBusca"
            :conciliacao="conciliacaoSelecionadaParaBusca"
            :lancamentos-selecionados="lancamentosSelecionados"
            @confirmou="desselecionarConciliacao"
            @selecionouLancamentoFinanceiro="adicionarLancamentoFinanceiro"
            @desselecionouLancamentoFinanceiro="removerLancamentoFinanceiro"
        ></buscaLancamentoFinanceiroParaConciliar>
        <conciliacaoDiaria 
            v-else
            :dados-conciliacao="model"
            @conciliou="atualizaParaConciliado"
            @desconciliou="atualizaParaDesconciliado"
            @selecionouTransacao="selecionarConciliacao"
        >
        </conciliacaoDiaria>
    </section>
</template>

<style scoped>

</style>
<script>

import { mapGetters, mapState } from "vuex"
import titlebar from "@/components/titlebar.vue"
import conciliacaoDiaria from "@/components/conciliacaoBancaria/conciliacaoDiaria.vue"
import buscaLancamentoFinanceiroParaConciliar from "@/components/conciliacaoBancaria/buscaLancamentoFinanceiroParaConciliar.vue"
import moment from "moment"

export default {
    data() {
        return {
            conciliacaoSelecionadaParaBusca: null,
            model: {
                conciliacoes: [],
                estaConciliado: false
            },
            isLoading: false,
        };
    },
    components: {
        titlebar,
        conciliacaoDiaria,
        buscaLancamentoFinanceiroParaConciliar
    },
    created() {
        this.isLoading = true
        const data = moment(this.$route.params.dataTransacao).format('YYYY-MM-DD')
        const filtros = `?dataTransacao=${data}&contaCorrenteId=${this.$route.params.contaCorrenteId}`
        this.$http.get(`/api/ConciliacaoBancaria/listarConciliacaoDiaria${filtros}`)
            .then(res => {
                this.model = res.data;
                this.isLoading = false
            })
            .catch((error) => {
                this.isLoading = false
                throw error
            });
    },
    computed: {
        titleStack() {
            return [
                'Financeiro',
                this.$t('CONCILIACAOBANCARIA.CONCILIACAO'),
                this.$t('CONCILIACAOBANCARIA.CONCILIAR')
            ]
        },
        lancamentosSelecionados()
        {
            const lancamentosSelecionados = {
                receber: [],
                pagar: []
            }

            this.model.conciliacoes.forEach(conciliacao => {
                conciliacao.lancamentosFinanceiros.forEach(lancamento => {
                    if (lancamento.parcelaReceber != null)
                        lancamentosSelecionados.receber.push(lancamento.parcelaReceber.id)

                    if (lancamento.parcelaPagar != null)
                        lancamentosSelecionados.pagar.push(lancamento.parcelaPagar.id)
                })
            })

            return lancamentosSelecionados
        }
    },
    methods: {
        selecionarConciliacao(id)
        {
            const conciliacao = this.model.conciliacoes.find(conciliacao => conciliacao.transacao.id == id)
            this.conciliacaoSelecionadaParaBusca = conciliacao
        },
        desselecionarConciliacao()
        {
            this.conciliacaoSelecionadaParaBusca = null
        },
        atualizaParaConciliado()
        {
            this.model.estaConciliado = true
        },
        atualizaParaDesconciliado()
        {
            this.model.estaConciliado = false
        },
        adicionarLancamentoFinanceiro(lancamentoFinanceiro)
        {
            const conciliacao = this.model.conciliacoes.find(conciliacao => conciliacao.transacao.id == this.conciliacaoSelecionadaParaBusca.transacao.id)
            conciliacao.lancamentosFinanceiros = [...conciliacao.lancamentosFinanceiros, lancamentoFinanceiro]
        },
        removerLancamentoFinanceiro(parcelaId, tipoParcela)
        {
            const conciliacao = this.model.conciliacoes.find(conciliacao => conciliacao.transacao.id == this.conciliacaoSelecionadaParaBusca.transacao.id)
            conciliacao.lancamentosFinanceiros = conciliacao.lancamentosFinanceiros.filter(lancamentoFinanceiro => {
                if (tipoParcela == 'Pagar' && lancamentoFinanceiro.parcelaPagar != null)
                {
                    return parcelaId != lancamentoFinanceiro.parcelaPagar.id
                }

                if (tipoParcela == 'Receber' && lancamentoFinanceiro.parcelaReceber != null)
                {
                    return parcelaId != lancamentoFinanceiro.parcelaReceber.id
                }

                return true
            })
        }
    }
}

</script>