<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONFIGTISSCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input v-model="model.nome" maxlength="70" :has-counter="true"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                            
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.ANS')">
                                        <b-input v-model="model.registroANS" maxlength="6" :has-counter="true"></b-input>
                                    </b-field>
                                </div>                            
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.LOG')">
                                        <b-input v-model="model.logradouro" maxlength="40" :has-counter="true"></b-input>
                                    </b-field>
                                </div>                            
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.COMPLEMENTO')">
                                        <b-input v-model="model.complemento" maxlength="15" :has-counter="true"></b-input>
                                    </b-field>
                                </div> 
                                <div class="column">
                                   <busca-cep :cep.sync="model.cep" />
                                </div>                                                            
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.MUN')">
                                        <b-input v-model="model.municipio" maxlength="40" :has-counter="true"></b-input>
                                    </b-field>
                                </div>                            
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.MUNIBGE')">
                                        <b-input v-model="model.municipioIBGE" maxlength="7" :has-counter="true"></b-input>
                                    </b-field>
                                </div> 
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.UF')">
                                        <b-input v-model="model.uf" maxlength="2" :has-counter="true"></b-input>
                                    </b-field>
                                </div>                                                            
                            </div>   
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONFIGTISSCADASTRO.CNPJ')}}</label>
                                        <input class="input" v-model="model.cnpj" v-mask="['##.###.###/####-##']" />
                                    </div>
                                </div>                            
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.CNES')">
                                        <b-input v-model="model.cnes" maxlength="7" :has-counter="true"></b-input>
                                    </b-field>
                                </div> 
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.ULTIMOSEQ')">
                                        <b-input type="number" v-model="model.ultimoSequencial" max="999999999"></b-input>
                                    </b-field>
                                </div>                            
                                <div class="column">
                                    <b-field :label="$t('CONFIGTISSCADASTRO.ULTIMOLOTE')">
                                        <b-input type="number" v-model="model.ultimoLote" max="999999999"></b-input>
                                    </b-field>
                                </div> 
                            </div>              
                            <div class="column">
                                <cadastro-items :label="$t('CONFIGTISSCADASTRO.UNIDADES')" :items.sync="model.unidades" tabela="unidade" />
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">                            
                            <article class="panel">
                                <historicoAlteracoes tabela="configuracaoTISS" />
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                               {{$t('CONFIGTISSCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CONFIGTISSCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCep from '@/components/buscaCep.vue'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import cadastroItems from '@/components/manutencao/cadastroItems.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    cep: null,
                    cnes: null,
                    cnpj: null,
                    complemento: null,
                    logradouro: null,
                    municipio: null,
                    municipioIBGE: null,
                    nome: null,
                    numero: null,
                    registroANS: null,
                    uf: null,
                    ultimoLote: null,
                    ultimoSequencial: null,
                    unidades: []
                },

                salvandoDados: false,
            };
        },
        components: {
            buscaCep,
            titlebar,
            historicoAlteracoes,
            cadastroItems
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/configuracaoTISS?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                      
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONFIGTISSCADASTRO.MANUTENCAO'),
                    this.$t('CONFIGTISSCADASTRO.CONFIGTISS'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id){
                    delete newItem.id;
                }
                //Removendo registros adicionados mas não preenchidos
                newItem.unidades = newItem.unidades?.filter(u => u.id != null);

                this.salvandoDados = true;
                this.$http.post('/api/manutencao/ConfiguracaoTISS', newItem)
                    .then(() => {
                        this.$router.push({ name: 'manutencao' })
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CONFIGTISSCADASTRO.ERROSALVAR'),
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            }          
        }
    }
</script>