<template>
    <section>
        <template v-if="editavel">
            <div class="panel">
                <b-checkbox-button v-model="guia.sepse" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-sepse">
                    <span>{{$t('GUIAINDICADORES.SEPSE')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.dorToracica" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-dortoracica">
                    <span>{{$t('GUIAINDICADORES.DORTORACICA')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.avc" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-avc">
                    <span>{{$t('GUIAINDICADORES.AVC')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.traumatologia" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-traumatologia">
                    <span>{{$t('GUIAINDICADORES.TRAUTOMALOGIA')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.prestesAReceberAltaMedica" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-prestesareceberaltamedica">
                    <span>{{$t('GUIAINDICADORES.RECEBERALTA')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.guiaPendente" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-guiapendente">
                    <span>{{$t('GUIAINDICADORES.GUIAPENDENTE')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.necessidadesEspeciais" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-necessidadesespeciais">
                    <span>{{$t('GUIAINDICADORES.NECESSIDADES')}}</span>
                </b-checkbox-button>
                <b-checkbox-button v-if="isInRole('usa-guia-conferida')"
                                   v-model="guia.guiaConferida" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-guiaconferida">
                    <span>{{$t('GUIAINDICADORES.GUIACONFERIDA')}}</span>
                </b-checkbox-button>
            </div>
        </template>
        <template v-else>
            <span v-if="guia.sepse" class="tag guia-indicador-sepse">
                {{$t('GUIAINDICADORES.SEPSE')}}
            </span>
            <span v-if="guia.dorToracica" class="tag guia-indicador-dortoracica">
                {{$t('GUIAINDICADORES.DORTORACICA')}}
            </span>
            <span v-if="guia.avc" class="tag guia-indicador-avc">
                {{$t('GUIAINDICADORES.AVC')}}
            </span>
            <span v-if="guia.traumatologia" class="tag guia-indicador-traumatologia">
                {{$t('GUIAINDICADORES.TRAUTOMALOGIA')}}
            </span>
            <span v-if="guia.prestesAReceberAltaMedica" class="tag guia-indicador-prestesareceberaltamedica">
                {{$t('GUIAINDICADORES.RECEBERALTA')}}
            </span>
            <span v-if="guia.guiaPendente" class="tag guia-indicador-guiapendente">
               {{$t('GUIAINDICADORES.GUIAPENDENTE')}}
            </span>
            <span v-if="guia.necessidadesEspeciais" class="tag guia-indicador-necessidadesespeciais">
                {{$t('GUIAINDICADORES.NECESSIDADES')}}
            </span>
            <span v-if="guia.guiaConferida && isInRole('usa-guia-conferida')" class="tag guia-indicador-guiaconferida">
                {{$t('GUIAINDICADORES.GUIACONFERIDA')}}
            </span>
        </template>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        props: {
            guia: Object,
            editavel: Boolean
        },
        computed: {
            ...mapState([
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
    }
</script>