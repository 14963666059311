<template>
    <div class="panel">
        <div class="panel-heading">
            {{$t('FILTROS.FILTRO')}}
        </div>
        <div class="panel-block columns is-multiline">
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.funcionario.id" :item.sync="filtros.funcionario" table="Funcionario" :label="$t('SISTEMA.FUNCIONARIO')"/>
            </div>
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.medico.id" :item.sync="filtros.medico" table="Medico" :label="$t('SISTEMA.SOLICITANTE')"/>
            </div>
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.convenio.id" :item.sync="filtros.convenio" table="Convenio" :label="$t('SISTEMA.CONVENIO')"/>
            </div>
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.tipoConvenio.id" :item.sync="filtros.tipoConvenio" table="TipoConvenio" :label="$t('SISTEMA.TIPOCONVENIO')"/>
            </div>
            <div :class="tipo == 'fluxoCaixa' ? 'column is-full is-full-mobile' : 'column is-6 is-full-mobile'">
                <searchIdName :id.sync="filtros.unidade.id" :item.sync="filtros.unidade" table="Unidade" :label="$t('SISTEMA.UNIDADE')"/>
            </div>
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.setor.id" :item.sync="filtros.setor" table="Setor" :label="$t('SISTEMA.SETOR')"/>
            </div>
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.exame.id" :item.sync="filtros.exame" table="Exame" :label="$t('SISTEMA.EXAME')"/>
            </div>

            <div v-if="tipo == 'Dre' || tipo == 'fluxoCaixa'" class="column is-full-mobile">
                <b-field :label="$t('FILTROS.CENTRORESULTADOS')">
                    <b-taginput
                        v-model="filtros.centrosDeResultadoSelecionados"
                        :data="centrosDeResultados"
                        autocomplete
                        :allow-new="false"
                        :before-adding="(item) => { this.centrosDeResultados = this.listaCentroResultados; return item;}"
                        field="nome"
                        icon="label"
                        :placeholder="$t('FILTROS.SELECIONECENTROSRESULTADOS')"
                        @typing="buscarCentroDeResultados"
                        >
                        <template slot-scope="props">
                            <div class="media">   
                                <div class="media-left">
                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                </div>
                                <div class="media-content">
                                    {{ props.option.nome }}
                                </div>                           
                            </div>
                        </template>
                    </b-taginput>
                </b-field>
            </div>

            <div class="column is-6 is-full-mobile" v-if="tipo=='Dre' || tipo == 'fluxoCaixa'">
                <label class="label">{{$t('FILTROS.CONTACORRENTE')}}</label>
                <div class="control" style="width:100%">
                    <span class="select is-empty" style="width:100%">
                        <select :placeholder="$t('FILTROS.SITUACAO')" v-model="filtros.contaCorrente.id" style="width:100%;">
                            <option :value="null">Todos</option>
                            <option v-for="option in listaContaCorrente"
                                :value="option.id"
                                :key="option.id"
                            >
                                {{ option.nome }}
                            </option>
                        </select>
                    </span>
                </div>
            </div>

            <div :class="tipo == 'fluxoCaixa' ? 'column is-full is-full-mobile' : 'column is-6 is-full-mobile'">

                <label v-if="tipo == 'Dre'" class="label">{{$t('FILTROS.PERIODO')}}</label>
                <div class="panel">
                    <p v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="panel-tabs">
                        <a :class="{ 'is-active': filtros.dataFiltro == 'solicitacao' }" @click="filtros.dataFiltro = 'solicitacao'">{{$t('FILTROS.DATASOLICITACAO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'recebimento' }" @click="filtros.dataFiltro = 'recebimento'">{{$t('FILTROS.DATARECEBIMENTO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'assinatura' }" @click="filtros.dataFiltro = 'assinatura'">{{$t('FILTROS.DATAASSINATURA')}}</a>
                    </p>

                    <p v-if="tipo == 'fluxoCaixa'" class="panel-tabs">
                        <a :class="{ 'is-active': filtros.dataFiltro == 'criacao' }" @click="filtros.dataFiltro = 'criacao'">{{$t('FILTROS.DATACRIACAO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'vencimento' }" @click="filtros.dataFiltro = 'vencimento'">{{$t('FILTROS.DATAVENCIMENTO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'pagamento' }" @click="filtros.dataFiltro = 'pagamento'">{{$t('FILTROS.DATAPAGAMENTO')}}</a>
                        <a :class="{ 'is-active': filtros.dataFiltro == 'competencia' }" @click="filtros.dataFiltro = 'competencia'">{{$t('FILTROS.DATACOMPETENCIA')}}</a>
                    </p>

                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="filtros.datas[0]"
                                :dataFinal.sync="filtros.datas[1]"/>
                    </div>

                    <div v-if="tipo == 'fluxoCaixa'" class="panel-block is-justified-between">
                        <b-checkbox
                            v-if="isInRole('fluxodecaixa')"
                            v-model="filtrarPorProcessamento"
                            size="is-small"
                        >
                            {{$t('MENUFINANCEIRO.FILTRARDATAPROCESSAMENTO')}} 
                        </b-checkbox>
                        <b-checkbox
                            v-if="isInRole('receber-relacaoDeDebito')" 
                            v-model="sintetizado"
                            size="is-small"
                        >
                            {{$t('MENUFINANCEIRO.SOMARVALORESPARCELAS')}}
                        </b-checkbox>
                    </div>
                </div>
            </div>
            
            <div v-if="tipo != 'Dre' && tipo != 'fluxoCaixa'" class="column is-4 is-full-mobile">
                <b-switch v-model="filtros.ocultarValorBruto">
                    {{$t('FILTROS.OCULTARVALORBRUTO')}}
                </b-switch>
            </div>

            <div class="column is-6 is-full-mobile" v-if="(tipo=='RepasseMedico')">
                <label class="label">{{$t('FILTROS.SITUACAO')}}</label>
                <div class="control" style="width:100%">
                    <span class="select is-empty" style="width:100%">

                        <select :placeholder="$t('FILTROS.SITUACAO')" v-model="filtros.filtrarPor" style="width:100%;">
                            <option value="todos" checked>{{$t('FILTROS.TODOS')}}</option>
                            <option value="somenteSemRepasse">{{$t('FILTROS.SEMREPASSE')}}</option>
                            <option value="somenteComRepasse">{{$t('FILTROS.COMREPASSE')}}</option>
                        </select>
                    </span>
                </div>
            </div>
        </div>
        <div class="panel-block">
            <b-button v-if="tipo != 'fluxoCaixa'" icon-left="magnify" type="is-primary" :loading="loading" @click="filtros.exportarPdf=false; carregarResultados()">{{$t('FILTROS.BUSCAR')}}</b-button>           
            <b-button v-if="habilitarBotaoPdf" icon-left="eye" class="ml-1" type="is-success"  @click=" filtros.exportarPdf=true; carregarResultados();" >{{$t('FILTROS.PDF')}}</b-button>  

            <b-button v-if="tipo == 'fluxoCaixa'" type="is-primary" :loading="loading" @click="carregarResultados()">{{$t('FILTROS.BTNFLUXOMENSAL')}}</b-button>  
            <form v-if="isInRole('fluxodecaixa') && tipo == 'fluxoCaixa'" action="/Rel/FluxoDeCaixa" method="post">
                    <input type="hidden" name="dataInicial" :value="filtros.datas[0] | moment('DD/MM/YYYY HH:mm')" />
                    <input type="hidden" name="dataFinal" :value="filtros.datas[1] | moment('DD/MM/YYYY HH:mm')" />
                    <input type="hidden" name="filtrarPorProcessamento" v-model="filtrarPorProcessamento" />
                    <button class="button is-primary ml-1" type="submit">
                        <span class="is-hidden-mobile">{{$t('FILTROS.BTNFLUXOCAIXA')}}</span>
                        <span class="is-hidden-tablet">{{$t('FILTROS.BTNFLUXO')}}</span>
                    </button>
            </form>
            <router-link v-if="isInRole('fechamentodecaixa') && tipo == 'fluxoCaixa'" :to="{ name: 'fechamentodecaixa', 
                params: {
                    dataInicial: this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                    dataFinal: this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')                                
                }}" class="button is-primary ml-1">
                <b-button class="button is-primary" type="button">
                    <span class="is-hidden-mobile">{{$t('FILTROS.BTNFECHAMENTOCAIXA')}}</span>
                    <span class="is-hidden-tablet">{{$t('FILTROS.BTNFECHAMENTO')}}</span>
                </b-button>
            </router-link>
            <router-link v-if="isInRole('receber-relacaoDeDebito') && tipo == 'fluxoCaixa'" :to="{ name: 'relacaodedebito', 
                params: {
                    dataInicial: this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                    dataFinal: this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss'), 
                    sintetizado: sintetizado
                }}" class="button is-primary ml-1">
                <b-button class="button is-primary" type="button">
                    <span class="is-hidden-mobile">{{$t('FILTROS.BTNRELACAODEBITOS')}}</span>
                    <span class="is-hidden-tablet">{{$t('FILTROS.BTNDEBITOS')}}</span>                                
                </b-button>
            </router-link>
        </div>
    </div>
</template>

<style scoped>
    hr{
        margin: 0.5rem 0  !important;
    }

    .label-filtros{
        min-height: 36px !important;
        font-size: 15px !important;
    }
</style>

<script>
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            searchIdName,
            periodoHora
        },
        props: {            
            tipo: String,
            habilitarBotaoPdf: {
                type: Boolean,
                default: false
            }            
        },
        data() {
            return {
                filtros: {
                    dataFiltro: 'solicitacao',
                    filtrarPor: 'todos',
                    exportarPdf: false,
                    funcionario: {
                        id: null
                    },
                    tipoConvenio: {
                        id: null
                    },
                    medico: {
                        id: null
                    },
                    unidade: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    setor: {
                        id: null
                    },
                    exame: {
                        id: null
                    },
                    contaCorrente: {
                        id: null
                    },
                    datas: [
                        this.$moment().subtract(7, 'days').toDate(),
                        this.$moment().toDate()
                    ],
                    ocultarValorBruto: false,
                    centrosDeResultadoSelecionados: [],
                },
                sintetizado: false,    
                filtrarPorProcessamento: false,
                loading: false,
                centrosDeResultados: null,
                listaCentroResultados: [],
                listaContaCorrente: [],
            };
        },
        mounted() {
            if(this.tipo == 'Dre' || this.tipo == 'fluxoCaixa') {
                this.loadCentroResultados();
                this.loadContaCorrente()

                this.filtros.dataFiltro = 'criacao'
            }
            
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            carregarResultados(){
                this.$emit('filtrar', this.filtros);
            },
            loadCentroResultados(){
                this.$http.get('/api/search/CentrosResultados')
                .then(res => {
                    this.listaCentroResultados = res.data;
                });
            }, 
            loadContaCorrente(){
                this.$http.get('/api/search/ContasCorrentes')
                .then(res => {
                    this.listaContaCorrente = res.data;
                });
            }, 
            buscarCentroDeResultados(text) {
                this.centrosDeResultados = this.listaCentroResultados;
                this.centrosDeResultados = this.centrosDeResultados.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },           
        }
    }

</script>