<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="columns box">
            <div class="column is-2">
                <div class="buttons">
                    <router-link :to="{ name: 'importar-ofx' }" class="button is-primary">
                        {{$t('CONCILIACAOBANCARIA.IMPORTAROFX')}}
                    </router-link>
                </div>
            </div>
        </div>                
        <b-table :data="model.lista"
                 :loading="isLoading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="changePage"
                 :aria-next-label="$t('CONCILIACAOBANCARIA.PROXPAGINA')"
                 :aria-previous-label="$t('CONCILIACAOBANCARIA.PAGANTERIOR')"
                 :aria-page-label="$t('CONCILIACAOBANCARIA.PAGINA')"
                 :aria-current-label="$t('CONCILIACAOBANCARIA.PAGATUAL')"
                 focusable
            >
            <template>
                <b-table-column  v-slot="props" field="Id" :label="$t('CONCILIACAOBANCARIA.ID')">
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column  v-slot="props" field="Nome" :label="$t('CONCILIACAOBANCARIA.CONTACORRENTE')">
                    {{ props.row.conta }}
                </b-table-column>
                  <b-table-column  v-slot="props" field="PeriodoInicial" :label="$t('CONCILIACAOBANCARIA.PERIODOINICIAL')">
                    {{ props.row.periodoInicial | moment("DD/MM/YYYY") }}
                </b-table-column>
                <b-table-column  v-slot="props" field="PeriodoFInal" :label="$t('CONCILIACAOBANCARIA.PERIODOFINAL')">
                    {{ props.row.periodoFinal | moment("DD/MM/YYYY") }}
                </b-table-column>
                <b-table-column  v-slot="props" field="ImportadoPor " :label="$t('CONCILIACAOBANCARIA.IMPORTADOPOR')">
                    {{ props.row.nome }}
                </b-table-column>
            </template>
        </b-table>
        
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {},
                total: 0,
                isLoading: false,
                page: 1,
                perPage: 20,
                previousBalance: null,
                fileOfx: {
                    name: null
                }
            };
        },
        components: {
            titlebar 
        },
        created() {
            this.isLoading = true;
            this.loadAsyncData()
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    this.$t('CONCILIACAOBANCARIA.CONCILIACAO'),
                    this.$t('CONCILIACAOBANCARIA.IMPORTACOES')
                ]
            },          
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            loadAsyncData() {
                const params = [
                    `page=${this.page}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/ConciliacaoBancaria/listarImportacoesRealizadas?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.isLoading = false
                        this.$router.replace({query: {page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.isLoading = false
                        throw error
                    })
            },
            changePage(page) {
                this.page = page
                this.loadAsyncData()
            },
            uploadFileOfx(event) {
                this.fileOfx = event.target.files[0]
            },
            onSubmit() {
                const formData = new FormData()
                formData.append('arquivo', this.fileOfx)
                formData.append('saldoAnterior', this.previousBalance)
                this.isLoading = true
                this.$http
                .post(`/api/ConciliacaoBancaria/ImportarExtratoOfx`, formData)
                .then(() => {
                    this.loadAsyncData()
                })
                .catch((error) => {
                    this.isLoading = false
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Ocorreu um erro ao listar as importações',
                        type: "is-danger",
                        queue: false,
                    });
                    console.error(error)
                })
            }
        }
    }
</script>