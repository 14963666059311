<template>
    <b-collapse class="card has-background-transparent"
        animation="slide"
        :open="isOpen == index"
        @open="isOpen = index;">
        <div slot="trigger"
            slot-scope="props"
            class="card-header has-background-light"
            role="button">
            <p class="card-header-title">
                {{titulo}}
            </p>
            <a class="card-header-icon">
                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
            </a>
        </div>
        <div class="column">
            <table class="table is-bordered is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th width="5%"></th>
                        <th width="20%">Regra</th>
                        <th width="75%">Descrição</th>
                    </tr>
                </thead>
                <slot class="tbody">
                </slot>
            </table>
        </div>
    </b-collapse>
</template>

<script>
export default {
    props: [
        'index',
        'titulo'
    ],
    data(){
        return {
            isOpen: 0
        }
    }
}
</script>