<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('ESTUDOSSEMVINCULO.TITULOVINCULAR')}}</p>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('ESTUDOSSEMVINCULO.CONFIRMAVINCULAR')"></b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('ESTUDOSSEMVINCULO.CODIGO')">
                        <b-input
                                 expanded
                                 v-model="codigoGuia"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('ESTUDOSSEMVINCULO.EXAME')">
                        <b-input 
                                 expanded
                                 v-model="exameItem"></b-input>
                    </b-field>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success" @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <button class="button" type="button" @click="$emit('close')">{{$t('ESTUDOSSEMVINCULO.CANCELAR')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            id: Number,
        },
        data() {
            return {
                codigoGuia: null,
                exameItem: null,
            }
        },
        computed: {
            ...mapState([
                'config'
            ]),
        },
        methods: {
            confirmar() {
                if (this.codigoGuia == null || this.exameItem == null) {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: "É preciso informar o exame e a guia!",
                        type: 'is-danger',
                        queue: false
                    })
                } else {
                    const params = [];                    
                    
                    params.push('id=' + this.id);
                    params.push('codigoGuia=' + this.codigoGuia);
                    params.push('exameItem=' + this.exameItem);

                    try {
                        this.$http.get(`/api/analitico/vincularEstudos?${params.join('&')}`)
                                    .then(res => {
                                        if (res.data.erro == null || !res.data.erro) {                                  
                                            this.$buefy.toast.open({
                                                duration: 5000,
                                                message: res.data.sucesso,
                                                type: 'is-success',
                                                queue: false
                                            });

                                            this.$router.go();
                                        }else {
                                            this.$buefy.toast.open({
                                                duration: 5000,
                                                message: res.data.erro,
                                                type: 'is-danger',
                                                queue: false
                                            });           
                                        }
                                    })
                                    .finally(() => {                    
                                        this.$emit('close')
                                    })

                    } catch (e) {

                        console.error(e);
                    }

                }
            }
        }
    }
</script>