<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>

        <div v-if="lista.length>0" class="tile is-ancestor centralizar">
            <div class="tile is-12 is-centered is-vertical is-parent">
                <div class="tile is-child box">
                    <p class="title">{{subTitulo}}</p>
                    <!-- LINHA CABEÇALHO -->
                    <div class="b-table" >
                        <div class="table-wrapper has-mobile-cards">
                            <table class="table  is-hoverable">
                                <thead>
                                        <th  class="width-5">                                             </th>                                        
                                        <th  class="width-5"> Guia                                        </th>                                        
                                        <th  class="width-5 centralizar">  Emissão                        </th>
                                        <th  class="width-5 centralizar"> Solicitação                     </th>
                                        <th  class="width-5 centralizar"> Recebimento                     </th>
                                        <th  class="width-5 centralizar"> Assinatura                      </th>
                                        <th  class="width-5 centralizar"> Prazo                           </th>
                                        <th  class="width-5 centralizar"> Recoleta?                       </th>
                                        <th  class="width-5 centralizar"> Remarcação?                     </th>
                                        <th  class="width-5 centralizar"> Exame                           </th>
                                        <th  class="width-5 centralizar"> Preço                           </th>
                                        <th  class="width-5 centralizar"> Amostra                         </th>                                        
                                        <th  class="width-5 centralizar"> Atraso                          </th>
                                        <th  class="width-5 centralizar"> Tat                             </th>
                                        <th  class="width-5 centralizar"> Atrasado?               	      </th>                                  

                                </thead>

                                <tbody>  

                                    <tr v-for="item in lista" :key="item.contador">
                                        <td  class="width-5 centralizar"> {{item.contador}}                </td>                                        
                                        <td  class="width-5 button-effect centralizar" @click="abrirGuia(item.codigoguia)"> {{item.codigoguia}}             </td>                                        
                                        <td  class="width-5 centralizar"> {{item.dataemissaoguia}}        </td>
                                        <td  class="width-5 centralizar"> {{item.datasolicitacaoexame}}   </td>
                                        <td  class="width-5 centralizar"> {{item.datarecebimento}}        </td>
                                        <td  class="width-5 centralizar"> {{item.dataassinatura}}         </td>
                                        <td  class="width-5 centralizar"> {{item.dataentregaexame}}       </td>
                                        <td  class="width-5 centralizar"> {{item.teverecoleta}}           </td>
                                        <td  class="width-5 centralizar"> {{item.teveremarcacao  }}       </td>
                                        <td  class="width-5 centralizar"> {{item.apelidoexame    }}       </td>
                                        <td  class="width-5 centralizar"> {{item.precoexame.toFixed(2).replace('.', ',')}}             </td>
                                        <td  class="width-5 centralizar"> {{item.amostra}}                </td>                                                                                                                                                             
                                        <td  class="width-5 centralizar"> {{item.atraso_tempo.toFixed(2).replace('.', ',')}}           </td>
                                        <td  class="width-5 centralizar"> {{item.tat_tempo.toFixed(2).replace('.', ',')}}              </td>
                                        <td  class="width-5 centralizar"> {{item.atraso_status}}		    </td> 
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>   
                                        
                </div>



            </div>
        </div>  
        

    </section>
</template>


<style scoped >


    .button-effect:hover {
        background-color: #e9efe9 !important;
    }
    .button-effect{
        cursor: pointer !important;
        color: blue;
    }
    

      
</style>

<script>
    import titlebar from '@/components/titlebar.vue'        
    
    export default {
        data() {
            return {
                lista: [], 
                isLoading: true,
                subTitulo: 'Vazio',
                filtro: null, 
            }
        },
        components: {
            titlebar                             
        },
        computed: {
            titleStack() {
                return [
                    'Relatórios',
                    'Acompanhamento de Exames'
                ]
            }
        },
      
        created() {
            
            // se passou algum parametro
            if (this.$route.query) {
                                              
                const params = [
                    `dataInicial=${this.$route.query.dataInicial}`,
                    `dataFinal=${this.$route.query.dataFinal}`,                                        
                    `tipo=${this.$route.query.tipo}`                    
                ]      
                

                if(this.$route.query.convenioIdLista != null ){
                    params.push(`convenioIdLista=${this.$route.query.convenioIdLista.join(';')}`);
                }
                
                if(this.$route.query.departamentoIdLista != null ){
                    params.push(`departamentoIdLista=${this.$route.query.departamentoIdLista.join(';')}`);
                }
                this.$http.get('/api/relatorio/AcompanhamentoExamesLista?'+ params.join('&'))
                    .then(res => res.data)
                    .then(data => {
                
                        this.lista = data.lista;
                        this.subTitulo = data.subTitulo;                                 
                        this.isLoading = false;

                        let contador  = 1;

                        this.lista.forEach(x => {
                            x.contador = contador++; 
                            x.dataemissaoguia = (x.dataemissaoguia) ? this.$moment(x.dataemissaoguia).format('DD/MM/YYYY HH:mm') : null;
                            x.datasolicitacaoexame = (x.datasolicitacaoexame) ? this.$moment(x.datasolicitacaoexame).format('DD/MM/YYYY HH:mm') : null;
                            x.datarecebimento = (x.datarecebimento) ? this.$moment(x.datarecebimento).format('DD/MM/YYYY HH:mm') : null;
                            x.dataassinatura = (x.dataassinatura) ? this.$moment(x.dataassinatura).format('DD/MM/YYYY HH:mm') : null;
                            x.dataentregaexame = (x.dataentregaexame) ? this.$moment(x.dataentregaexame).format('DD/MM/YYYY HH:mm') : null;
                            x.precoexame = (isNaN(x.precoexame) || x.precoexame == null ) ? 0.0 : x.precoexame;
                        });

                        if(document.getElementsByClassName('delete').length>0){
                            document.getElementsByClassName('delete')[0].click();
                        }                               
                        
                    })
                    .catch((error) => {
                        this.lista = [];
                        this.isLoading = false;
                        throw error
                    })					

           
             

            }                
                                            
        },      
        methods: {


            abrirGuia(guiaId) {
                                      
                const routeData =  this.$router.resolve({ path: `/atendimento/guia/${guiaId}`  });
                window.open(routeData.href, '_blank');                
                                                
            },  

        }       
    }
</script>