
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Sobre from '../views/sobre.vue'

import atendimento from '../views/dashboard/atendimento.vue'
import analise from '../views/dashboard/analise.vue'
import equipamentos from '../views/dashboard/equipamentos.vue'
import manutencao from '../views/dashboard/manutencao.vue'
import estoque from '../views/dashboard/estoque.vue'
import financeiro from '../views/dashboard/financeiro.vue'

import tabelapreco from '../views/cadastro/tabelapreco.vue'
import novatabelapreco from '../views/cadastro/novatabelapreco.vue'
import novatabelaprecoexcel from '../views/cadastro/novatabelaprecoexcel.vue'

import pacienteVue from '../views/cadastro/paciente.vue'
import estoqueVue from '../views/cadastro/estoque.vue'
import categoriaVue from '../views/cadastro/categoria.vue'
import marcaVue from '../views/cadastro/marca.vue'
import unidadedemedidaVue from '../views/cadastro/unidadedemedida.vue'
import consumivelVue from '../views/cadastro/consumivel.vue'
import loteVue from '../views/cadastro/lote.vue'
import medicoVue from '../views/cadastro/medico.vue'
import niveldeacesso from '../views/cadastro/niveldeacesso.vue'
import abreviaturaVue from '../views/cadastro/abreviatura.vue'
import conservante from '../views/cadastro/conservante.vue'
import tipodelocal from '../views/cadastro/tipodelocal.vue'
import localVue from '../views/cadastro/local.vue'
import liberacaoAutomaticaVue from '../views/cadastro/liberacaoautomatica.vue'
import liberacaoAutomaticaConfigVue from '../views/cadastro/liberacaoautomaticaconfig.vue'
import reducaoAcrescimoVue from '../views/cadastro/reducaoacrescimo.vue'
import repasseMedicoVue from '../views/cadastro/repassemedico.vue'
import convenioVue from '../views/cadastro/convenio.vue'
import departamentoVue from '../views/cadastro/departamento.vue'
import pesquisa from '../views/cadastro/pesquisa.vue'
import especie from '../views/cadastro/especie.vue'
import examecodigotipo from '../views/cadastro/tipodecodigoexame.vue'
import setor from '../views/cadastro/setor.vue'
import unidade from '../views/cadastro/unidade.vue'
import material from '../views/cadastro/material.vue'
import sorotecageladeira from '../views/cadastro/sorotecageladeira.vue'
import rateio from '../views/cadastro/rateio.vue'
import condicaopagamento from '../views/cadastro/condicaopagamento.vue'
import imposto from '../views/cadastro/imposto.vue'
import tipodeconvenio from '../views/cadastro/tipodeconvenio.vue'
import sorotecacontainer from '../views/cadastro/sorotecacontainer.vue'
import feriado from '../views/cadastro/feriado.vue'
import portalcoletador from '../views/cadastro/coletador.vue'
import raca from '../views/cadastro/raca.vue'
import especiepagamento from '../views/cadastro/especiepagamento.vue'
import prontuario from '../views/cadastro/paciente-pronturario.vue'

import pacientesVue from '../views/lista/pacientes.vue'
import estoquesVue from '../views/lista/estoques.vue'
import categoriasVue from '../views/lista/categorias.vue'
import marcasVue from '../views/lista/marcas.vue'
import unidadesdemedidaVue from '../views/lista/unidadesdemedida.vue'
import consumiveisVue from '../views/lista/consumiveis.vue'
import lotesVue from '../views/lista/lotes.vue'
import medicosVue from '../views/lista/medicos.vue'
import tabelasPrecosVue from '../views/lista/tabelasprecos.vue'
import niveisDeAcessoVue from '../views/lista/niveisdeacesso.vue'
import abreviaturasVue from '../views/lista/abreviaturas.vue'
import conservantesVue from '../views/lista/conservantes.vue'
import locaisVue from '../views/lista/locais.vue'
import listaLiberacaoAutomaticaVue from '../views/lista/liberacaoautomatica.vue'
import reducoesAcrescimosVue from '../views/lista/reducoesacrescimos.vue'
import repassesMedicosVue from '../views/lista/repassesmedicos.vue'
import conveniosVue from '../views/lista/convenios.vue'
import departamentosVue from '../views/lista/departamentos.vue'
import pesquisasVue from '../views/lista/pesquisas.vue'
import especieVue from '../views/lista/especies.vue'
import examescodigostiposVue from '../views/lista/tiposdecodigosexame.vue'
import setoresVue from '../views/lista/setores.vue'
import unidadesVue from '../views/lista/unidades.vue'
import materiaisVue from '../views/lista/materiais.vue'
import sorotecageladeirasVue from '../views/lista/sorotecageladeiras.vue'
import rateiosVue from '../views/lista/rateios.vue'
import tiposdelocaisVue from '../views/lista/tiposdelocais.vue'
import condicoespagamentosVue from '../views/lista/condicoespagamentos.vue'
import tiposdeconveniosVue from '../views/lista/tiposdeconvenios.vue'
import sorotecascontainersVue from '../views/lista/sorotecascontainers.vue'
import feriadosVue from '../views/lista/feriados.vue'
import portalColetadoresVue from '../views/lista/coletadores.vue'
import racasVue from '../views/lista/racas.vue'
import especiesPagamentosVue from '../views/lista/especiespagamentos.vue'

import recebimentoAmostras from '../views/atendimento/recebimentodeamostras.vue'
import orcamentos from '../views/atendimento/lista/orcamentos.vue'
import orcamento from '../views/atendimento/cadastro/orcamento.vue'

import interfaceConfigVue from '../views/interface/config.vue'
import interfaceConfirmVue from '../views/interface/confirm.vue'
import interfaceQualityControlVue from '../views/interface/qualityControl.vue'
import interfaceSampleLinkVue from '../views/interface/samplelink.vue'
import interfaceMaintenanceListVue from '../views/interface/interfacemaintenancelist.vue'
import interfaceMaintenanceVue from '../views/interface/interfacemaintenance.vue'

import exameVue from '../views/cadastro/exame.vue'
import examesVue from '../views/lista/exames.vue'
import exameValidacaoVue from '../views/cadastro/examevalidacao.vue'
import examesValidacaoVue from '../views/lista/examesvalidacao.vue'

import fornecedorVue from '../views/cadastro/fornecedor.vue'
import fornecedoresVue from '../views/lista/fornecedores.vue'
import valorCbhpmVue from '../views/cadastro/valorCbhpm.vue'
import valoresCbhpmVue from '../views/lista/valoresCbhpm.vue'
import motivoVue from '../views/cadastro/motivo.vue'
import motivosVue from '../views/lista/motivos.vue'
import grupoExameVue from '../views/cadastro/grupoexame.vue'
import grupoExamesVue from '../views/lista/gruposexames.vue'
import configuracaoTissVue from '../views/cadastro/configuracaotiss.vue'
import configuracoesTissVue from '../views/lista/configuracoestiss.vue'
import funcionarioVue from '../views/cadastro/funcionario.vue'
import funcionariosVue from '../views/lista/funcionarios.vue'
import agendamentotarefa from '../views/cadastro/agendamentotarefa.vue'

import receberParcelaLoteVue from '../views/financeiro/cadastro/receber-parcela-lote.vue'
import importacoesOfx from '../views/financeiro/lista/importacoes-ofx.vue'
import importarOfx from '../views/financeiro/cadastro/importacao-ofx.vue'
import conciliacaoDiaria from '../views/financeiro/cadastro/conciliacao-diaria.vue'
import extratoConciliacao from '../views/financeiro/lista/extrato-conciliacao.vue'
import bandeira from '../views/financeiro/cadastro/bandeira.vue'
import bandeiras from '../views/financeiro/lista/bandeiras.vue'
import listaReceberParcelaLoteVue from '../views/financeiro/lista/receber-parcela-lote.vue'
import fechamentodecaixa from '../views/financeiro/fechamentodecaixa.vue'
import relacaodedebito from '../views/financeiro/relacaodedebitos.vue'
import listarpagar from '../views/financeiro/listarpagar.vue'
import contaspagar from '../views/financeiro/contaspagar.vue'
import baixapagamentos from '../views/financeiro/baixapagamentos.vue'
import estatisticas from '../views/financeiro/estatisticas.vue'
import categoriaFinanceira from '../views/financeiro/categoriafinanceira.vue';
import centroResultados from '../views/financeiro/centroresultados.vue';
import pagarParcelaLoteVue from '../views/financeiro/cadastro/pagar-parcela-lote.vue'
import listaPagarParcelaLoteVue from '../views/financeiro/lista/pagar-parcela-lote.vue'
import listaContasReceber from '../views/financeiro/lista/contas-receber.vue'
import contasReceber from '../views/financeiro/cadastro/contas-receber.vue'
import xmltiss from '../views/financeiro/xmltiss.vue'
import listaContaCorrente from '../views/financeiro/lista/conta-corrente.vue'
import contaCorrente from '../views/financeiro/cadastro/conta-corrente.vue'
import faturar from '../views/financeiro/faturar.vue'
import repassesMedicos from '../views/financeiro/repasses-medicos.vue'
import listaDRE from '../views/financeiro/lista/dre-lista.vue'
import dre from '../views/financeiro/cadastro/dre.vue'
import emitirDre from '../views/financeiro/relatorio-dre.vue'
import fluxoCaixa from '../views/financeiro/fluxo-caixa.vue'

import agendaVue from '../views/atendimento/agenda.vue'
import inspecaoVue from '../views/atendimento/inspecao.vue'
import agendamentoVue from '../views/atendimento/agendamento.vue'
import agendaRelacaoVue from '../views/atendimento/agenda-relacao.vue'
import salaEsperaVue from '../views/atendimento/sala-espera.vue'
import amostralote from '../views/atendimento/loteamostras.vue'
import amostralotelistar from '../views/atendimento/listarloteamostras.vue'
import amostraloteenviar from '../views/atendimento/incluirloteamostras.vue'
import amostralotecheckpoint from '../views/atendimento/checkpointloteamostras.vue'
import entregaresultados from '../views/atendimento/entregaresultados.vue'
import procuradeexames from '../views/atendimento/procuradeexames.vue'
import guiaVue from '../views/atendimento/guia.vue'
import guiaList from '../views/atendimento/guia-list.vue'
import sorotecaLocalizarVue from '../views/analise/soroteca-localizar.vue'
import sorotecaArmazenarVue from '../views/analise/soroteca-armazenar.vue'
import examesBloqueadosVue from '../views/analise/exames-bloqueados.vue'
import examesEntregasVue from '../views/analise/exames-entregas.vue'

import amostraIncidencia from '../views/analise/amostraIncidencia.vue'
import emissaoBancada from '../views/analise/bancada-emissao.vue'
import progressoSetor from '../views/analise/progresso-setor.vue'
import progressobancada from '../views/analise/progresso-bancada.vue'
import abrirLote from '../views/analise/abrirlote.vue'
import loteResultados from '../views/analise/loteresultados.vue'

import coletaExamesVue from '../views/atendimento/coleta-exames.vue'
import examesMesmoPaciente from '../views/atendimento/examesmesmopaciente.vue'
import pesquisaclinica from '../views/atendimento/pesquisaclinica.vue'
import workflow from '../views/atendimento/workflow.vue'
import worklist from '../views/atendimento/worklist.vue'

import estoqueRastreio from '../views/relatorios/estoquerastreio.vue'
import movimentacaoConveniosUnidades from '../views/relatorios/movimentacaoconveniosunidades.vue'
import acompanhamentoexames from '../views/relatorios/acompanhamentoexames.vue'
import acompanhamentoexameslista from '../views/relatorios/acompanhamentoexameslista.vue'

import painelDeAtendimento from '../views/painel-de-atendimento.vue'
import totenDeAtendimento from '../views/toten-de-atendimento.vue'

import portalPaciente from '../views/portal/paciente/paciente.vue'
import portalMedico from '../views/portal/medico/medico.vue'
import pesquisasSatisfacao from '../views/portal/pesquisassatisfacao.vue'
import portalLocal from '../views/portal/local/local.vue'
import medicoPortal from '../views/portal/convenio/medicoPortal.vue'
import portalcatalogoexames from '../views/portal/convenio/catalogoexames.vue'
import portalvisualizarexame from '../views/portal/convenio/visualizarexame.vue'
import portalConvenio from '../views/portal/convenio/convenio.vue'
import portalLote from '../views/portal/convenio/lotes.vue'
import portalOrcamento from '../views/portal/convenio/orcamento.vue'
import portalListarPaciente from '../views/portal/convenio/listar-pacientes.vue'
import portalConvenioPaciente from '../views/portal/convenio/paciente.vue'
import portalConvenioGuia from '../views/portal/convenio/guia.vue'
import portalConvenioPesquisaClinica from '../views/portal/convenio/pesquisaclinica.vue'
import portalConvenioWorkflow from '../views/portal/convenio/workflow.vue'
import portalrelExames from '../views/portal/relatorios/relacaoexames.vue'
import portalrelResultados from '../views/portal/relatorios/resultados.vue'
import portalrelIntegracoes from '../views/portal/relatorios/integracoes.vue'
import portalrelAmostras from '../views/portal/relatorios/amostras.vue'
import portalGerenciar from '../views/portal/gerenciar.vue'
import portalAvisos from '../views/portal/convenio/avisos.vue'
import portalRecoletas from '../views/portal/convenio/recoletas.vue'
import portalExamesAtrasados from '../views/portal/convenio/exames-atrasados.vue'
import portalValorCritico from '../views/portal/convenio/valor-critico.vue'
import portalIntegracao from '../views/portal/convenio/integracao.vue'
import portalListarPedidos from '../views/portal/convenio/listar-pedidos.vue'
import portalPedidos from '../views/portal/convenio/pedido.vue'
import tiposdeAgendamento from '../views/lista/tiposdeagendamento.vue'
import tipoAgendamento from '../views/cadastro/tipoagendamento.vue'
import regrasdeAgendamento from '../views/lista/regrasdeagendamento.vue'
import regraAgendamento from '../views/cadastro/regraagendamento.vue'
import estudos from '../views/analise/estudos.vue'
import estudolaudo from '../views/analise/estudo-laudo.vue'
import modelolaudodescritivo from '../views/cadastro/modelolaudodescritivo.vue'
import portalrelFaturas from '../views/portal/relatorios/faturas.vue'
import portalCadastros from '../views/dashboard/portalcadastros.vue'
import modeloslaudodescritivo from '../views/lista/modeloslaudodescritivo.vue'
import amostraStatus from '../views/cadastro/amostrastatus.vue'
import amostrasStatus from '../views/lista/amostrasstatus.vue'
import examesExtracao from '../views/lista/examesextracao.vue'
import exameExtracao from '../views/cadastro/exameextracao.vue'
import autorizacaoTiss from '../views/relatorios/autorizacao-tiss.vue'




Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Sobre',
		component: Sobre,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'atendimento',
		path: '/atendimento',
		component: atendimento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'paineldeatendimento',
		path: '/painel/:id?',
		component: painelDeAtendimento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'totendeatendimento',
		path: '/toten/:id?',
		component: totenDeAtendimento,
		meta: {
			roles: ["Funcionario"]
		}
    },

	{
		name: 'portalpaciente',
		path: '/portal/paciente',
		component: portalPaciente,
		meta: {
			roles: ["Paciente"]
		}
    },
	{
		name: 'portalmedico',
		path: '/portal/medico',
		component: portalMedico,
		meta: {
			roles: ["Medico"]
		}
    },
	{
		name: 'portalcatalogoexames',
		path: '/portal/catalogoexames',
		component: portalcatalogoexames,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalvisualizarexame',
		path: '/portal/catalogoexames/:id',
		component: portalvisualizarexame,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'pesquisassatisfacao',
		path: '/portal/pesquisassatisfacao/:id',
		component: pesquisasSatisfacao,
		meta: {
			roles: ["Paciente", "Medico", "Convenio", "Local"]
		}
    },
	{
		name: 'portallocal',
		path: '/portal/local',
		component: portalLocal,
		meta: {
			roles: ["Local"]
		}
    },
	{
		name: 'medicoportal',
		path: '/portal/medicoPortal/:id?/:return?',
		component: medicoPortal,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconvenio',
		path: '/portal/convenio',
		component: portalConvenio,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portallote',
		path: '/portal/enviarlote',
		component: portalLote,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalavisos',
		path: '/portal/avisos',
		component: portalAvisos,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalrecoletas',
		path: '/portal/recoletas',
		component: portalRecoletas,
		meta: {
			roles: ["Convenio"]
		}
    },


	{
		name: 'portalexamesatrasados',
		path: '/portal/examesatrasados',
		component: portalExamesAtrasados,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalintegracao',
		path: '/portal/integracao',
		component: portalIntegracao,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalestoque',
		path: '/portal/pedidos',
		component: portalListarPedidos,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalpedido',
		path: '/portal/pedido/:pedidoId?',
		component: portalPedidos,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalvalorcritico',
		path: '/portal/valorcritico',
		component: portalValorCritico,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalorcamento',
		path: '/portal/orcamento',
		component: portalOrcamento,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portallistarpaciente',
		path: '/portal/pacientes-convenio',
		component: portalListarPaciente,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconveniopaciente',
		path: '/portal/pacientes-convenio/:id',
		component: portalConvenioPaciente,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconveniopacienteguia',
		path: '/portal/convenio/paciente/guia/:pacienteId',
		component: portalConvenioGuia,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconvenioguia',
		path: '/portal/convenio/guia/:id',
		component: portalConvenioGuia,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconveniopesquisaclinica',
		path: '/portal/convenio/pesquisaclinica/:id',
		component: portalConvenioPesquisaClinica,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalconvenioworkflow',
		path: '/portal/convenio/workflow/:id',
		component: portalConvenioWorkflow,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalrelacaoexames',
		path: '/portal/relacaoexames',
		component: portalrelExames,
		meta: {
			roles: ["Convenio"]
		}
  },
	{
		name: 'portalresultados',
		path: '/portal/resultados',
		component: portalrelResultados,
		meta: {
			roles: ["Convenio"]
		}
  },
	{
		name: 'portalfaturas',
		path: '/portal/faturas',
		component: portalrelFaturas,
		meta: {
			roles: ["Convenio"]
		}
  },
  {
	name: 'portalcadastros',
	path: '/portal/cadastros',
	component: portalCadastros,
	meta: {
		roles: ["Convenio"]
	}
},  
	{
		name: 'portalintegracoes',
		path: '/portal/integracoes',
		component: portalrelIntegracoes,
		meta: {
			roles: ["Convenio"]
		}
    },

	{
		name: 'portalamostras',
		path: '/portal/amostras',
		component: portalrelAmostras,
		meta: {
			roles: ["Convenio"]
		}
    },
	{
		name: 'portalgerenciar',
		path: '/portal/gerenciar',
		component: portalGerenciar,
		meta: {
			roles: ["Paciente", "Medico", "Convenio", "Local"]
		}
    },

	{
		name: 'recebimentoamostras',
		//parametros criados para a integração MV da unimed bauru
		path: '/atendimento/recebimentoamostras/:tipo?/:referencia?/:funcionario?',
		component: recebimentoAmostras,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'orcamentos',
		path: '/atendimento/listarorcamentos',
		component: orcamentos,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'orcamento',
		path: '/atendimento/orcamento/:id?',
		component: orcamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'analise',
		path: '/analise',
		component: analise,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'equipamentos',
		path: '/interface',
		component: equipamentos,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'manutencao',
		path: '/manutencao',
		component: manutencao,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'estoque',
		path: '/estoque',
		component: estoque,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'financeiro',
		path: '/financeiro',
		component: financeiro,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'autorizacao-tiss',
		path: '/financeiro/autorizacao-tiss',
		component: autorizacaoTiss,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'abreviaturas',
		path: '/manutencao/abreviaturas',
		component: abreviaturasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'abreviatura',
		path: '/manutencao/abreviatura/:id?',
		component: abreviaturaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'conservantes',
		path: '/manutencao/conservantes',
		component: conservantesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'conservante',
		path: '/manutencao/conservante/:id?',
		component: conservante,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'motivos',
		path: '/manutencao/motivos',
		component: motivosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'motivo',
		path: '/manutencao/motivo/:id?',
		component: motivoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'grupoexame',
		path: '/manutencao/examegrupo/:id?',
		component: grupoExameVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'grupoexames',
		path: '/manutencao/examegrupos',
		component: grupoExamesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'locais',
		path: '/manutencao/locais',
		component: locaisVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'local',
		path: '/manutencao/local/:id?',
		component: localVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'departamentos',
		path: '/manutencao/departamentos',
		component: departamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'departamento',
		path: '/manutencao/departamento/:id?',
		component: departamentoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'pacientes',
		path: '/manutencao/pacientes/:procurar?/:criarGuia?',
		component: pacientesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'estoques',
		path: '/estoque/listarestoques/:procurar?',
		component: estoquesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'categorias',
		path: '/estoque/listarcategorias/:procurar?',
		component: categoriasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'unidadesdemedida',
		path: '/estoque/listarunidadesdemedida/:procurar?',
		component: unidadesdemedidaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'consumiveis',
		path: '/estoque/listarconsumiveis/:procurar?',
		component: consumiveisVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'marcas',
		path: '/estoque/listarmarcas/:procurar?',
		component: marcasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'lotes',
		path: '/estoque/listarlotes/:procurar?',
		component: lotesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'configuracaotiss',
		path: '/manutencao/configuracaotiss/:id?',
		component: configuracaoTissVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'configuracoestiss',
		path: '/manutencao/configuracoestiss',
		component: configuracoesTissVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'paciente',
		path: '/manutencao/paciente/:id?/:criarGuia?',
		component: pacienteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'prontuario',
		path: '/manutencao/prontuario/:id',
		component: prontuario,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastroestoque',
		path: '/cadastro/estoque/:id?',
		component: estoqueVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastrocategoria',
		path: '/cadastro/categoria/:id?',
		component: categoriaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastrounidadedemedida',
		path: '/cadastro/unidadedemedida/:id?',
		component: unidadedemedidaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastroconsumivel',
		path: '/cadastro/consumivel/:id?',
		component: consumivelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastrolote',
		path: '/cadastro/lote/:id?',
		component: loteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cadastromarca',
		path: '/cadastro/marca/:id?',
		component: marcaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'liberacoesautomatica',
		path: '/manutencao/liberacoesautomatica',
		component: listaLiberacaoAutomaticaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'convenios',
		path: '/manutencao/convenios',
		component: conveniosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'convenio',
		path: '/manutencao/convenio/:id?/:agendamentoDashboard?/:convenioIdAgendar?',
		component: convenioVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'niveldeacesso',
		path: '/manutencao/niveldeacesso/:id?',
		component: niveldeacesso,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'examecodigotipo',
		path: '/manutencao/examecodigotipo/:id?',
		component: examecodigotipo,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'setor',
		path: '/manutencao/setor/:id?',
		component: setor,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'pesquisa',
		path: '/manutencao/pesquisa/:id?',
		component: pesquisa,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'especie',
		path: '/manutencao/especie/:id?',
		component: especie,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'unidade',
		path: '/manutencao/unidade/:id?',
		component: unidade,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'material',
		path: '/manutencao/material/:id?',
		component: material,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'sorotecageladeira',
		path: '/manutencao/sorotecageladeira/:id?',
		component: sorotecageladeira,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'rateio',
		path: '/financeiro/rateio/:id?',
		component: rateio,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'tipodelocal',
		path: '/manutencao/tipodelocal/:id?',
		component: tipodelocal,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'condicaopagamento',
		path: '/manutencao/condicaopagamento/:id?',
		component: condicaopagamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'imposto',
		path: '/manutencao/imposto/:id?',
		component: imposto,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tipodeconvenio',
		path: '/manutencao/tipoconvenio/:id?',
		component: tipodeconvenio,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'sorotecacontainer',
		path: '/manutencao/sorotecacontainer/:id?',
		component: sorotecacontainer,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'feriado',
		path: '/manutencao/feriado/:id?',
		component: feriado,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'portalcoletador',
		path: '/portal/coletador/:id?',
		component: portalcoletador,
		meta: {
			roles: ["Convenio"]
		}
	},	
	{
		name: 'raca',
		path: '/manutencao/raca/:id?',
		component: raca,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tabelasprecos',
		path: '/manutencao/tabelaprecos',
		component: tabelasPrecosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tabelapreco',
		path: '/manutencao/tabelaprecos/:id?',
		component: tabelapreco,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'novatabelapreco',
		path: '/manutencao/novatabelapreco/',
		component: novatabelapreco,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'novatabelaprecoexcel',
		path: '/manutencao/novatabelaprecoexcel/',
		component: novatabelaprecoexcel,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'niveisdeacesso',
		path: '/manutencao/niveisdeacesso',
		component: niveisDeAcessoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'examescodigostipos',
		path: '/manutencao/examecodigotipos',
		component: examescodigostiposVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'setores',
		path: '/manutencao/setores',
		component: setoresVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'pesquisas',
		path: '/manutencao/pesquisas',
		component: pesquisasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'especies',
		path: '/manutencao/especies',
		component: especieVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'unidades',
		path: '/manutencao/unidades',
		component: unidadesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'materiais',
		path: '/manutencao/materiais',
		component: materiaisVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'sorotecageladeiras',
		path: '/manutencao/sorotecasgeladeiras',
		component: sorotecageladeirasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'rateios',
		path: '/financeiro/rateios',
		component: rateiosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'tiposdelocais',
		path: '/manutencao/tiposdelocais',
		component: tiposdelocaisVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'condicoespagamentos',
		path: '/manutencao/condicoespagamentos',
		component: condicoespagamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tiposdeconvenios',
		path: '/manutencao/tipoconvenios',
		component: tiposdeconveniosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'sorotecascontainers',
		path: '/manutencao/sorotecascontainers',
		component: sorotecascontainersVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'feriados',
		path: '/manutencao/feriados',
		component: feriadosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'portalcoletadores',
		path: '/portal/coletadores',
		component: portalColetadoresVue,
		meta: {
			roles: ["Convenio"]
		}
	},	
	{
		name: 'racas',
		path: '/manutencao/racas',
		component: racasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'agendamentotarefa',
		path: '/agendamentos',
		component: agendamentotarefa,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfaceconfig',
		path: '/interface/config/:id',
		component: interfaceConfigVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfaceconfirm',
		path: '/interface/confirm/:id',
		component: interfaceConfirmVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfacequalitycontrol',
		path: '/interface/qualitycontrol/:id',
		component: interfaceQualityControlVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfacesamplelink',
		path: '/interface/samplelink/:id',
		component: interfaceSampleLinkVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfacemaintenance',
		path: '/interface/maintenance/:equipmentid/:id?',
		component: interfaceMaintenanceVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'interfacemaintenancelist',
		path: '/interface/maintenances/:equipmentid',
		component: interfaceMaintenanceListVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'exames',
		path: '/manutencao/exames',
		component: examesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'examesvalidacao',
		path: '/manutencao/examesvalidacao',
		component: examesValidacaoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'exame',
		path: '/manutencao/exame/:id?',
		component: exameVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'examevalidacao',
		path: '/manutencao/examevalidacao/:id?',
		component: exameValidacaoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'medicos',
		path: '/manutencao/medicos',
		component: medicosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'medico',
		path: '/manutencao/medicos/:id?/:return?',
		component: medicoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'fornecedores',
		path: '/manutencao/fornecedores',
		component: fornecedoresVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'fornecedor',
		path: '/manutencao/fornecedor/:id?',
		component: fornecedorVue	,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'valorescbhpm',
		path: '/manutencao/valoresCbhpm',
		component: valoresCbhpmVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'valorcbhpm',
		path: '/manutencao/valorcbhpm/:id?',
		component: valorCbhpmVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'listaliberacoesautomatica',
		path: '/manutencao/liberacoesautomatica',
		component: listaLiberacaoAutomaticaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'liberacaoautomaticaconfig',
		path: '/manutencao/liberacaoautomaticaconfig',
		component: liberacaoAutomaticaConfigVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'liberacaoautomatica',
		path: '/manutencao/liberacaoautomatica/:id?',
		component: liberacaoAutomaticaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'reducaoacrescimo',
		path: '/manutencao/reducaoacrescimo/:id?',
		component: reducaoAcrescimoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'reducoesacrescimos',
		path: '/manutencao/reducoesacrescimos',
		component: reducoesAcrescimosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'repassemedico',
		path: '/manutencao/repassemedico/:id?',
		component: repasseMedicoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'repassesmedicos',
		path: '/manutencao/repassesmedicos',
		component: repassesMedicosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'especiepagamento',
		path: '/manutencao/especiepagamento/:id?',
		component: especiepagamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'especiespagamentos',
		path: '/manutencao/especiespagamentos',
		component: especiesPagamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	/*{
		name: 'mascara',
		path: '/manutencao/mascara/:id?',
		component: mascaraVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	*/
	{
		name: 'funcionarios',
		path: '/manutencao/funcionarios',
		component: funcionariosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'funcionario',
		path: '/manutencao/funcionario/:id?',
		component: funcionarioVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'receber-parcela-lote',
		path: '/financeiro/receber-parcela-lote/:id?',
		component: receberParcelaLoteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'lista-parcela-lote',
		path: '/financeiro/lista-parcela-lote',
		component: listaReceberParcelaLoteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'bandeira',
		path: '/financeiro/bandeira/:id?',
		component: bandeira,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'bandeiras',
		path: '/financeiro/bandeiras',
		component: bandeiras,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'importar-ofx',
		path: '/financeiro/importar-ofx',
		component: importarOfx,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'importacoes-ofx',
		path: '/financeiro/lista-importacoes-ofx',
		component: importacoesOfx,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'extrato-conciliacao',
		path: '/financeiro/extrato-conciliacao/:contaCorrenteId?',
		component: extratoConciliacao,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'conciliacao-diaria',
		path: '/financeiro/conciliacao-diaria/:dataTransacao/contaCorrente/:contaCorrenteId',
		component: conciliacaoDiaria,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'fechamentodecaixa',
		path: '/financeiro/fechamentodecaixa',
		component: fechamentodecaixa,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'relacaodedebito',
		path: '/financeiro/relacaodedebitos',
		component: relacaodedebito,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'lista-contas-receber',
		path: '/financeiro/receber',
		component: listaContasReceber,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'contas-receber',
		path: '/financeiro/receber/:id?',
		component: contasReceber,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'contas-receber-particular',
		path: '/financeiro/receberParticular/:guiaId?/:condicaoPagamento?',
		component: contasReceber,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'xmltiss',
		path: '/financeiro/xmltiss/:id?/:versao?',
		component: xmltiss,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'listarpagar',
		path: '/financeiro/listarpagar',
		component: listarpagar,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'contaspagar',
		path: '/financeiro/pagar/:id?/:pedidoid?/:notafiscal?',
		component: contaspagar,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'lista-conta-corrente',
		path: '/financeiro/listaContaCorrente',
		component: listaContaCorrente,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'conta-corrente',
		path: '/financeiro/contaCorrente/:id',
		component: contaCorrente,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'receber-faturar',
		path: '/financeiro/faturar',
		component: faturar,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'repasses-medicos',
		path: '/financeiro/repassesMedicos',
		component: repassesMedicos,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'dre-lista',
		path: '/financeiro/listaDre',
		component: listaDRE,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'emitir-dre',
		path: '/financeiro/relatorioDre',
		component: emitirDre,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'dre',
		path: '/financeiro/dre/:id?',
		component: dre,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'fluxo-caixa',
		path: '/financeiro/fluxoCaixa',
		component: fluxoCaixa,
		meta: {
			roles: ["Funcionario"]
		}
	},

	
	{
		name: 'baixapagamentos',
		path: '/financeiro/baixapagamentos',
		component: baixapagamentos,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'estatisticas',
		path: '/financeiro/estatisticas',
		component: estatisticas,
		meta: {
			roles: ["Funcionario"]
		}
	}
	,{
		name: 'categoria-financeira',
		path: '/financeiro/categoria-financeira',
		component: categoriaFinanceira,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'centro-resultados',
		path: '/financeiro/centro-resultados',
		component: centroResultados,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'pagar-parcela-lote',
		path: '/financeiro/pagar-parcela-lote/:id?',
		component: pagarParcelaLoteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'lista-parcela-lote-pagar',
		path: '/financeiro/lista-parcela-lote-pagar',
		component: listaPagarParcelaLoteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'coleta',
		path: '/atendimento/coleta',
		component: coletaExamesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'agenda',
		path: '/atendimento/agenda',
		component: agendaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'inspecao',
		path: '/atendimento/inspecao',
		component: inspecaoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'agendamento',
		path: '/atendimento/agendamento/:id?',
		component: agendamentoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'agenda-relacao',
		path: '/atendimento/agenda-relacao',
		component: agendaRelacaoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'sala-espera',
		path: '/atendimento/sala-espera',
		component: salaEsperaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'amostralote',
		path: '/atendimento/amostralote/:id',
		component: amostralote,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostralotelistar',
		path: '/atendimento/amostralotelistar',
		component: amostralotelistar,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostraloteenviar',
		path: '/atendimento/amostraloteenviar',
		component: amostraloteenviar,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostralotecheckpoint',
		path: '/atendimento/amostralotecheckpoint',
		component: amostralotecheckpoint,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'entregaresultados',
		path: '/atendimento/entregaresultados',
		component: entregaresultados,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'procuradeexames',
		path: '/atendimento/procuradeexames',
		component: procuradeexames,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'guia',
		path: '/atendimento/guia/:id?/:agendamentoDashboard?',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostra',
		path: '/atendimento/amostra/:amostra?',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostrareferencia',
		path: '/atendimento/amostrareferencia/:amostraReferencia?',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'referencia',
		path: '/atendimento/referencia/:referencia?',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'clonarguia',
		path: '/atendimento/clonar/:clonarGuiaId',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'novaguia',
		path: '/atendimento/paciente/:pacienteId/:agendamento?/:agendamentoDashboard?/:examesId',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'guia-list',
		path: '/atendimento/List/:agendamentoDashboard?/:convenioIdAgendar?',
		component: guiaList,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'soroteca-localizar',
		path: '/analise/soroteca-localizar',
		component: sorotecaLocalizarVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'soroteca-armazenar',
		path: '/analise/soroteca-armazenar',
		component: sorotecaArmazenarVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'exames-bloqueados',
		path: '/analise/exames-bloqueados',
		component: examesBloqueadosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'exames-entregas',
		path: '/analise/exames-entregas',
		component: examesEntregasVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'exames=mesmo-paciente',
		path: '/atendimento/examesMesmoPaciente/:id/:pagamentoGuia/:condicaoPagamento',
		component: examesMesmoPaciente,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'pesquisaclinica',
		path: '/atendimento/pesquisaclinica/:id/:pagamentoGuia?/:condicaoPagamento?/:pesquisaExecucao?',
		component: pesquisaclinica,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'workflow',
		path: '/atendimento/workflow/:id/:agendamentoDashboard?',
		component: workflow,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'worklist',
		path: '/atendimento/worklist/:id',
		component: worklist,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'estoqueRastreio',
		path: '/relatorios/estoqueRastreio',
		component: estoqueRastreio,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'movimentacaoConveniosUnidades',
		path: '/relatorios/movimentacaoConveniosUnidades',
		component: movimentacaoConveniosUnidades,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'acompanhamentoExames',
		path: '/relatorios/acompanhamentoexames',
		component: acompanhamentoexames,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'acompanhamentoExamesLista',
		path: '/relatorios/acompanhamentoexameslista',
		component: acompanhamentoexameslista,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'amostraIncidencia',
		path: '/analise/amostraIncidencia',
		component: amostraIncidencia,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'progresso-setor',
		path: '/analise/progresso-setor',
		component: progressoSetor,
		meta: {
			roles: ["Funcionario"]
		}
    },
	{
		name: 'emissao-bancada',
		path: '/analise/emissao-bancada',
		component: emissaoBancada,
		meta: {
			roles: ["Funcionario"]
		}
    },
	{
		name: 'progresso-bancada',
		path: '/analise/progresso-bancada',
		component: progressobancada,
		meta: {
			roles: ["Funcionario"]
		}
    },
	{
		name: 'abrir-lote',
		path: '/analise/abrir-lote/:id',
		component: abrirLote,
		meta: {
			roles: ["Funcionario"]
		}
    },
	{
		name: 'lote-resultados',
		path: '/analise/lote-resultados',
		component: loteResultados,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tiposdeagendamento',
		path: '/manutencao/tiposdeagendamento',
		component: tiposdeAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tipoagendamento',
		path: '/manutencao/tipoagendamento/:id?',
		component: tipoAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'regrasdeagendamento',
		path: '/manutencao/regrasdeagendamento',
		component: regrasdeAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'regradeagendamento',
		path: '/manutencao/regradeagendamento/:id?',
		component: regraAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'amostrastatus',
		path: '/manutencao/amostrastatus/:id?',
		component: amostraStatus,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'amostrasstatus',
		path: '/manutencao/amostrasstatus',
		component: amostrasStatus,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'examesextracao',
		path: '/manutencao/examesextracao',
		component: examesExtracao,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'exameextracao',
		path: '/manutencao/exameextracao/:id?',
		component: exameExtracao,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'estudos',
		path: '/analise/estudos',
		component: estudos,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'estudolaudo',
		path: '/analise/estudo/laudo/:id',
		component: estudolaudo,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'modelolaudodescritivo',
		path: '/manutencao/modelolaudodescritivo/:id?',
		component: modelolaudodescritivo,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'modeloslaudodescritivo',
		path: '/manutencao/modeloslaudodescritivo',
		component: modeloslaudodescritivo,
		meta: {
			roles: ["Funcionario"]
		}
	},	
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active'
})

const storeInit = store.dispatch('init')

router.beforeEach(async (to, from, next) => {
	await storeInit.then(function() {

		if(store.state.usuario.id != undefined){
			if(store.state.usuario.tipos[0] == "Convenio"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Convenio").length > 0)) {
					next()
				} else {
					console.log('next')
					next("/portal/convenio")
				}
			}

			if(store.state.usuario.tipos[0] == "Local"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Local").length > 0)) {
					next()
				} else {
					next("/portal/local")
				}
			}

			if(store.state.usuario.tipos[0] == "Medico"){

				if (to.matched.some(record => record.meta.roles.filter(role => role == "Medico").length > 0)) {
					next()
				} else {
					next("/portal/medico")
				}
			}

			if(store.state.usuario.tipos[0] == "Paciente"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Paciente").length > 0)) {
					next()
				} else {
					next("/portal/paciente")
				}
			}

			if(store.state.usuario.tipos[0] == "Funcionario"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Funcionario").length > 0)) {
					next()
				} else {
					next("/")
				}
			}
		}
		else {
			next()
		}
	})
    .catch(e => {
		console.error(e);
    })

})

export default router
