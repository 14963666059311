<template>
  <section>
    <article class="panel">
      <div class="panel-heading has-text-centered">
        {{ label }}
      </div>
      <div class="panel-block">
        <b-table :data="interno.items" class="table is-bordered is-striped is-fullwidth" striped hoverable>
          <template >
            <b-table-column v-slot="props" field="" label="">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="delete"
                class="button-delete"
                @click="deleteItem(props.row)"
              >
              </b-button>
            </b-table-column>
            <b-table-column v-slot="props" field="id" :label="$t('BUSCAEQUIPAMENTO.ID')" class="id-column">
                <b-input
                  v-model="props.row.id"
                  @blur="getId(props.row)"
                  @keyup="$emit('update:id', inputid)"
                  icon="magnify"                  
                ></b-input>
            </b-table-column>
            <b-table-column v-slot="props" field="equipamento" :label="$t('BUSCAEQUIPAMENTO.EQUIPAMENTO')">
              <b-autocomplete
                :data="data"
                v-model="props.row.nome"
                ref="autocomplete"
                field="nome"
                icon="magnify"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="selectItem(props.row, $event)"
              >
                <template slot="empty">
                  Nenhum {{ label }} encontrado
                </template>
                <template slot-scope="props">
                  <div>
                    <div class="">
                      <div class="tag">{{ props.option.id }}</div>
                      {{ props.option.nome }}
                    </div>
                  </div>
                  <slot
                    v-if="hasDefaultSlot"
                    :option="props.option"
                    :index="props.index"
                  />
                </template>
              </b-autocomplete>
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div class="panel-heading has-text-centered">
        <b-button
          type="is-success"
          native-type="button"
          icon-left="plus"
          class="center"
          @click="addItem()"
        >
          {{$t('BUSCACONVENIO.INCLUIR')}}
        </b-button>
      </div>
    </article>
  </section>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    items: [],
    label: String,
  },
  data() {
    return {
      data: [],
      inputid: this.id,
      isFetching: false,
      table: "EquipmentDicom",
      interno: {
        items: [],
      },
    };
  },
  watch: {
    items() {
      this.interno.items = this.items;
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
  methods: {
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`/Search/${this.table}?nome=${name}&_=${new Date().getTime()}`)
        .then(({ data }) => {
          this.data = [];
          data.forEach((item) => this.data.push(item));
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    addItem() {
      this.interno.items.push({
        id: null,
        nome: null,
        changed: true,
        show: true,
      });
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 100);
    },
    deleteItem(item) {
      this.interno.items = this.interno.items.filter((i) => i.id !== item.id);
      this.change();
    },
    selectItem(item, option) {
      item.id = option.id;
      item.nome = option.nome;
      item.changed = true;
      this.change();
    },
    change() {
      const result = this.interno.items;
      this.$emit("update:items", result);
    },
    getId(item) {
      this.isFetching = true;
      this.$http
        .get(`/Search/${this.table}Id?id=${item.id}`)
        .then(({ data }) => {
          item.id = data.id;
          item.nome = data.nome;
          this.change();
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
  },
};
</script>
