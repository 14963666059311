import { Line, mixins } from 'vue-chartjs'
import * as chartConfig from '@/components/Charts/chart.config'

export default {
    name: 'line-chart',
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: {
        extraOptions: Object
    },
    data() {
        return {
            ctx: null
        }
    },
    mounted() {
        this.$watch('chartData', (newVal, oldVal) => {
            if (!oldVal) {
                this.renderChart(
                    {
                        datasets: [{
                            //fill: false,
                            borderColor: chartConfig.chartColors.default.info,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: chartConfig.chartColors.default.info,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: this.chartData.map(x => {
                                return x.valor
                            })
                        }],
                        labels: this.chartData.map(x => {
                            return this.$moment(x.dataDeSolicitacao).fromNow()
                        })
                    },
                    chartConfig.chartOptionsMain
                )
            }
        }, { immediate: true })
    }
}
