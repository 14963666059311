<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'Dre'" @filtrar="filtrar($event)"></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <article v-else class="panel" >
            <div class="panel tile is-ancestor" v-if="dre != null && dre.length > 0">
                <div class="tile is-12 is-centered is-vertical is-parent">
                    <div class="tile is-child box">
                        <div class="panel-heading has-text-centered">
                            Demonstrativo de Resultado do Exercicio
                        </div>
                        <!-- LINHA CABEÇALHO -->
                        <div class="b-table" style="overflow-y: auto">
                            <div class="table-wrapper has-mobile-cards">
                                <table class="table is-hoverable">
                                    <thead>
                                        <th class="width-15">Categoria</th>
                                        <th class="width-10">Operador</th>
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'janeiro'))">Janeiro</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'fevereiro'))">Fevereiro</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'março'))">Março</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'abril'))">Abril</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'maio'))">Maio</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'junho'))">Junho</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'julho'))">Julho</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'agosto'))">Agosto</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'setembro'))">Setembro</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'outubro'))">Outubro</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'novembro'))">Novembro</th>  
                                        <th class="width-10" v-if="dre.some(x => x.itens.some(x => x.mes == 'dezembro'))">Dezembro</th>  
                                    </thead>
                                    <tr v-for="lista in dre" :key="lista.id">
                                        <td :class="lista.operador == 3 ? 'width-10 has-text-grey has-background-grey-lighter' : 'width-10'"> {{ lista.dreCategoria }} </td>
                                        <td :class="lista.operador == 3 ? 'width-10 has-text-grey has-background-grey-lighter' : 'width-10'"> 
                                            {{ 
                                                lista.operador == 1 
                                                ? '+' 
                                                : lista.operador == 2 
                                                    ? '-'
                                                    : '='
                                            }} 
                                        </td>
                                        <td v-for="(mes,index) in lista.meses" :key="index" :class="lista.operador == 3 ? 'width-10 has-text-grey has-background-grey-lighter' : 'width-10'"> 
                                            <p v-for="(item, index) in lista.itens" :key="index">
                                                <span v-if="item.mes == mes">
                                                    {{ item.valor.toFixed(2) | currency}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="width-15 has-text-white-ter has-background-grey">Resultado Operacional</td>
                                        <td class="width-15 has-text-white-ter has-background-grey"></td>
                                        <td  v-for="(lista, index) in resultadoOperacional" :key="index" 
                                            class="width-10 has-text-white-ter has-background-grey" 
                                        >
                                            {{lista.valor.toFixed(2) | currency}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>                                        
                    </div>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/financeiro/filtrosAcompanhamento.vue';

    export default {
        data() {
            return {
                isLoading: false,
                salvandoDados: false,
                dre: null,
                resultadoOperacional: [],
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('DRECADASTRO.TITULO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        methods: {
            filtrar(filtros){
                const params = [
                    `dataInicial=${this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                ]
                
                if(filtros.unidade?.id != null)
                {
                    params.push(`unidadeId=${filtros.unidade.id}`)
                }

                if(filtros.contaCorrente?.id != null)
                {
                    params.push(`contaCorrenteId=${filtros.contaCorrente.id}`)
                }

                if(filtros.centrosDeResultadoSelecionados.length > 0)
                {
                    params.push('centrosDeResultadoSelecionados=' + filtros.centrosDeResultadoSelecionados.map(item => item.id).join());
                }

                this.carregarLista(params);
            },
            carregarLista(params){
                this.isLoading = true;
                this.$http.get(`/api/financeiro/RelatorioDre?${params.join('&')}`)
                    .then(res => {
                        this.inserirValoresZerados(res.data.relatorio);
                        this.resultadoOperacional = res.data.resultadoOperacional;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        throw error
                    });
            },
            inserirValoresZerados(data) {
                this.dre = data.map(lista => {
                    const mesAdicionado = [];
                    lista.meses.forEach(mes => {
                        if(lista.itens.length != lista.meses.length){
                            lista.itens.forEach(item => {
                                if(item.mes != mes && !mesAdicionado.includes(mes)) {
                                    mesAdicionado.push(mes)
                                    lista.itens.push({
                                        mes: mes,
                                        valor: 0.00
                                    })
                                }
                                else {
                                    mesAdicionado.push(mes)
                                }
                            })
                        }
                    });

                    return lista;
                })
            }
        }
    }
</script>