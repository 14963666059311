<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALBLOQUEIO.BLOQUEAREXAME')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALBLOQUEIO.ATENCAO')}}</h5>
                <p>{{$t('MODALBLOQUEIO.NOTIFICAOBLOQ')}}</p>
            </b-notification>
            <b-notification v-if="erroBloqueioExame"
                            type="is-danger">
                {{ erroBloqueioExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALBLOQUEIO.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('MODALBLOQUEIO.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosBloqueioExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosBloqueioExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoBloqueioExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosBloqueioExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarBloqueioExame">{{$t('MODALBLOQUEIO.CONFIRMARBLOQUEIO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALBLOQUEIO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            result: Object,
            selecionado: Array,
            portal: Boolean,            
        },
        data() {
            return {
                procurarMotivosBloqueioExame: '',
                listaMotivosBloqueioExame: [],
                motivoBloqueioExameSelecionado: [],
                erroBloqueioExame: null,
            }
        },
        computed: {
            filteredMotivosBloqueioExame() {
                return this.procurarMotivosBloqueioExame
                    ? this.listaMotivosBloqueioExame
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosBloqueioExame.toUpperCase()) > -1)
                    : this.listaMotivosBloqueioExame
            },
        },
        mounted() {
            this.loadListaMotivosBloqueioExame()
        },
        methods: {
            loadListaMotivosBloqueioExame() {
                this.$http.get('/api/search/MotivosBloqueioExame?portal=' + this.portal)
                    .then(m => {
                        this.listaMotivosBloqueioExame = m.data
                    })
            },
            confirmarBloqueioExame() {

                if(this.result !=null){
                    

                    if(this.motivoBloqueioExameSelecionado.length>0){

                    
                        this.$http.post('/api/interface/ConfirmResultadoBloqueio', {
                            lisOrderID:  this.guia.id,
                            LISTestId: this.result.lisTestID,
                            LISTestCode: this.result.lisTestCode,
                            LISResultCode: this.result.lisResultCode,
                            Confirm : true,
                            LISResultVersion: this.result.lisResultVersion,
                            value: this.result.value,
                            resultId : this.result.resultId,
                            motivoIds: this.motivoBloqueioExameSelecionado                                                               
                        })
                        .then((res) => {
                            if (res.body.erro) {
                                this.erroBloqueioExame = res.body.erro
                            } else {                
                                this.$buefy.toast.open({
                                    message: this.$t('MODALBLOQUEIO.RESULTADOCONFIRMADO'),
                                    type: 'is-success'
                                });                                                                         
                                this.$emit('removerItemConfirmado',{guiaId: this.guia.id, resultId:this.result.resultId});
                                this.$emit('close');
                            }
                        })     
                    
                    }

                }else{

                    this.$http.post('/api/atendimento/bloquearExame', {
                        id: this.guia.id,
                        item: this.selecionado.map(x => x.item),
                        motivoIds: this.motivoBloqueioExameSelecionado
                    })
                        .then((res) => {
                            if (res.body.erro) {
                                this.erroBloqueioExame = res.body.erro
                            } else {
                                this.$emit('loadGuia')
                                this.$emit('close')
                            }
                        })                    

                }


            },

        }
    }
</script>