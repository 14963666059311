<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div v-if="mostrarCadastro" class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('CATEGORIAFINANCEIRA.ID')">
                                <b-input type="text" v-model="model.id" :readonly="true"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CATEGORIAFINANCEIRA.NOME')">
                                <b-input type="text" maxlength="20" v-model="model.nome"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CATEGORIAFINANCEIRA.REGISTROPAI')">
                                <b-input type="text" v-model="model.nomePai" :readonly="true"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CATEGORIAFINANCEIRA.DEPARACONTABIL')">
                                <b-input type="text" maxlength="10" v-model="model.deparaContabil"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-flex">
                            <b-field class="pr-2" :label="$t('CATEGORIAFINANCEIRA.TIPO')">
                                <b-select v-model="model.tipo">
                                    <option value="0">Receita</option>
                                    <option value="1">Despesa</option>
                                </b-select>
                            </b-field>
                            <b-field :label="$t('CATEGORIAFINANCEIRA.DRE')">
                                <b-select v-model="model.dreId">
                                    <option v-for="option in dres.filter(x => x.operador != 3)"
                                        :value="option.id"
                                        :key="option.id">
                                        <span>
                                        {{ option.operador == 1 ? '( + )' : '( - )'}}  {{ option.nome }}
                                        </span>
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            
                        </div>
                    </div>
                    <nav class="level">
                        <div class="level-item">
                            <p class="buttons">
                                <b-button type="is-success"
                                        :loading="salvandoDados"
                                        native-type="button" @click="salvar()"
                                        icon-left="check-circle">
                                    {{$t('CATEGORIAFINANCEIRA.SALVAR')}}
                                </b-button>
                            </p>
                        </div>
                    </nav>
                </article>
            </div>              
        </div>
        <div class="columns box">
            <div class="column">
                <h2 class="subtitle">{{$t('CATEGORIAFINANCEIRA.RECEITA')}}</h2>
                <b-button style="margin-bottom: 2px;" type="type is-primary" size="is-small"
                    icon-left="plus"
                    @click="clickPlusRootReceita">
                    {{$t('CATEGORIAFINANCEIRA.NOVORAIZ')}}
                </b-button>
                <TreeView 
                    :nodes="treeDataReceita"
                    @clickPlus="plusWasClicked"
                    @clickEdit="editWasClicked"
                    />
            </div>
            <div class="column" style="border-left: 1px solid rgba(0, 0, 0, .3);">
                <h2 class="subtitle">{{$t('CATEGORIAFINANCEIRA.DESPESA')}}</h2>
                <b-button style="margin-bottom: 2px;" type="type is-primary" size="is-small"
                    icon-left="plus"
                    @click="clickPlusRootDespesa">
                    {{$t('CATEGORIAFINANCEIRA.NOVORAIZ')}}
                </b-button> 
                <TreeView 
                    :nodes="treeDataDespesa"
                    @clickPlus="plusWasClicked"
                    @clickEdit="editWasClicked"
                    />
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import TreeView from "@/components/treeView.vue";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    registroPai: null,
                    nomePai: null,
                    tipo: 0,
                    deparaContabil: null,
                    dre: null,
                    dreId: null,
                },
                treeDataReceita: [],
                treeDataDespesa: [],
                mostrarCadastro: false,
                salvandoDados: false,
                categoriasFinanceiras: [],
                dres: [],
            }
        },
        components: {
            titlebar,
            TreeView
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('MENUFINANCEIRO.CATEGORIAFINANCEIRA')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        created() {   
            this.loadCategoriasFinanceiras();
            this.loadDres();
        },    
        methods: {
            loadCategoriasFinanceiras(){
                this.treeDataReceita = [];
                this.treeDataDespesa = [];

                this.$http.get('/api/manutencao/BuscarCategoriasFinanceiras')
                    .then(res => {
                        this.categoriasFinanceiras = res.data;
                        this.construirTreeDataReceita();
                        this.construirTreeDataDespesa();
                    })
            },
            loadDres(){
                this.$http.get('/api/search/ListarDres')
                .then(res => {
                    this.dres = res.data;
                })
            },
            construirTreeDataReceita(){
                const roots = this.categoriasFinanceiras.filter(categoria => categoria.registroPai == null && categoria.tipo == 0);

                roots.forEach(root => {
                    root = this.controiFilhos(root);

                    this.treeDataReceita.push({
                        id: root.id,
                        name: root.nome,
                        children: root.children
                    });
                });

            },
            construirTreeDataDespesa(){
                const roots = this.categoriasFinanceiras.filter(categoria => categoria.registroPai == null && categoria.tipo == 1);

                roots.forEach(root => {
                    root = this.controiFilhos(root);

                    this.treeDataDespesa.push({
                        id: root.id,
                        name: root.nome,
                        children: root.children
                    });
                });

            },
            controiFilhos(root){
                const suns = this.categoriasFinanceiras.filter(categoria => categoria.registroPai == root.id);

                const sunsRoot = [];
                if(suns != null && suns.length > 0){
                    suns.forEach(sun => {
                        //recursive
                        sun = this.controiFilhos(sun);

                        sunsRoot.push({
                            id: sun.id,
                            name: sun.nome,
                            children: sun.children,
                            filho:true
                        });
                    });
                }

                root.children = sunsRoot;

                return root;
            },
            clickPlusRootReceita(){
                this.model = {
                    id: 0,
                    nome: null,
                    registroPai: null,
                    nomePai: null,
                    tipo: 0,
                    deparaContabil: null,
                    dreId: null,
                };

                this.mostrarCadastro = true;
            },
            clickPlusRootDespesa(){
                this.model = {
                    id: 0,
                    nome: null,
                    registroPai: null,
                    nomePai: null,
                    tipo: 1,
                    deparaContabil: null,
                    dreId: null,
                };

                this.mostrarCadastro = true;
            },
            plusWasClicked(node){
                this.model = {
                    id: 0,
                    nome: null,
                    registroPai: node.id,
                    nomePai: node.name,
                    deparaContabil: null
                };

                this.model.tipo = this.categoriasFinanceiras.filter(categoria => categoria.id == this.model.registroPai)[0].tipo;                

                this.mostrarCadastro = true;
            },
            editWasClicked(node){
                this.model = {
                    id: node.id,
                    nome: node.name,
                    registroPai: this.categoriasFinanceiras.filter(categoria => categoria.id == node.id)[0].registroPai,
                    deparaContabil: this.categoriasFinanceiras.filter(categoria => categoria.id == node.id)[0].deparaContabil,
                    tipo: this.categoriasFinanceiras.filter(categoria => categoria.id == node.id)[0].tipo,
                    dreId: this.categoriasFinanceiras.filter(categoria => categoria.id == node.id)[0].dreId,
                };
                
                if(this.model.registroPai != null)
                {
                    this.model.nomePai = this.categoriasFinanceiras.filter(categoria => categoria.id == this.model.registroPai)[0].nome;
                }

                this.mostrarCadastro = true;
            },
            salvar(){
                

                if(!this.model.nome){
                    this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('CATEGORIAFINANCEIRA.CAMPONOMEOBRIGATORIO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });                        
                    return true;          
                }      

                if(this.model.dreId != null) {
                    this.model.dre = {
                        id: this.model.dreId,
                    }
                }

                this.salvandoDados = true;
                
                this.$http.post('/api/manutencao/CategoriaFinanceira', this.model)
                    .then(res => {
                        this.model = {
                            id: null,
                            nome: null,
                            registroPai: null,
                            nomePai: null,
                            tipo: 0,
                            deparaContabil: null,
                            dreId: null,
                        };
                        
                        this.salvandoDados = false;
                        this.mostrarCadastro = false;
                        this.loadCategoriasFinanceiras();
                    });
            }
        }
    }
</script>