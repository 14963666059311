<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('TIPOAGENDAMENTOCADASTRO.SALVANDOAGENDAMENTO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column column is-one-quarter">
                                    <ValidationProvider name="model.cor" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.COR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.cor"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="columns is-one-quarter">
                                    <div class="column is-half">
                                        <br>
                                        <input type="color" :change="corSelecionada != null ? model.cor = corSelecionada.substring(1) : null" v-model="corSelecionada">
                                    </div>
                                    <div class="column is-half">
                                        <br>
                                        <b-switch v-model="model.desativado" type="is-danger">
                                            {{$t('TIPOAGENDAMENTOCADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="columns">                                
                                <div class="column is-one-quarter">
                                    <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.IDADEMIN')">
                                        <b-input type="number" max="300" v-model="model.idadeMin"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-one-quarter">
                                     <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.IDADEMAX')">
                                        <b-input type="number" max="300" v-model="model.idadeMax"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-one-fifth">
                                     <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.LIMITEPESO')">
                                        <b-input type="number" step="0.01" v-model="model.peso"></b-input>
                                    </b-field>
                                </div>
                                  <div class="column is-one-quarter">
                                    <ValidationProvider rules="required" name="sexo" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.SEXO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('TIPOAGENDAMENTOCADASTRO.SELECIONARSEXO')" v-model="model.sexo">
                                                <option value="F">{{$t('TIPOAGENDAMENTOCADASTRO.FEM')}}</option>
                                                <option value="M">{{$t('TIPOAGENDAMENTOCADASTRO.MASC')}}</option>
                                                <option value="T">{{$t('TIPOAGENDAMENTOCADASTRO.TODOS')}}</option>

                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <searchIdName :id.sync="model.unidadeId" :item.sync="model.unidade" :label="$t('TIPOAGENDAMENTOCADASTRO.UNIDADE')" table="Unidade"></searchIdName>
                                                                                    
                        </article>

                        <article class="panel">
                            <buscaEquipamento :label="$t('TIPOAGENDAMENTOCADASTRO.EQUIPAMENTOS')" :items.sync="model.equipments" />
                        </article>

                        <article class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('TIPOAGENDAMENTOCADASTRO.ESCALA')}}
                            </div>
                            <div class="panel-block" style="overflow-x: auto;">
                                <table class="table is-bordered  is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.IDESCALA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.DIADASEMANA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.NOMEESCALA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAINICIO')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAFIM')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.INTERVALO')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.VIGENCIA')}}</th>
                                            <th>{{$t('SISTEMA.EXECUTANTE')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.PERMITECONFLITO')}}</th>
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.escalas?.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>
                                            <td>{{item.id}}</td>
                                            <td> 
                                                <b-select class="is-fullwidth" v-model="item.diaDaSemana">
                                                    <option value="1" >Segunda</option>
                                                    <option value="2" >Terça</option>
                                                    <option value="3" >Quarta</option>
                                                    <option value="4" >Quinta</option>
                                                    <option value="5" >Sexta</option>
                                                    <option value="6" >Sábado</option>
                                                    <option value="0" >Domingo</option>
                                                </b-select>                                            
                                            
                                            </td>
                                            <td> <input class="input" type="text" v-model="item.nome" maxlength="100"> </td>
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaInicioEscala"> </td>
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaFimEscala"> </td>                                  
                                            <td> <input class="input" type="text" v-mask="'##'" v-model="item.intervaloDuracaoAtendimento"> </td>
                                            <td> 
                                                <b-datepicker  editable v-model="item.dataHoraVigencia" trap-focus></b-datepicker>
                                            </td>                                                      
                                            <td>
                                                <b-select v-model="item.funcionarioId" :key="index">
                                                    <option :value="null"></option>
                                                    <option v-for="(func, index) in funcionariosAtivos" :key="index" :value="func.id">
                                                        {{func.nome}}
                                                    </option>
                                                </b-select>
                                            </td>
                                            <td> 
                                                <b-checkbox v-model="item.permiteConflitoExecutante"> </b-checkbox>                                                
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('TIPOAGENDAMENTOCADASTRO.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>                                        

                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('tipos-agendamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TIPOAGENDAMENTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TIPOAGENDAMENTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import buscaEquipamento from '@/components/manutencao/buscaEquipamento.vue';

    export default {
        components: {
            titlebar,
            searchIdName,
            buscaEquipamento,
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    sexo: null,
                    idadeMin: null,
                    idadeMax: null,
                    peso: null,
                    cor: null,
                    desativado: null,
                    unidadeId: null,
                    equipmentId: null,
                    escalas: [],
                    equipments: []
                },
                funcionariosAtivos: [],
                corSelecionada: null,
                salvandoDados: false,
                retornarTipoAgendamento: Boolean(this.$route.query.retornarTipoAgendamento) ?? false,
            }
        },
        created() {
            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
                    .then(res => {
                        if (res.data != null) {
                            this.funcionariosAtivos = res.data
                        }
                    });

            if(this.$route.params.id){
                this.$http.get('/api/manutencao/tipoagendamento?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;

                        this.model.escalas.forEach((escala) => { 
                            escala.show = true; 
                            escala.dataHoraVigencia = (escala.dataHoraVigencia) ? new Date(escala.dataHoraVigencia) : null;
                        });
                                                
                        this.corSelecionada = "#" + this.model.cor;

                        if(this.model.sexo == null || this.sexo == "") {
                            this.model.sexo = "T";
                        }

                        const equipments = res.data?.equipments?.map(c => {
                            return {
                                id: c.id,
                                nome: c.applicationName
                            }
                        })
                        res.data.equipments = equipments; 
                        this.model = res.data;
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TIPOAGENDAMENTOCADASTRO.MANUTENCAO'),
                    this.$t('TIPOAGENDAMENTOCADASTRO.TIPOAGENDAMENTO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            addItem() {
                this.model.escalas.push({
                    id: 0,
                    nome: null,
                    horaInicioEscala: null,
                    horaFimEscala: null,
                    intervaloDuracaoAtendimento: null,
                    show:true,
                    funcionarioId: null,
                })
            },
            
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.escalas.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },                              
                                
            onSubmit() {
                this.salvandoDados = true;
                let todosEscalasPreenchidas = true;                

                
                if(this.model.sexo == null || this.sexo == "") {
                    this.model.sexo = "T";
                }

                if(this.model.id == null){
                    this.model.id = 0
                }
                
                this.model.equipments = this.model?.equipments?.filter(c => c.id != null);

                this.model.escalas.filter(i => i.show===true).forEach((escala) => {
                                                     
                    if(!escala.nome || !escala.intervaloDuracaoAtendimento
                    || !escala.horaInicioEscala || !escala.horaFimEscala  ){
                        todosEscalasPreenchidas= false;
                     }
                });



                if(todosEscalasPreenchidas ){
                    this.$http.post(`/api/manutencao/tipoagendamento`, this.model)
                            .then((res) => {
                                this.salvandoDados = false;

                                if(this.retornarTipoAgendamento){
                                    localStorage.retornarTipoAgendamento = res.data.id;   
                                    window.close();
                                } else{
                                    this.$router.back();
                                }

                            
                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('TIPOAGENDAMENTOCADASTRO.ATENCAO'),
                        message: this.$t('TIPOAGENDAMENTOCADASTRO.CAMPOSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });                    
                    this.salvandoDados = false;
                    return null;
                }
                
            },
        }
    }
</script>