<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div v-for="item, index in Object.keys(model)" :key="index">
            <p>{{ item }}</p>
            <div v-for="lote, idx in Object.keys(model[item])" :key="idx">
                <span class="tag">{{ lote }}</span>
                <div class="card">
                    <line-chart :chart-data="chartData(model[item][lote])" :extra-options="chartOptions" height=200></line-chart>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import LineChart from '@/components/Charts/LineChart'
    import * as chartConfig from '@/components/Charts/chart.config'

    export default {
        data() {
            return {
                model: []
            }
        },
        components: {
            titlebar,
            LineChart
        },
        computed: {
            titleStack() {
                return [
                    'Interface',
                    'Controle de Qualidade',
                    this.$route.params.id
                ]
            },
            chartOptions() {
                return chartConfig.chartOptionsMain
            }
        },
        methods: {
            loadAsyncData() {
                this.$http.get('/api/interface/qualityControlResults?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data.lista
                    })
            },
            grupoLote(item) {
                return this.model[item]
            },
            chartData(grupo) {                
                return {
                    datasets: [
                        {
                            fill: false,
                            borderColor: chartConfig.chartColors.default.primary,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointRadius: 0,
                            data: grupo.map(x => x.meanValue)
                        },
                        {
                            fill: false,
                            borderColor: chartConfig.chartColors.default.info,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: chartConfig.chartColors.default.info,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: grupo.map(x => x.result)
                        },
                        {
                            fill: false,
                            borderColor: '#c7c7c7',
                            borderWidth: 1,
                            borderDash: [10,5],
                            pointRadius: 0,
                            data: grupo.map(x => x.meanValue + x.standardDeviation)
                        },
                        {
                            fill: false,
                            borderColor: '#c7c7c7',
                            borderWidth: 1,
                            borderDash: [10,5],
                            pointRadius: 0,
                            data: grupo.map(x => x.meanValue - x.standardDeviation)
                        },
                    ],
                    labels: grupo.map(x => this.$moment(x).fromNow())
                }
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>