<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('DESBLOQUEIOEXAME.DESBLOQUEIO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('DESBLOQUEIOEXAME.ATENCAO')}}</h5>
                <p>{{$t('DESBLOQUEIOEXAME.DESBLOQUEIASELECIONADOS')}}</p>
            </b-notification>
            <b-notification v-if="erroDesbloqueioExame"
                            type="is-danger">
                {{ erroDesbloqueioExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('DESBLOQUEIOEXAME.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('DESBLOQUEIOEXAME.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosDesbloqueioExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosDesbloqueioExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoDesbloqueioExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosDesbloqueioExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarDesbloqueioExame">{{$t('DESBLOQUEIOEXAME.CONFIRMARDESBLOQUEIO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('DESBLOQUEIOEXAME.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array,
            portal: Boolean,            
        },
        data() {
            return {
                procurarMotivosDesbloqueioExame: '',
                listaMotivosDesbloqueioExame: [],
                motivoDesbloqueioExameSelecionado: [],
                erroDesbloqueioExame: null,
            }
        },
        computed: {
            filteredMotivosDesbloqueioExame() {
                return this.procurarMotivosDesbloqueioExame
                    ? this.listaMotivosDesbloqueioExame
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosDesbloqueioExame.toUpperCase()) > -1)
                    : this.listaMotivosDesbloqueioExame
            },
        },
        mounted() {
            this.loadListaMotivosDesbloqueioExame()
        },
        methods: {
            loadListaMotivosDesbloqueioExame() {
                this.$http.get('/api/search/MotivosDesbloqueioExame??portal=' + this.portal)
                    .then(m => {
                        this.listaMotivosDesbloqueioExame = m.data
                    })
            },
            confirmarDesbloqueioExame() {
                this.$http.post('/api/atendimento/DesbloquearExame', {
                    id: this.guia.id,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoDesbloqueioExameSelecionado
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroDesbloqueioExame = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
            },

        }
    }
</script>