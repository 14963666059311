<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <contaPagar :model="model" ></contaPagar>
    </section>
</template>

<script>
    import { mapGetters, mapState  } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import contaPagar from '@/components/financeiro/contaPagar.vue'

    export default {
        data() {
            return {
                model: [],
            }
        },
        components: {
            titlebar,
            contaPagar
        },
        created() {
            
            if (this.$route.params.id) {
                const params = [];
                params.push(`id=${this.$route.params.id}`);

                if (this.$route.params.notafiscal)
                    params.push(`notaFiscal=${this.$route.params.notafiscal}`);

                if (this.$route.params.pedidoid)
                    params.push(`pedidoId=${this.$route.params.pedidoid}`);
                
                this.$http.get(`/api/financeiro/pagar?${params.join('&')}`)
                    .then(res => {
                        this.model.push(res.data);   
                        this.model[0].data = new Date(this.model[0].data);
                        this.model[0].dataDeCompetencia = new Date(this.model[0].dataDeCompetencia);
                        
                        this.model[0].centrosResultados.forEach((centroResultados) => {
                            centroResultados.show = true; 
                        });

                        for (let i = 0; i < this.model[0].parcelas.length; i++) {
                            this.model[0].parcelas[i].dataDeVencimento = new Date(this.model[0].parcelas[i].dataDeVencimento);
                            this.model[0].parcelas[i].dataDePagamento =  new Date(this.model[0].parcelas[i].dataDePagamento);                            
                        }
                        
                    })

            }else{
                const novo =  {
                    id: null,
                    data: new Date(),
                    dataDeCompetencia:new Date(),
                    descricao: null,
                    funcionarioId: null,
                    unidadeId: null,
                    categoriaFinanceira: {
                        id: null,
                    },
                    contaCorrente: {
                        id: null,
                    },
                    fornecedor: {
                        id: null,
                    },
                    notaFiscal: this.$route.params.notafiscal ? this.$route.params.notafiscal : null,
                    observacoes: null,
                    pedidoId: this.$route.params.pedidoid ? this.$route.params.pedidoid : 0,
                    parcelas: [],
                    centrosResultados: []
                };  
                
                this.model.push(novo); 
            }

        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('CONTASPAGARLISTAR.TITULO'),                    
                    (this.model.length>0 && this.model[0].id > 0) ? this.model[0].id : this.$t('SISTEMA.NOVO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config'
            ])
        }
    }
</script>