<template>
    <b-table :data="adaptar(lancamentosFinanceiros)" striped hoverable>
        <template>
            <b-table-column v-slot="props" field="parcelaValor" :label="$t('CONCILIACAOBANCARIA.VALOR')">
                {{ props.row.parcelaValor | currency }}
            </b-table-column>
            <b-table-column v-slot="props" field="parcelaVencimento" :label="$t('CONCILIACAOBANCARIA.DATAVENCIMENTO')">
                {{ props.row.parcelaVencimento | moment("DD/MM/YYYY")}}
            </b-table-column>
            <b-table-column v-slot="props" field="parcelaNotaFiscal" :label="$t('CONCILIACAOBANCARIA.NOTAFISCAL')">
                {{ props.row.notaFiscal}}
            </b-table-column>
            <b-table-column v-slot="props" field="referenciaLancamento" :label="$t('CONCILIACAOBANCARIA.REFERENCIA')">
                <router-link target='_blank' v-if="props.row.tipo == 'Pagar'"
                    :to="{ name: 'contaspagar', params: { id: props.row.id }}">
                    {{ props.row.descricao}}
                </router-link>
                <router-link target='_blank' v-else :to="{ name: 'contas-receber', params: { id: props.row.id}}">
                    {{ props.row.descricao}}
                </router-link>
            </b-table-column>
            <b-table-column v-slot="props" :visible="podeRemoverItem" custom-key="actions" numeric>
                <b-button @click="remover(props.row.parcelaId, props.row.tipo)" type="is-danger" title="Clique para cancelar" icon-left="close-circle"></b-button>
            </b-table-column>
        </template>
    </b-table>
</template>
<style scoped>

</style>
<script>
import adaptador from "../../assets/js/views/conciliacaoBancaria/adaptadorDadoLancamentoFinanceiro.js"

export default {
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        lancamentosFinanceiros: null,
        podeRemoverItem: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        adaptar(lancamentos)
        {
            return adaptador.adaptarDadosLancamentosFinanceiros(lancamentos)
        }
    },
    methods: {
        remover(parcelaId, tipoParcela)
        {
            this.$emit('removeuLancamento', parcelaId, tipoParcela)
        }
    }
}

</script>