<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALANEXO.ANEXAR')}}</p>
        </header>
        <section class="modal-card-body is-centered">
             <div class="columns">
                <div class="column">
                    <b-field>
                        <b-upload   accept=".jpg"
                                    v-model="anexoEstudo"
                                    multiple
                                    drag-drop>
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon icon="upload"
                                                size="is-medium">
                                        </b-icon>
                                    </p>
                                    <p>{{$t('MODALANEXO.ARRASTEARQUIVOS')}}</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
                <div class="column">
                    <table class="table is-striped">
                        <tbody>
                            <tr v-for="(anexo, index) in anexoEstudo" :key="index">
                                <td width="90%">
                                    <p>
                                        <span>{{ anexo.name }}</span><br>
                                    </p>
                                </td>
                                <td>
                                    <button class="delete is-small" type="button" @click="removerAnexo(index)"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                                
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"  @click="enviarEstudo">{{$t('SISTEMA.ENVIAR')}}</b-button>
            <button class="button" type="button" @click="$emit('close')">{{$t('MODALANEXO.FECHAR')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            guiasExames: String,
            guiaId: Number,

        },
        data() {
            return {
                anexoEstudo: [],
            }
        },
        computed: {
            ...mapState([
                'config'
            ]),
        },
        methods: {
            enviarEstudo() {
                const formData = new FormData()
                formData.append('guiasExames', this.guiasExames)

                Array.from(Array(this.anexoEstudo.length).keys())
                .map(x => {                    
                    formData.append('anexo', this.anexoEstudo[x], this.anexoEstudo[x].name);
                });               

                this.$http.post('/api/atendimento/enviarEstudoAnexo', formData)
                .then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })

                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                })
                
                this.$emit('close')

            },
            removerAnexo(index) {
                this.anexoEstudo.splice(index, 1);
            },
        }
    }
</script>