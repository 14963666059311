import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '../assets/lang'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: localStorage.lang || 'pt',
	messages: locales
})

export default i18n