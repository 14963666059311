const utilResizing = {    
    async resizeBase64Img(base64, newWidth, newHeight) {
        const resizedBase64 = await new Promise((resolve) => {
            const img = new Image()
            img.src = base64
            img.onload = () => {
                const canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height
    
                if (width > height) {
                    if (width > newWidth) {
                        height *= newWidth / width
                        width = newWidth
                    }
                } else {
                    if (height > newHeight) {
                        width *= newHeight / height
                        height = newHeight
                    }
                }
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL()) // this will return base64 image results after resize
            }
        });
        return resizedBase64;
    }
}

export default utilResizing;