<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <b-message>
            Foram encontradas solicitações de exames iguais para um mesmo paciente. Deseja vincular os resultados dos exames ?
        </b-message>

        <div class='box' v-if="lista.length > 0">
            <div class="panel" v-for="(item, index) in lista" v-bind:key="index">
                <p class="panel-heading background-1">
                    {{item.pacienteNome}}
                    <b-checkbox class="is-pulled-right" @input="selecionarTudo"></b-checkbox>
                </p>
                <div style='background: gainsboro;'>
                    <div v-for="(exame, j) in item.exames" v-bind:key="j" class="columns" style="border-bottom: 1px dashed;">
                        <div class="column is-6">
                            <div class="columns">
                                <div class="column is-11">
                                    <div class="box">
                                        <span class="guiaExameApelido">{{exame.exameApelido}}</span>
                                        <span class="guiaExameDataDeSolicitacao">{{ $moment(exame.dataDeSolicitacao).format("DD/MM/YYYY") }}</span><br />
                                        <span class="guiaExameObservacao">{{ exame.observacao }}</span>
                                        <b-tag type="is-success">Guia: {{item.guiaId}}</b-tag>
                                        <b-tag type="is-default">Item: {{exame.item}}</b-tag>
                                    </div>
                                </div>
                                <div class="column is-1 has-text-centered">
                                    <b-icon icon="arrow-right-bold" size="is-medium"></b-icon>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6">
                            <div class="columns">
                                <div class="column is-11">
                                    <div class="box">
                                        <span class="guiaExameApelido">{{exame.exameApelido}}</span>
                                        <span>&nbsp;</span>
                                        <span class="guiaExameDataDeSolicitacao">{{ $moment(exame.copiaDataDeSolicitacao).format("DD/MM/YYYY") }}</span><br />
                                        <span class="guiaExameObservacao">{{ exame.copiaObservacao }}</span>
                                        <b-tag type="is-success">Guia: {{exame.copiaGuiaId}}</b-tag>
                                        <b-tag type="is-default">Item: {{exame.copiaItem}}</b-tag>
                                    </div>
                                </div>
                                <div class="column is-1 has-text-centered">
                                    <b-checkbox v-model="exame.selecionado"></b-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              native-type="button"
                              :loading="salvandoDados"
                              @click="salvar()"
                              icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    
    export default {
        data() {
            return {
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                convenioCoparticipacao: this.$route.params.convenioCoparticipacao ?? false,
                agendamentoDashboard: this.$route.params.agendamentoDashboard ?? false ,
                salvandoDados: false,
                lista: []
            }
        },
        components: {
            titlebar        
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Exames de mesmo paciente',
                    this.id
                ]
            }
        },
        mounted() {
            this.salvandoDados = true
                this.$http.get(`/api/atendimento/ExamesMesmoPaciente?id=${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.lista = data;
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
        },
        methods:{
            salvar() {
                this.salvandoDados = true
                this.$http.post('/api/atendimento/VincularExames', this.lista)
                    .then(res => {                        
                        if(res.data.pesquisaClinica) {
                            this.$router.push({ name: 'pesquisaclinica', 
                            params: { 
                                guiaId: this.id, 
                                pagamentoGuia: this.pagamentoGuia, 
                                condicaoPagamento: this.condicaoPagamento,
                                convenioCoparticipacao: this.convenioCoparticipacao,
                                pesquisaPosGuia: true,
                                agendamentoDashboard: this.agendamentoDashboard
                            }});
                        }else if (res.data.id && res.data.worklist) {
                            this.$router.push({ name: 'worklist', 
                                params: { 
                                    id: res.data.id,
                                    pagamentoGuia: this.pagamentoGuia, 
                                    condicaoPagamento: this.condicaoPagamento,
                                    convenioCoparticipacao:  this.convenioCoparticipacao,
                                    agendamentoDashboard: this.agendamentoDashboard
                                }
                            });
                        }     
                        else if(this.pagamentoGuia && !this.agendamentoDashboard || this.convenioCoparticipacao ) {
                            this.$router.push({ name: 'contas-receber-particular', 
                            params: { 
                                guiaId: this.id, 
                                condicaoPagamento: this.condicaoPagamento,
                                convenioCoparticipacao: this.convenioCoparticipacao,
                                agendamentoDashboard: this.agendamentoDashboard
                            }});
                        }                      
                        else {
                            this.$router.push({ name: 'workflow', 
                                params: { 
                                    id: this.id,
                                    agendamentoDashboard: this.agendamentoDashboard
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
            },
            selecionarTudo(value) {
                this.lista.forEach(x => {
                    x.exames.forEach(y =>{
                        y.selecionado = value
                    })
                })
            }
        }
    }
</script>