<template>
    <section>

            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column is-4">
                        <div  class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.COLETADOR')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'portalcoletadores', query: { procurar: procurar.coletador }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.coletador"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'portalcoletadores' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link 
                                    :to="{ name: 'portalcoletador' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column is-4"> </div>
                    <div class="column is-4"> </div>
                </div>
            </div>
        

    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {                
                procurar: {}
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config'                
            ])
        },
        methods: {
 
        }
    }
</script>