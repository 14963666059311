<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('COLETADORESCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input :readonly="model.id" type="text" maxlength="150" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="conselho" v-slot="{ errors, valid }">
                                        <b-field :label="$t('COLETADORESCADASTRO.CONSELHO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input :readonly="model.id" type="text" maxlength="50" v-model="model.conselho"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>                       
                    <div class="tile is-parent is-3"> 
                        <article class="tile is-child is-12">
                            <article >
                                
                                <div v-if="model.id" class="panel-block">
                                    <b-field>
                                        <b-switch v-model="model.desativado" type="is-danger">
                                            {{$t('COLETADORESCADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </b-field>
                                </div>
    
                            </article>
                                                                          
                        </article> 
                    </div>                
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"                                      
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    data: null,
                    convenio: {
                        id: 0                     
                    }
                },
                ano: new Date().getFullYear(),
                salvandoDados: false
            }
        },
        created() {
                        
            if(this.$route.params.id){
                this.$http.get('/api/portal/ColetadorPortalApoiado?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        this.model.data = new Date(this.model.data);
                        this.model.convenio.id = this.usuario.convenioId;
                    });
            }else{
                this.model.convenio.id = this.usuario.convenioId;
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('COLETADORESLISTA.CADASTRO'),
                    this.$t('COLETADORESLISTA.COLETADORES'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'usuario'
            ]),            
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                this.$http.post(`/api/portal/ColetadorPortalApoiado`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
        }
    }
</script>