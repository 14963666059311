<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Valores de Referência</p>
        </header>
        <section class="modal-card-body">
            <b-tabs v-model="tabs" multiline>
                <b-tab-item value="0" label="Informações">
                    <div class="columns">
                        <div class="column">
                            <b-field label="Nome">
                                <b-input type="text" v-model="valorDeReferencia.nome" disabled></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Unidade">
                                <b-input type="text" v-model="valorDeReferencia.unidade"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns" v-for="(item, index) in valorDeReferencia.itens" :key="index">                        
                        <div class="column">
                            <b-field label="Sexo">
                                <b-select v-model="item.sexo" expanded>
                                    <option value="F">F</option>
                                    <option value="M">M</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Idade <">
                                <b-input type="text" v-model="item.idadeMaior"></b-input>
                                <b-select v-model="item.idadeMaiorPeriodo" expanded>
                                    <option value="">anos</option>
                                    <option value="meses">meses</option>
                                    <option value="semanas">semanas</option>
                                    <option value="dias">dias</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Idade <">
                                <b-input type="text" v-model="item.idadeMenor"></b-input>
                                <b-select v-model="item.idadeMenorPeriodo" expanded>
                                    <option value="">anos</option>
                                    <option value="meses">meses</option>
                                    <option value="semanas">semanas</option>
                                    <option value="dias">dias</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Valor Inicial">
                                <b-input type="text" v-model="item.valorInicial"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Valor Final">
                                <b-input type="text" v-model="item.valorFinal"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-button type="is-primary is-small" :loading="salvandoDados"
                                @click="incluir()">Inclui condição</b-button>
                        </div>
                        <div class="column">
                            <b-button type="is-danger is-small is-pull-right" :loading="salvandoDados"
                                @click="remover()">Excluir última condição</b-button>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item value="1" label="Descrição">
                    <div class="columns">
                        <b-field label="Descrição">
                            <textarea class="textarea" maxlength="10485760" rows="10" cols="100" v-model="valorDeReferencia.texto"></textarea>
                        </b-field>
                    </div>
                </b-tab-item>
            </b-tabs>
        </section>
        <footer class="modal-card-foot">
            <b-button :loading="salvandoDados" type="is-success"
                @click="salvarValorReferencia()">Confirmar</b-button>
            <b-button :loading="salvandoDados" type="is-danger"
                @click="$parent.close()">Cancelar</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            valorDeReferencia: Object
        },
        data() {
            return {
                tabs: 0,
                salvandoDados: false
            }
        },
        created() {
            this.valorDeReferencia.itens?.forEach(item => {
                let expressao = item.expressao?.toLowerCase();

                if (!expressao) {
                    expressao = '';
                }

                if (expressao?.indexOf('sexo') >= 0) {
                    item.sexo = expressao?.substring(expressao?.indexOf("sexo") + 7, expressao?.indexOf("sexo") + 9).toUpperCase().trim();
                } else if (expressao?.indexOf('clisexo') >= 0) {
                    item.sexo = expressao?.substring(expressao?.indexOf("clisexo") + 10, expressao?.indexOf("clisexo") + 12).toUpperCase().trim();
                }

                let valores = this.identificarValores(expressao, "idade");
                if(valores.length == 0){
                    valores = this.identificarValores(expressao, "cliidade");
                }

                if(valores.length != 0){
                    item.idadeMaior = valores[0];

                    if(item.idadeMaior != undefined && item.idadeMaior != null && item.idadeMaior != "") {
                        if (item.idadeMaior.indexOf('dias') != -1) {
                            item.idadeMaiorPeriodo = "dias";
                            item.idadeMaior = item.idadeMaior.replace("dias", "");
                        } else if (item.idadeMaior.indexOf('semanas') != -1) {
                            item.idadeMaiorPeriodo = "semanas";
                            item.idadeMaior = item.idadeMaior.replace("semanas", "");
                        } else if (item.idadeMaior.indexOf('meses') != -1) {
                            item.idadeMaiorPeriodo = "meses";
                            item.idadeMaior = item.idadeMaior.replace("meses", "");
                        } else {
                            item.idadeMaiorPeriodo = "";
                        }
                    }

                    item.idadeMenor = valores[1];
                    
                    if(item.idadeMenor != undefined && item.idadeMenor != null && item.idadeMenor != "") {
                        if (item.idadeMenor.indexOf('dias') != -1) {
                            item.idadeMenorPeriodo = "dias";
                            item.idadeMenor = item.idadeMenor.replace("dias", "");
                        } else if (item.idadeMenor.indexOf('semanas') != -1) {
                            item.idadeMenorPeriodo = "semanas";
                            item.idadeMenor = item.idadeMenor.replace("semanas", "");
                        } else if (item.idadeMenor.indexOf('meses') != -1) {
                            item.idadeMenorPeriodo = "meses";
                            item.idadeMenor = item.idadeMenor.replace("meses", "");
                        } else {
                            item.idadeMenorPeriodo = "";
                        }
                    }
                }
            })
        },
        methods:{
            identificarValores(expressao, termo){
                const valores = [];

                if (expressao?.indexOf(termo) != -1) {
                    const expressaoSplit = expressao?.split('and');
                    let i = 0;
                    while (expressaoSplit.length > i) {
                        if (expressaoSplit[i].indexOf(termo) != -1) {
                            valores.push(this.calculaExpressao(expressaoSplit[i]));
                        }
                        i++;
                    }                    
                }

                return valores;
            },
            calculaExpressao(expressaoSplit) {
                let valor = null;

                if (expressaoSplit.indexOf('<') != -1) {
                    if (expressaoSplit.indexOf('=') != -1) {
                        valor = this.buscarValorExpressao(expressaoSplit, '=');
                    } else {
                        valor = this.buscarValorExpressao(expressaoSplit, '<');
                    }
                } else if (expressaoSplit.indexOf('>') != -1) {
                    if (expressaoSplit.indexOf('=') != -1) {
                        valor = this.buscarValorExpressao(expressaoSplit, '=');
                    } else {
                        valor = this.buscarValorExpressao(expressaoSplit, '>');
                    }
                }
                return valor;
            },
            buscarValorExpressao(expressaoSplit, simbolo){
                let valorExpressao = null;

                if (expressaoSplit.indexOf('dias') != -1) {
                    valorExpressao = expressaoSplit.substring(expressaoSplit.indexOf(simbolo) + 1, expressaoSplit.indexOf('dias') + 4).trim();
                } else if (expressaoSplit.indexOf('semanas') != -1) {
                    valorExpressao = expressaoSplit.substring(expressaoSplit.indexOf(simbolo) + 1, expressaoSplit.indexOf('semanas') + 7).trim();
                } else if (expressaoSplit.indexOf('meses') != -1) {
                    valorExpressao = expressaoSplit.substring(expressaoSplit.indexOf(simbolo) + 1, expressaoSplit.indexOf('meses') + 5).trim();
                } else {
                    valorExpressao = expressaoSplit.substring(expressaoSplit.indexOf(simbolo) + 1).trim();
                }

                return valorExpressao;
            },
            remover(){
                this.valorDeReferencia.itens = this.valorDeReferencia.itens.slice(0, -1);
            },
            incluir(){
                this.valorDeReferencia.itens.push({
                    expressao: null,
                    item: this.valorDeReferencia.itens.length + 1,
                    valorFinal: null,
                    valorInicial: null
                });
            },
            salvarValorReferencia(){
                this.salvandoDados = true;

                this.calcularExpressao();

                this.$http.post(`/api/manutencao/valorReferenciaExame`, this.valorDeReferencia)
                        .catch(err => {
                            Object.keys(err.body.errors).forEach(x => {
                                this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                            })
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Erro ao salvar valor de referência',
                                type: 'is-danger',
                                queue: false
                            })
                        })
                        .finally(() => {
                            this.salvandoDados = false;
                            this.$parent.close();
                        })
                        
            },
            calcularExpressao(){
                this.valorDeReferencia.itens?.forEach(item => {
                    item.expressao = "";

                    if(item.sexo != undefined && item.sexo != null && item.sexo != "") {                        
                        if(item.expressao != "") {
                            item.expressao += " and ";
                        }
                        item.expressao += "Sexo = " + item.sexo;
                    }

                    if(item.idadeMaior != undefined && item.idadeMaior != null && item.idadeMaior != "") {
                        if(item.expressao != "") {
                            item.expressao += " and ";
                        }
                        item.expressao += "Idade >= " + item.idadeMaior + " " + item.idadeMaiorPeriodo;
                    }

                    if(item.idadeMenor != undefined && item.idadeMenor != null && item.idadeMenor != "") {
                        if(item.expressao != "") {
                            item.expressao += " and ";
                        }
                        item.expressao += "Idade < " + item.idadeMenor + " " + item.idadeMenorPeriodo;
                    }
                });
            }
        }
    }
</script>