<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('WORKLIST.REALIZARENCAIXE')}}</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-datetimepicker rounded
                                  :placeholder="$t('SISTEMA.SELECIONEDATAHORA')"
                                  icon="calendar-today"
                                  v-model="dataSelecionada"                                  
                                  :datepicker="getConfigDatePicker()"
                                  inline
                                  horizontal-time-picker>
                </b-datetimepicker>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      @click="alterarData">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('ALTERARDATAULTIMAREFEICAO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            agenda: Object,
            desabilitarDia: {
                type: Boolean,
                default: false
            },
            data:{
                type: Date,
                default: new Date()
            }             
        },    
        data() {
            return {
                dataSelecionada: this.data
            }
        },
        methods: {

            getConfigDatePicker(){
                return (this.desabilitarDia) ? { unselectableDaysOfWeek : [0,1,2,3,4,5,6] } : { }                                
            },        
            alterarData() {

                this.$emit('encaixePaciente', this.agenda,this.$moment(this.dataSelecionada).toDate())
                this.$emit('close')
            }
        }
    }
</script>