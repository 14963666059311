<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('LOTESCONTARECEBERLISTAR.PLACEHOLDERPROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="buttons">
                    <router-link to="/financeiro/receber-parcela-lote" class="button is-primary">
                        {{$t('LOTESCONTARECEBERLISTAR.INCLUIR')}}
                    </router-link>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('LOTESCONTARECEBERLISTAR.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('LOTESCONTARECEBERLISTAR.PROXPAGINA')"
                 :aria-previous-label="$t('LOTESCONTARECEBERLISTAR.PAGANTERIOR')"
                 :aria-page-label="$t('LOTESCONTARECEBERLISTAR.PAGINA')"
                 :aria-current-label="$t('LOTESCONTARECEBERLISTAR.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 focusable
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column v-slot="props" field="id" :label="$t('LOTESCONTARECEBERLISTAR.CODIGO')">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="Descricao" :label="$t('LOTESCONTARECEBERLISTAR.DESCRICAO')">
                    {{ props.row.descricao }}
                </b-table-column>

                <b-table-column v-slot="props" field="contaCorrente" :label="$t('LOTESCONTARECEBERLISTAR.CONTACORRENTE')">
                    {{ props.row.contaCorrente ? props.row.contaCorrente.nome : '' }}
                </b-table-column>

                <b-table-column v-slot="props" field="dataInicial" :label="$t('LOTESCONTARECEBERLISTAR.DATAINICIAL')">
                    {{ props.row.dataInicial | data }}
                </b-table-column>                

                <b-table-column v-slot="props" field="dataInicial" :label="$t('LOTESCONTARECEBERLISTAR.DATAFINAL')">
                    {{ props.row.dataFinal | data }}
                </b-table-column>         

                <b-table-column v-slot="props" field="qtdeContas" :label="$t('LOTESCONTARECEBERLISTAR.QTDCONTAS')">
                    {{ props.row.qtdeContas }}
                </b-table-column>          

                <b-table-column v-slot="props" field="valor" :label="$t('LOTESCONTARECEBERLISTAR.VALOR')">
                    {{ props.row.valor | currency }}
                </b-table-column>                                                


                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'receber-parcela-lote', params: { id: props.row.id }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from "@/components/titlebar.vue";
    import moment from 'moment';

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null
            }
        },
        filters: {
            data: (valor) => {
                if (!valor) return null;
            
                return  moment(valor).format('DD/MM/YYYY');
            }
        },
        computed: {
            titleStack() {
                return [this.$t('SIDEBAR.FINANCEIRO'), this.$t('LOTESCONTARECEBERLISTAR.TITULO')];
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        components: {
            titlebar
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/financeiro/ListarReceberParcelaLote?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/financeiro/receber-parcela-lote/${this.id}` })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>