const calcularIdade = {    
    Idade(dataDeNascimento) {
        if(dataDeNascimento > new Date()){
            dataDeNascimento = null;

            this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                    type: 'is-danger',
                    queue: false
                })

            return;
        }

        const today = new Date();
        let m = today.getMonth() - dataDeNascimento.getMonth();
                        
        const dateAge = new Date(Date.now() - dataDeNascimento.getTime()); 
        const age = Math.abs(dateAge.getUTCFullYear() - 1970);
                        
        if(age > 200){
            dataDeNascimento = null;

            this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                    type: 'is-danger',
                    queue: false
                })

            return;
        }

        if (age >= 1) {
            if (age == 1) {
                return ~~(age) + " ano";
            } else {
                return ~~(age) + " anos";
            }
        } else {
            if ((age == 0) && (m < 0)) {
                m += 12;
            }

            if (today.getDate() < dataDeNascimento.getDate()) {
                m--;
            }

            if (m > 0) {
                if (m == 1) {
                    return ~~(m) + " mês";
                } else {
                    return ~~(m) + " meses";
                }
            } else {
                const d = (new Date(today.getFullYear(), today.getMonth(), today.getDate()) - dataDeNascimento) / 86400000;

                if (d > 0) {
                    if (d == 1) {
                        return ~~(d) + " dia";
                    } else {
                        return ~~(d) + " dias";
                    }
                } else {
                    return "";
                }
            }
        }
    }
}

export default calcularIdade;