<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-9">
                            <ValidationProvider rules="required" :name="$t('EXAMEEXTRACAO.NOME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('EXAMEEXTRACAO.NOME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                        <div class="column is-3">       
                            <ValidationProvider rules="required" name="Modelo de etiqueta" v-slot="{ errors, valid }">                     
                                <b-field :label="$t('EXAMEEXTRACAO.MODELETQ')"
                                            :message="errors"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                    <b-select :placeholder="$t('SISTEMA.SELECMODEL')" class="is-fullwidth" v-model="model.modeloEtiqueta">
                                        <option v-for="(item, index) in model.modelos" v-bind:key="index" :value="item">{{item}}</option>
                                    </b-select>
                                </b-field>     
                            </ValidationProvider>                       
                        </div>                        
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            <searchIdName :id.sync="model.exameOrigemId" table="Exame"
                                :label="$t('EXAMEEXTRACAO.EXAMEORIGEM')" />
                        </div>

                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <searchIdName :id.sync="model.exameDestinoId" table="Exame" 
                                :label="$t('EXAMEEXTRACAO.EXAMEDESTINO')" />
                        </div>
                    </div>                    

                </article>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                v-if="isInRole('exameextracao-alterar')"
                                native-type="button"
                                icon-left="check-circle"
                                :loading="loading"
                                @click="onSubmit()">
                        {{$t('SISTEMA.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                         {{$t('SISTEMA.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue';

    export default {
        data() {
            return {
                model: {                    
                    nome: null,
                    modeloEtiqueta: null,
                    exameOrigemId: null,
                    exameDestinoId: null,
                    modelos: [],
                },
                loading: false
            }
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
            this.$http.get('/api/manutencao/exameExtracao?id=' + ((this.$route.params.id) ? this.$route.params.id : 0))
                .then(res => {
                    this.model = res.data;                  
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('EXAMEEXTRACAO.MANUTENCAO'),
                    this.$t('EXAMEEXTRACAO.EXTRACAOEXAME'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.loading = true;

                if(!this.model.nome){
                    this.$buefy.toast.open({
                        duration: 3000,
                        message: "Insira o nome da Regra de Extração de Exame!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                                               
                else if(!this.model.exameDestinoId){
                    this.$buefy.toast.open({
                        message: "Insira o ID do Exame de Destino!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }

                else if(!this.model.exameOrigemId){
                    this.$buefy.toast.open({
                        message: "Insira o ID do Exame de Origem!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }                

                this.$http.post('/api/manutencao/exameExtracao', this.model)
                    .then(() => {
                        this.$route.params.id ? this.$router.back() : this.$router.push({ name: 'examesextracao' })
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('EXAMEEXTRACAO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                        
                        this.loading = false;
                    })
            }
        }
    }
</script>