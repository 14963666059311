<template>
  <div class="modal-card" style="width: 100%;">
    <header class="modal-card-head">
      <p class="modal-card-title">Imprimir comprovante de agendamento</p>
    </header>
    <b-message
      v-for="(erro, index) in erros"
      v-bind:key="index"
      title="Erro"
      type="is-danger"
      aria-close-label="Fechar mensagem"
    >
      {{ erro }}
    </b-message>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(confirmar)">
        <section class="modal-card-body list is-paddingless is-marginless almost-full">
          <article class="tile is-child box">
            <div class="columns">
              <div class="column">
                  <label class="label" v-if="item.modelos.length === 1">
                    Modelo: {{item.modelos[0].nome}}
                  </label>
                  <b-field label="Modelo">
                     <b-select v-model="item.modelo" class="fullwidth" expanded>
                        <option 
                            v-for="modelo in item.modelos"
                            :value="modelo"
                            :key="modelo.id">
                            {{ modelo.nome }}
                        </option>
                    </b-select>
                  </b-field>

              </div>
            </div>
            <div class="columns">
              <div class="column">
                  <b-field :label="variavel.nome" v-for="variavel in item.modelo.variaveis" :key="variavel.nome">
                    <b-input
                        type="text"
                        v-model="variavel.valor"
                        >
                    </b-input>
                  </b-field>
              </div>
            </div>            
          </article>
        </section>

        <footer class="modal-card-foot">
          <button
            class="button is-pulled-right is-success"
            type="submit"
          >
            Confirmar
          </button>

          <button class="button is-pulled-right" type="button" @click="sair()">
            Fechar
          </button>
        </footer>

      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
.almost-full{
  // height: 60vh;
}

.icone-adicionar{
  margin-top: 1.8rem;
  background-color: rgba(#000,0.1);
}
</style>

<script>
import moment from "moment";

export default {
  props: {
    modelos: null
  },  
  components: {
  },
  computed: {},
  data() {
    return {
      item: {
        modelos: [],
        modelo: { variaveis: []},
        variaveis: []
      },
      erros: [],
    };
  },
  watch: {
    modelos(newValue){
      if (newValue){
        this.item.modelos = this.modelos;
        this.parseVariaveis();
      }
    }
  },
  mounted() {
    this.item.modelos = this.modelos ? this.modelos : [];
    this.parseVariaveis();
  },
  methods: {
    parseVariaveis(){
      this.item.modelo = {
        variaveis: []
      };
      if (this.item.modelos?.length){
        this.item.modelo = this.item.modelos[0];
      }
    },
    sair() {
      this.$parent.close();
    },
    confirmar(e) {
      this.$emit('imprimir',this.item.modelo)
      this.$parent.close();
      
    },
    adicionarPaciente(){
      window.open('/manutencao/pacientes/0','_blank');
    }
  },
};
</script>
