<template>
    <div class="buttons has-addons is-right">
        <b-button :title="$t('SOROTECA.AMPLIAR')"
                @click="aplicarZoom(0.1)" size="is-small" icon-left="plus"></b-button>
        <b-button :title="$t('SOROTECA.DIMINUIR')"
                @click="aplicarZoom(-0.1)" size="is-small" icon-left="minus"></b-button>                        
    </div>

</template>

<script>    
    export default {
        components: {},
        props: {
            elemento: null,
            classe: null,
            max:null,
            min:null
        },
              
        methods:{

            aplicarZoom(valor){     
                let zoomAtual = 1.0;
                if(this.elemento){

                    if(this.elemento.style.zoom)
                        zoomAtual = parseFloat(this.elemento.style.zoom);    
                    const novoValor = zoomAtual + valor;
                    
                    if( novoValor <= this.max && novoValor >= this.min  ){
                        this.elemento.style.zoom = novoValor;          
                    }                      
                }else if (this.classe){                    

                    const elements = document.getElementsByClassName(this.classe);                                         
                    for (let i = 0; i < elements.length; i++) {
                        const element = elements[i];
                        if(element.style.zoom)
                            zoomAtual = parseFloat(element.style.zoom);      
                        
                        const novoValor = zoomAtual + valor;                    

                        if( novoValor <= this.max && novoValor >= this.min  ){
                            element.style.zoom = novoValor;          
                        } 
                    }
                   
                }
                

            
            },

        }

    }
</script>
