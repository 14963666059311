<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'fluxoCaixa'" :habilitarBotaoPdf="false" @filtrar="filtrar($event)"></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>
            <div class="panel tile is-ancestor" v-if="fluxoCaixa != null && fluxoCaixa.length > 0">
                <div class="tile is-12 is-centered is-vertical is-parent">
                    <div class="tile is-child box">
                        <div class="panel-heading has-text-centered">
                            Fluxo de Caixa
                        </div>
                        <div class="b-table" style="overflow-x:auto;width:100%">
                            <div class="table-wrapper">
                                <table class="table is-hoverable">
                                    <thead>
                                        <th style="width:1px"></th>
                                        <th>Categorias</th>
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'janeiro'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.JANEIRO')}}   
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'fevereiro'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.FEVEREIRO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'março'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.MARCO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'abril'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.ABRIL')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'maio'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.MAIO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'junho'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.JUNHO')}}
                                                    </span>

                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'julho'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.JULHO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                            
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'agosto'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.AGOSTO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'setembro'))">
                                             <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.SETEMBRO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'outubro'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.OUTUBRO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'novembro'))">
                                             <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.NOVEMBRO')}}  
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                        <th v-if="fluxoCaixa.some(x => x.valores.some(m => m.mes == 'dezembro'))">
                                            <div>
                                                <p class="pl-6">
                                                    <span class="pl-4">
                                                        {{$t('SISTEMA.DEZEMBRO')}}
                                                    </span>
                                                </p>
                                                <small class="pl-4">Previsto</small>
                                                <small class="pl-6">Realizado</small>
                                            </div>
                                        </th>  
                                    </thead>

                                    <tbody v-for="item in fluxoCaixa" :key="item.id">
                                        <tr>
                                            <td  v-if="item.tipo == 0" style="width: 1px;background-color:hsl(141, 71%, 48%)"></td>  
                                            <td v-if="item.tipo == 1" style="width: 1px;background-color:red;"></td>  
                                            <td class="is-clickable" @click="toggleCollapse(item)">
                                                <b-icon
                                                    v-if="item.categoriaFilha != null && item.categoriaFilha.length > 0"
                                                    :icon="item.exibir ? 'menu-right' : 'menu-down'"
                                                >
                                                </b-icon>
                                                {{ item.categoria }}
                                            </td>
                                            <td v-for="(values, index) in item.valores" :key="index"> 
                                                <tr>
                                                    <td>
                                                        {{values.valorPrevisto.toFixed(2) | currency}}
                                                    </td>
                                                    <td class="pl-4">
                                                        {{values.valorRealizado.toFixed(2) | currency}}
                                                    </td>
                                                </tr>
                                            </td>
                                        </tr>
                                        <tr v-for="subItem in item.categoriaFilha" :key="subItem.id" :class="item.exibir ? 'is-hidden' : ''">
                                            <td style="width:1px"></td>
                                            <td class="is-size-7 pl-6 ">{{ subItem.categoria }}</td>
                                            <td v-for="(values, index) in subItem.valores" :key="index">
                                                <div>
                                                    <span class="pl-5 is-size-7 ">
                                                        {{values.valorPrevisto.toFixed(2) | currency}}
                                                    </span>
                                                    <span class="pl-6 is-size-7 ">
                                                        {{values.valorRealizado.toFixed(2) | currency}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <td></td>
                                        <td><b class="pl-3">Total Mês</b></td>
                                        <td v-for="(values, index) in totalMes" :key="index"> 
                                                <div>
                                                    <span class="pl-3">
                                                       <b>{{values.valorPrevisto.toFixed(2) | currency}}</b>
                                                    </span>
                                                    <span class="pl-5">
                                                         <b>{{values.valorRealizado.toFixed(2) | currency}}</b>
                                                    </span>
                                                </div>
                                            </td>
                                    </tbody>
                                </table>
                            </div>
                        </div>                                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/financeiro/filtrosAcompanhamento.vue';

    export default {
        data() {
            return {
                isLoading: false,
                salvandoDados: false,
                fluxoCaixa: null,
                totalMes: null,
                meses: [],
                mesNum: [],
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Fluxo de Caixa'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        methods: {
            filtrar(filtros){
                const params = [
                    `dataFiltro=${filtros.dataFiltro}`,
                    `dataInicial=${this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                ]
                
                if(filtros.unidade?.id != null)
                {
                    params.push(`unidadeId=${filtros.unidade.id}`)
                }
                if(filtros.contaCorrente?.id != null)
                {
                    params.push(`contaCorrenteId=${filtros.contaCorrente.id}`)
                }
                if(filtros.centrosDeResultadoSelecionados.length > 0)
                {
                    params.push('centrosDeResultadoSelecionados=' + filtros.centrosDeResultadoSelecionados.map(item => item.id).join());
                }

              
                this.carregarLista(params);
            },
            carregarLista(params){
                this.isLoading = true;
                this.$http.get(`/api/financeiro/fluxoDeCaixa?${params.join('&')}`)
                    .then(res => {
                        this.isLoading = false;
                        this.meses = [];
                        this.pegarMeses(res.data.fluxoCaixa);
                        this.inserirValoresZerados(res.data)
                    })
                    .catch((error) => {
                        this.loading = false;
                        throw error
                    });
            },
            toggleCollapse(item) {
                item.exibir = !item.exibir;
            },
            pegarMeses(data){
                data.map(lista => {
                    lista.valores.forEach(item => {
                        if(!this.meses.includes(item.mes)){
                            this.meses.push(item.mes)
                        }

                        if(this.mesNum.filter(x => x.mes == item.mes).length == 0){
                            this.mesNum.push({
                                mes: item.mes,
                                mesNum: item.mesNum,
                            })
                        }
                    })
                })
            },
            inserirValoresZerados(data) {
                //ordernar totais
                this.totalMes = data.totalMes.sort((primeiroMes, segundoMes) => {
                    return primeiroMes.mesNum === segundoMes.mesNum ? 0 : primeiroMes.mesNum > segundoMes.mesNum ? 1 : -1;
                }); 

                //Insere nas categorias valores zerados para os meses a onde não existe valor.
                this.fluxoCaixa = data.fluxoCaixa.map(lista => {
                    lista.valores.forEach(valores => {
                            this.meses.filter(mes => mes != valores.mes).forEach(mes => {
                                if(valores.mes != mes && lista.valores.length != this.meses.length && lista.valores.filter(item => item.mes == mes).length == 0) {
                                    
                                    lista.valores.push({
                                        mes: mes,
                                        mesNum: this.mesNum.filter(x => x.mes == mes)[0].mesNum,
                                        valorPrevisto: 0.00,
                                        valorRealizado: 0.00
                                    });
                                }
                            })        


                            lista.valores = lista.valores.sort((primeiroMes, segundoMes) => {
                                return primeiroMes.mesNum === segundoMes.mesNum ? 0 : primeiroMes.mesNum > segundoMes.mesNum ? 1 : -1;
                            });                        
                    });

                      //insere também nas categorias filhas
                        if(lista.categoriaFilha != null) {
                            lista.categoriaFilha.forEach(listaFilhas => {
                                this.meses.forEach(mes => {
                                    listaFilhas.valores.forEach(item => {
                                        if(item.mes != mes && listaFilhas.valores.length != this.meses.length && listaFilhas.valores.filter(item => item.mes == mes).length == 0) {
                                            listaFilhas.valores.push({
                                                mes: mes,
                                                mesNum: this.mesNum.filter(x => x.mes == mes)[0].mesNum,
                                                valorPrevisto: 0.00,
                                                valorRealizado: 0.00
                                            });
                                        }
                                    });

                                    //orderna por mês
                                    listaFilhas.valores = listaFilhas.valores.sort((primeiroMes, segundoMes) => {

                                        return primeiroMes.mesNum === segundoMes.mesNum ? 0 : primeiroMes.mesNum > segundoMes.mesNum ? 1 : -1;
                                    })
                                });               
                            });
                        }
                        

                    return lista;
                });
            }
        }
    }
</script>