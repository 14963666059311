<template>
    <section>
        <b-loading :active.sync="carregando"></b-loading>
        <titlebar :title-stack="titleStack"> </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <label class="label">{{$t('LIBERACAOAUTOCADASTRO.FILTROS')}}</label>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('LIBERACAOAUTOCADASTRO.VERSAOMASC')">
                                        <b-select :placeholder="$t('LIBERACAOAUTOCADASTRO.SELECVERSAO')"
                                                  v-model="mascaraSelecionada"
                                                  @input="campoAlterado"
                                                  expanded>
                                            <option value="">Todas</option>
                                            <option v-for="option in mascaras"
                                                    :value="option"
                                                    :key="getKey(option.versao)">
                                                {{ option.versao }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="Campo" v-if="mascaraSelecionada.campos && mascaraSelecionada.campos.length">
                                        <b-select :placeholder="$t('LIBERACAOAUTOCADASTRO.SELECCAMPO')"
                                                  expanded
                                                  v-model="campoSelecionado"
                                                  @input="campoAlterado">
                                            <option value="">Todos</option>
                                            <option v-for="option in mascaraSelecionada.campos"
                                                    :value="option"
                                                    :key="getKey(option.id)">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-button class="form-botao" type="is-primary" @click="carregarReferencia()" v-if="campoSelecionado.id && (campoSelecionado.valoresDeReferencia && campoSelecionado.valoresDeReferencia.length && campoSelecionado.valoresDeReferencia[0].valoresDeReferencia != null)">
                                       {{$t('LIBERACAOAUTOCADASTRO.CARREGARREF')}} 
                                    </b-button>
                                </div>
                            </div>
                            <div class="columns" v-if="exibirValoresDeReferencia">
                                <article class="tile is-child box" v-if="!valoresDeReferencia.length">
                                    <span class="help is-danger">{{$t('LIBERACAOAUTOCADASTRO.SEMREF')}}</span>
                                </article>
                                <article v-if="valorDeReferenciaTexto">
                                    <pre>{{valorDeReferenciaTexto}}</pre>
                                </article>
                                <article class="tile is-child box" v-if="!valorDeReferenciaTexto && valoresDeReferencia.length">
                                    <b-field label="">
                                        <b-table :data="valoresDeReferencia"
                                                 class="table"
                                                 striped
                                                 hoverable>
                                            <template  v-if="exibirValoresDeReferencia">
                                                <b-table-column  v-slot="props" field="expressao" :label="$t('LIBERACAOAUTOCADASTRO.EXPRESSAO')">
                                                    {{ props.row.expressao }}
                                                </b-table-column>
                                                <b-table-column  v-slot="props" field="valorInicial" :label="$t('LIBERACAOAUTOCADASTRO.VALINICIAL')">
                                                    {{ props.row.valorInicial }}
                                                </b-table-column>
                                                <b-table-column  v-slot="props" field="valorFinal" :label="$t('LIBERACAOAUTOCADASTRO.VALFINAL')">
                                                    {{ props.row.valorFinal }}
                                                </b-table-column>
                                            </template>
                                        </b-table>
                                    </b-field>
                                    <b-button class="form-botao" type="is-primary" @click="importarValoresReferencia()">
                                       {{$t('LIBERACAOAUTOCADASTRO.IMPORTAR')}}
                                    </b-button>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <liberacao-automatica :label="$t('LIBERACAOAUTOCADASTRO.LIBAUTOMATICA')"
                                                  :items.sync="model.lista"
                                                  :mascaras="mascaras"
                                                  :sexo="opcoesSexo"
                                                  :campos="getCampos(mascaraSelecionada.campos)"
                                                  @historico="exibirHistorico($event)"
                                                  :filtro-mascara="mascaraSelecionada"
                                                  :filtro-campo="campoSelecionado.id"
                                                  :apelido="apelido"
                                                  :novosItems="novosItems"></liberacao-automatica>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('LIBERACAOAUTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back()">
                                {{$t('LIBERACAOAUTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss" scoped>
    .form-botao {
        @media (min-width: 576px) {
            margin-top: 1.5rem;
        }
    }
</style>

<script>
    import { mapGetters, mapState } from "vuex";
    import liberacaoAutomatica from "@/components/manutencao/liberacaoAutomatica.vue";
    import titlebar from "@/components/titlebar.vue";
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";

    export default {
        data() {
            return {
                model: {
                    lista: [],
                    campos: [],
                    mascaras: [],
                },
                apelido: null,
                salvandoDados: false,
                mascaras: [],
                opcoesSexo: [
                    { value: null, text: "Ambos" },
                    { value: "M", text: "Masculino" },
                    { value: "F", text: "Feminino" },
                ],
                mascaraSelecionada: {
                    campos: []
                },
                campoSelecionado: { id: null },
                exibirValoresDeReferencia: false,
                valoresDeReferencia: [],
                valorDeReferenciaTexto: null,
                campos: [],
                novosItems: [],
                carregando: false
            };
        },
        components: {
            titlebar,
            liberacaoAutomatica
        },
        mounted() {
            this.apelido = this.$route.params?.id;
        },
        created() {
            if (this.$route.params.id) {
                this.carregando = true;
                this.$http
                    .get(
                        "/api/manutencao/liberacaoAutomaticaAgrupadaExame?apelido=" +
                        this.$route.params.id
                    ).catch(e => {
                        this.carregando = false;
                    })
                    .then((res) => {
                        if (!res.data?.exame) {
                            this.carregando = false;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message:  this.$t('LIBERACAOAUTOLISTA.NAOENCONTRADO'),
                                type: "is-danger",
                                queue: false,
                            });
                            window.history.back();
                            return;
                        }
                        this.model = res.data;
                        try {
                            this.mascaras = res.data.mascaras.sort((a, b) => (a.versao > b.versao) ? 1 : -1);
                        } catch (e) {
                            this.mascaras = res.data.mascaras;
                        }

                        this.campos = res.data.campos.length ? res.data.campos[0] : [];
                        this.carregando = false;
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('LIBERACAOAUTOLISTA.MANUTENCAO'),
                    this.$t('LIBERACAOAUTOLISTA.LIBAUTO'),
                    this.$route.params?.id
                ];
            },
            ...mapState(["unidadeId", "config", "integradores"]),
            ...mapGetters(["isInRole"]),
        },
        watch: {},
        methods: {
            onSubmit() {

                this.salvandoDados = true;

                const newItem = {
                    lista: this.model?.lista?.map(l => {

                        let idProximaLiberacao = l.proximaLiberacao?.id;
                        if (l.proximaLiberacao?.examesCorrelacionados?.length && (idProximaLiberacao == 0 || idProximaLiberacao == null)) {

                            if (l.proximaLiberacao.examesCorrelacionados[0].id) {
                                idProximaLiberacao = l.proximaLiberacao.examesCorrelacionados[0]?.id;
                            } else {
                                idProximaLiberacao = l.proximaLiberacao.examesCorrelacionados[0]?.idSelecionado;
                            }
                        }

                        if (idProximaLiberacao == null) idProximaLiberacao = 0;

                        return {
                            ...l,
                            idadeMaiorQue: !l.idadeMaiorQue ? 0 : l.idadeMaiorQue,
                            idadeMenorIgualQue: !l.idadeMenorIgualQue ? 0 : l.idadeMenorIgualQue,
                            valorMinimo: !l.valorMinimo ? 0 : l.valorMinimo.toString().replace('.', ',') ,
                            valorMaximo: !l.valorMaximo ? 0 : l.valorMaximo,
                            proximaLiberacao: {
                                id: idProximaLiberacao,
                            }
                        }
                    })
                };


                this.$http
                    .post("/api/manutencao/LiberacaoAutomaticaExame", newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch((err) => {
                        console.error(err);
                        Object.keys(err.body.errors).forEach((x) => {
                            this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                        });
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('LIBERACAOAUTOLISTA.ERROSALVAR') + err.body.title,
                            type: "is-danger",
                            queue: false,
                        });
                    });
                    
            },
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: 'LiberacaoAutomaticaExame',
                        chave: item.id,
                        toggle: true
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            getKey(valor) {
                return `${valor}_${Math.random()}`;
            },
            carregarReferencia() {
                this.exibirValoresDeReferencia = true;
                this.valorDeReferenciaTexto = this.campoSelecionado?.valoresDeReferencia?.length ? this.campoSelecionado?.valoresDeReferencia[0]?.texto : null;
                this.valoresDeReferencia = this.campoSelecionado?.valoresDeReferencia?.length ? this.campoSelecionado?.valoresDeReferencia[0]?.valoresDeReferencia : [];
                if (!this.valoresDeReferencia?.length) {
                    this.valoresDeReferencia = [];
                }
            },
            importarValoresReferencia() {
                this.novosItems = [];
                const mascara = this.mascaraSelecionada;
                try {
                    this.valoresDeReferencia?.forEach((referencia, index) => {
                        let expressao = referencia.expressao?.toLowerCase();

                        if (!expressao) {
                            expressao = '';
                        }

                        let sexo = null;
                        //Para cada valor de referência, descubro a idade, sexo e cliidade para obter o valor dos campos
                        if (expressao?.indexOf('sexo') >= 0) {
                            sexo = expressao?.substring(expressao?.indexOf("sexo") + 7, expressao?.indexOf("sexo") + 9).toUpperCase().trim();
                        } else if (expressao?.indexOf('clisexo') >= 0) {
                            sexo = expressao?.substring(expressao?.indexOf("clisexo") + 10, expressao?.indexOf("clisexo") + 12).toUpperCase().trim();
                        }

                        if (expressao?.indexOf("idade") != -1) {
                            const expressaoSplit = expressao?.split('and');
                            let i = 0;
                            const valores = [];
                            while (expressaoSplit.length > i) {
                                if (expressaoSplit[i].indexOf("idade") != -1) {
                                    valores.push(this.calculaExpressao(expressaoSplit[i]?.toLowerCase()));
                                }
                                i++;
                            }
                            this.adicionarRegistroLiberacao(valores[0], valores[1] ? valores[1] : 0, mascara, sexo, this.campoSelecionado, referencia.valorInicial, referencia.valorFinal);

                        } else if (expressao?.indexOf("cliidade") != -1) {
                            const expressaoSplit = expressao?.split('and');
                            const valores = [];
                            let i = 0;
                            while (expressaoSplit.length > i) {
                                if (expressaoSplit[i].indexOf("cliidade") != -1) {
                                    valores.push(this.calculaExpressao(expressaoSplit[i]));
                                }
                                i++;
                            }
                            this.adicionarRegistroLiberacao(valores[0], valores[1] ? valores[1] : 0, mascara, sexo, this.campoSelecionado, referencia.valorInicial, referencia.valorFinal);
                        } else {
                            //Caso não tenha idade, pode ter o sexo ou apenas os valores. Devo adicionar
                            this.adicionarRegistroLiberacao(0, 0, mascara, sexo, this.campoSelecionado, referencia.valorInicial, referencia.valorFinal);
                        }
                    })

                    setTimeout(() => {
                        // this.exibirValoresDeReferencia = false;
                        // this.valoresDeReferencia = [];
                    }, 100);
                } catch (ex) {
                    console.error(ex);                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('LIBERACAOAUTOLISTA.ERROREGISTRO'),
                        type: "is-danger",
                        queue: false,
                    });
                }

            },
            calculaExpressao(expressaoSplit) {

                let valorObtido = null;

                if (expressaoSplit.indexOf('<') != -1) {
                    if (expressaoSplit.indexOf('=') != -1) {
                        //converte para dias
                        if (expressaoSplit.indexOf('dias') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('dias')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'dias');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('semanas') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('semanas')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'semanas');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('meses') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('meses')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'meses');

                            valorObtido = valor;

                        } else {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, null);

                            valorObtido = valor;
                        }

                    } else {
                        if (expressaoSplit.indexOf('dias') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('<') + 1, expressaoSplit.indexOf('dias')).trim();

                            valor = this.converteIdade(parseInt(valor), 'dias');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('semanas') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('<') + 1, expressaoSplit.indexOf('semanas')).trim();

                            valor = this.converteIdade(parseInt(valor), 'semanas');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('meses') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('<') + 1, expressaoSplit.indexOf('meses')).trim();

                            valor = this.converteIdade(parseInt(valor), 'meses');

                            valorObtido = valor;

                        } else {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('<') + 1).trim();

                            valor = this.converteIdade(parseInt(valor), null);

                            valorObtido = valor;
                        }
                    }
                } else if (expressaoSplit.indexOf('>') != -1) {
                    if (expressaoSplit.indexOf('=') != -1) {
                        if (expressaoSplit.indexOf('dias') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('dias')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'dias');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('semanas') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('semanas')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'semanas');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('meses') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1, expressaoSplit.indexOf('meses')).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, 'meses');

                            valorObtido = valor;

                        } else {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('=') + 1).trim();

                            valor = this.converteIdade(parseInt(valor) - 1, null);

                            valorObtido = valor;
                        }
                    } else {
                        if (expressaoSplit.indexOf('dias') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('>') + 1, expressaoSplit.indexOf('dias')).trim();

                            valor = this.converteIdade(parseInt(valor), 'dias');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('semanas') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('>') + 1, expressaoSplit.indexOf('semanas')).trim();

                            valor = this.converteIdade(parseInt(valor), 'semanas');

                            valorObtido = valor;

                        } else if (expressaoSplit.indexOf('meses') != -1) {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('>') + 1, expressaoSplit.indexOf('meses')).trim();

                            valor = this.converteIdade(parseInt(valor), 'meses');

                            valorObtido = valor;

                        } else {
                            let valor = expressaoSplit.substring(expressaoSplit.indexOf('>') + 1).trim();

                            valor = this.converteIdade(parseInt(valor), null);

                            valorObtido = valor;
                        }
                    }
                }
                return valorObtido;
            },
            converteIdade(valor, periodo) {
                if (periodo != null) {
                    if (periodo == 'dias') {
                        valor = valor / 365;
                    } else if (periodo == 'semanas') {
                        valor = valor / 52;
                    } else {
                        valor = valor / 12;
                    }
                }

                return parseFloat(valor.toFixed(3)).toString().replace('.', ',');
            },
            adicionarRegistroLiberacao(valorMaiorQue, valorMenorQue, mascara, sexo, campo, valorInicial, valorFinal) {
                const novoItem = {};
                novoItem.mascara = mascara;
                novoItem.idadeMaiorQue = parseFloat(valorMaiorQue?.toString()?.replace(',', '.'));
                novoItem.idadeMenorIgualQue = parseFloat(valorMenorQue?.toString()?.replace(',', '.'));
                novoItem.campo = campo;
                novoItem.habilitada = true;
                novoItem.valorMinimo = valorInicial ? valorInicial.toString()?.replace('.', ',') : 0;
                novoItem.valorMaximo = valorFinal ? parseFloat(valorFinal.toString()?.replace(',', '.')) : 0;
                novoItem.proximaLiberacao = { id: 0, examesCorrelacionados: [] };
                novoItem.primeiroCampo = true;
                novoItem.ultimoCampo = true;
                novoItem.sexo = sexo;
                novoItem.show = true;
                this.novosItems.push(novoItem);
            },
            campoAlterado() {
                this.exibirValoresDeReferencia = false;
                this.valoresDeReferencia = [];
            },
            getCampos(campos) {
                if (campos?.length) return campos;
                const camposSet = new Set();
                this.mascaras?.forEach(m => m.campos?.forEach(c => camposSet.add(c)));

                return Array.from(camposSet);
            }
        }
    }



</script>
