<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('ORCAMENTOLISTA.PROCURARNOME')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="is-flex is-justified-between">
                    <router-link
                        v-if="isInRole('orcamento-alterar')"
                        :to="{ name: 'orcamento' }"
                        class="button is-primary"
                        >
                        {{$t('ORCAMENTOLISTA.INCLUIR')}}
                    </router-link>
                </div>                
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('ORCAMENTOLISTA.CODIGO')"
                                v-model="id"
                                type="number"
                                icon-right="edit"
                                icon-right-clickable
                                @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('ORCAMENTOLISTA.PROXPAGINA')"
                 :aria-previous-label="$t('ORCAMENTOLISTA.PAGINAANTERIOR')"
                 :aria-page-label="$t('ORCAMENTOLISTA.PAGINA')"
                 :aria-current-label="$t('ORCAMENTOLISTA.PAGINAATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="id" :label="$t('ORCAMENTOLISTA.ID')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column  v-slot="props" field="convenioNome" :label="$t('SISTEMA.CONVENIO')" sortable>
                    {{ props.row.convenioNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="pacienteNome" :label="$t('SISTEMA.PACIENTE')" sortable>
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="funcionarioNome" :label="$t('ORCAMENTOLISTA.FUNCIONARIO')" sortable>
                    {{ props.row.funcionarioNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="emissao" :label="$t('ORCAMENTOLISTA.EMISSAO')" sortable>
                    {{ $moment(props.row.emissao).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column  v-slot="props" field="validade" :label="$t('ORCAMENTOLISTA.VALIDADE')" sortable>
                    {{ $moment(props.row.validade).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column  v-slot="props" field="valorTotal" :label="$t('ORCAMENTOLISTA.VALOR')" sortable>
                    {{ formatPrice(props.row.valorTotal) }}
                </b-table-column>

                <b-table-column  v-slot="props" field="expirado" :label="$t('ORCAMENTOLISTA.EXPIRADO')">
                    <b-icon v-if="new Date(props.row.validade) < dataAtual && !props.row.possuiGuia" type="is-danger" icon="close-circle" title="Expirado"></b-icon>
                </b-table-column>


                <b-table-column  v-slot="props" field="" label="PDF">
                    <b-button icon-left="eye" class="is-success center"  @click.prevent.stop="download(props.row.id)"></b-button>
                </b-table-column>                 

                <b-table-column  v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'orcamento', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('ORCAMENTOLISTA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

               

            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,
                dataAtual: new Date()
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ORCAMENTOLISTA.ATENDIMENTO'),
                    this.$t('ORCAMENTOLISTA.ORCAMENTOS')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/atendimento/listarorcamentos?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({ name: 'orcamento', params: { id: this.selecionado.id } })
            },
            abrirCadastroInput() {
                this.$router.push({ name: 'orcamento', params: { id: this.id } })
            },
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            download(id){
                const url = `/api/atendimento/ImprimeOrcamento?id=${id}`;
                window.open(url, '_blank');
                
            }            
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>