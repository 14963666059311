<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-table :data="model" class="table-fix"
                 :loading="loading"
                 striped
                 hoverable
                 backend-pagination
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template >
                <b-table-column  v-slot="props" field="guiaId" label="Guia">
                    <router-link :to="'/atendimento/guia/'+props.row.guiaId" class="is-primary is-small is-pulled-right">
                        {{ props.row.guiaId }}
                    </router-link>
                </b-table-column>

                <b-table-column  v-slot="props" field="Paciente" label="Paciente">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Convenio" :label="$t('MENUANALISE.CONVENIO')">
                    {{ props.row.convenio }}
                </b-table-column>


                <b-table-column  v-slot="props" field="Setor" :label="$t('MENUANALISE.SETOR')">
                    {{ props.row.setor }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Exame" :label="$t('MENUANALISE.EXAME')">
                    <b class="tag is-default">
                        {{ props.row.apelido }}
                    </b>
                </b-table-column>

                <b-table-column  v-slot="props" field="" label="">
                    {{ props.row.bloqueadoEm |datahora }} por <b>{{props.row.bloqueadoPor}}</b>
                </b-table-column>

                <b-table-column  v-slot="props" field="Motivo" label="Motivo">
                    {{ props.row.motivo }}
                </b-table-column>


            </template>
        </b-table>
    </section>
</template>

<style scoped>
.table-fix {
    margin-top: 1rem;
}

.is-default {
    background-color: #777;
    color: white;
}
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import moment from 'moment';

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null
            }
        },
        filters: {
            datahora: (valor) => {
                if (!valor) return '';

                return moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            titleStack() {
                return [
                    'Análise',
                    this.$t('MENUANALISE.EXAMESBLOQUEADOSTITULO')
                ]
            },                 
        },
        components: {
            titlebar
        },
        methods: {
           loadAsyncData() {
               const params = [
                    `unidadeId=${this.$route?.query?.unidadeId}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/analitico/examesBloqueados?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            }         
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>