<template>
    <div class="columns is-centered">
        <div class="column is-4">
            <div class="panel">
                <p class="panel-heading is-info">
                    Alterar senha
                </p>
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(alterar)">
                        <div class="panel-block">
                            <div class="columns">
                                <b-field label="Senha atual">
                                    <b-input type="password"
                                             v-model="senhaAtual"
                                             placeholder="Senha atual"
                                             required>
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="panel-block">
                            <b-field label="Nova senha">
                                <b-input type="password"
                                         v-model="novaSenha"
                                         placeholder="Nova senha"
                                         required>
                                </b-input>
                            </b-field>
                        </div>
                        <div class="panel-block">
                            <b-field label="Confirme a nova senha">
                                <b-input type="password"
                                         v-model="confirmandoNovaSenha"
                                         placeholder="Confirme a nova senha"
                                         required>
                                </b-input>
                            </b-field>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-primary"
                                      native-type="submit"
                                      class="is-fullwidth">
                                Alterar
                            </b-button>
                            <a @click="$router.back();" class="button is-danger is-fullwidth">
                                Cancelar
                            </a>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                senhaAtual: null,
                novaSenha: null,
                confirmandoNovaSenha: null
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ]),            
            titleStack() {
                return [
                    'Gerenciar',
                    this.usuario.nome
                ]
            }
        },
        methods:{
            alterar(){

                const formData = new FormData();

                formData.append("OldPassword", this.senhaAtual);
                formData.append("NewPassword", this.novaSenha);
                formData.append("ConfirmPassword", this.confirmandoNovaSenha);

                this.$http.post('/api/auth/manage', formData)
                    .then(() => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Senha alterada com sucesso!',
                            type: 'is-success'
                        })

                        this.$router.back();
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger'
                        })
                    })
            }
        }
    }

</script>