<!--USAR ESSE COMPONENT COMO PADRÃO PARA TIPOS DE MODAIS DE SELEÇÃO DE MOTIVO.-->
<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{componentPhrases.remover}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <p>{{componentPhrases.remover}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoExame"
                            type="is-danger">
                {{ erroCancelamentoExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="componentPhrases.exames">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.apelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="componentPhrases.motivos">
                    </b-field>
                </p>

                <div class="panel-block">
                    <b-select v-model="motivoRetificacaoSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarDesvincular">{{componentPhrases.confirmar}}</b-button>
            <b-button @click="$emit('close')">{{componentPhrases.cancelar}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            id: Number,
            guiaId: Number,
            selecionado: Array,
            validado: Boolean,
        },
        data() {
            return {
                procurarMotivosRetificacao: '',
                listaMotivosRetificacao: [],
                motivoRetificacaoSelecionado: [],
                erroCancelamentoExame: null,
                //UTILIZAR O COMPONENT PHRASES PARA DEFINIR QUAL TEXTO DO I18N SERÁ ASSUMIDO.
                componentPhrases: {
                    remover: this.$t('MODALREMOVERVINCULO.REMOVER'),
                    notificacao: this.$t('MODALREMOVERVINCULO.NOTIFICAÇÃO'),
                    exames: this.$t('MODALREMOVERVINCULO.EXAMES'),
                    motivos: this.$t('MODALREMOVERVINCULO.MOTIVOS'),
                    confirmar: this.$t('MODALREMOVERVINCULO.CONFIRMARDESVINCULAR'),
                    cancelar: this.$t('MODALREMOVERVINCULO.CANCELAR')
                }
            }
        },
        computed: {
            filteredMotivosCancelamentoExame() {
                return this.procurarMotivosRetificacao
                    ? this.listaMotivosRetificacao.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosRetificacao.toUpperCase()) > -1)
                    : this.listaMotivosRetificacao
            },
        },
        mounted() {
            this.loadListaMotivosRetificacao()
        },
        methods: {
            loadListaMotivosRetificacao() {
                this.$http.get('/api/search/MotivosDesvincular')
                    .then(m => {
                        this.listaMotivosRetificacao = m.data
                    })
            },
            confirmarDesvincular() {
                this.motivoRetificacaoSelecionado = [this.motivoRetificacaoSelecionado];

                this.$http.post('/api/analitico/DesvincularEstudo', {
                    estudoId: this.id,
                    guiaId: this.guiaId,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoRetificacaoSelecionado,
                    validado: this.validado,
                })
                .then((res) => {
                    if (res.body.erro) {
                        this.erroCancelamentoExame = res.body.erro
                    } else {
                        this.$emit('close')
                        this.$forceUpdate();
                        this.$router.go(0)
                    }
                })
            },
        }
    }
</script>