<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('REDUCAOACRESCIMOLISTA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="buttons">                       
                    <router-link v-if="isInRole('reducaoacrescimo-alterar')" :to="{ name: 'reducaoacrescimo' }" class="button is-primary">
                        {{$t('REDUCAOACRESCIMOLISTA.INCLUIR')}}
                    </router-link>
                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('REDUCAOACRESCIMOLISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>

        <b-table :data="model.lista"
                :loading="loading"
                paginated
                striped
                hoverable
                backend-pagination
                :total="total"
                @page-change="onPageChange"
                :aria-next-label="$t('TABPRECOLISTA.PROXPAGINA')"
                :aria-previous-label="$t('TABPRECOLISTA.PAGANTERIOR')"
                :aria-page-label="$t('TABPRECOLISTA.PAGINA')"
                :aria-current-label="$t('TABPRECOLISTA.PAGATUAL')"
                backend-sorting
                :default-sort-direction="defaultOrdenar"
                :default-sort="[ordenarPor, ordenar]"
                sort-icon-size="is-small"
                 @sort="onSort">
            <template>
                <b-table-column  v-slot="props" field="id" :label="$t('TABPRECOLISTA.CODIGO')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Nome" :label="$t('TABPRECOLISTA.NOME')" width="80%" sortable>
                    {{ props.row.nome }}
                </b-table-column>

                <!-- <b-table-column  v-slot="props" field="Tipo" :label="$t('TABPRECOLISTA.TIPO')" sortable>
                    {{ props.row.tipo }}
                </b-table-column> -->

                <b-table-column  v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'reducaoacrescimo', params: { id: props.row.id }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

            </template>
        </b-table>

    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    
    export default {
        data() {
            return {
                model: [],
                loading: false,
                total: 0,
                defaultOrdenar: 'asc',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                page: 1,
                perPage: 20,
                procurar: this.$route.query.procurar ?? ""
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REDUCAOACRESCIMOCADASTRO.MANUTENCAO'),
                    this.$t('REDUCAOACRESCIMOCADASTRO.REDUCAOACRESCIMO'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/reducoesacrescimos?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/manutencao/reducaoacrescimo/${this.id}` })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>