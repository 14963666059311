<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('ALTERACAOSTATUSAMOSTRA.ALTERACAOSTATUS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('ALTERACAOSTATUSAMOSTRA.ATENCAO')}}</h5>
                <p>{{$t('ALTERACAOSTATUSAMOSTRA.MENSAGEM')}}</p>
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('ALTERACAOSTATUSAMOSTRA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoAmostra"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoAmostra = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoAmostraSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoAmostra" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                      @click="confirmarAlteracaoStatusAmostra">{{$t('ALTERACAOSTATUSAMOSTRA.CONFIRMARALTERACAO')}}</b-button>
            <b-button @click="cancelar">{{$t('ALTERACAOSTATUSAMOSTRA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            amostra: Object,
            amostras: Array,
            statusLote: Boolean,
        },
        data() {
            return {
                procurarMotivosCancelamentoAmostra: '',
                listaMotivosCancelamentoAmostra: [],
                motivoCancelamentoAmostraSelecionado: []                
            }
        },
        computed: {
            filteredMotivosCancelamentoAmostra() {
                return this.procurarMotivosCancelamentoAmostra
                    ? this.listaMotivosCancelamentoAmostra
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoAmostra.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoAmostra
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoAmostra()
        },
        methods: {
            loadListaMotivosCancelamentoAmostra() {
                this.$http.get('/api/search/MotivosAlteracaoStatusAmostra')
                    .then(m => {
                        this.listaMotivosCancelamentoAmostra = m.data
                    })
            },
            confirmarAlteracaoStatusAmostra() {
                    const ids = [];

                    if(this.motivoCancelamentoAmostraSelecionado == null || this.motivoCancelamentoAmostraSelecionado == '' || this.motivoCancelamentoAmostraSelecionado == undefined){
                        this.$buefy.dialog.alert({
                            title: this.$t('ALTERACAOSTATUSAMOSTRA.MOTIVOOBRIGATORIO'),
                            message: this.$t('ALTERACAOSTATUSAMOSTRA.MENSAGEMMOTIVOOBRIGATORIO'),
                            type: 'is-warning',
                            hasIcon: true
                        });
                        return;
                    }


                    if(this.statusLote){
                        this.amostras.forEach(x => ids.push(x.amostraId));
                    }
                    
                    this.$http.post('/api/atendimento/AlteracaoStatusAmostra', {                    
                        id: this.amostra.amostraId,
                        motivoIds: this.motivoCancelamentoAmostraSelecionado,
                        amostraIds: ids
                    })
                    .then((res) => {                            
                        this.$emit('close')                        
                    })
            },
            cancelar(){
                this.$emit('cancelarAlteracaoStatus',this.amostra,this.statusLote);
                this.$emit('close')
            }
            
        }
    }
</script>