<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns" v-if="model.mensagensErros">
                                <div class="column">
                                    <div class="panel-heading has-text-centered button is-danger is-fullwidth">
                                        Erros de Geração
                                    </div>
                                    <div class="panel-block" v-for="msg in model.mensagensErros" :key="msg">
                                        {{msg}}
                                    </div>
                                </div>                            
                            </div>    
                            <div class="columns" v-if="model.mensagensAvisos">
                                <div class="column">
                                    <div class="panel-heading has-text-centered  button is-warning is-fullwidth">
                                        Avisos de Geração
                                    </div>
                                    <div class="panel-block" v-for="msg in model.mensagensAvisos" :key="msg">
                                        {{msg}}
                                    </div>
                                </div>                            
                            </div> 
                            <div class="columns">
                                <div class="column" v-if="model.xmls != null && model.xmls.length">
                                    <div class="panel-heading has-text-centered  button is-primary is-fullwidth">
                                        XMLs Gerados
                                    </div>
                                    <div>
                                        <div v-for="(item,index) in model.xmls" :key="index" class="columns">
                                            <div class="tile is-ancestor">
                                                <div class="tile is-parent is-vertical is-12">
                                                    <article class="tile is-child box">
                                                        <div class="columns">
                                                            <div class="column">
                                                                <b-field label="Nome Arquivo">
                                                                    <b-input type="text" v-model="item.nomeArquivo"></b-input>
                                                                </b-field>
                                                            </div>
                                                        </div>
                                                        <div class="columns">
                                                            <div class="column">
                                                                <b-field label="Hash">
                                                                    <b-input type="text" v-model="item.hash"></b-input>
                                                                </b-field>
                                                            </div>
                                                            <div class="column">
                                                                <b-field label="Sequencial">
                                                                    <b-input type="text" v-model="item.sequencialInterno"></b-input>
                                                                </b-field>
                                                            </div>
                                                            <div class="column">
                                                                <b-field label="Hora Registro">
                                                                    <b-input type="text" v-model="item.horaRegistroTransacao" readonly></b-input>
                                                                </b-field>
                                                            </div>
                                                        </div>
                                                        <div class="columns">
                                                            <div class="column">
                                                                <b-field label="Versao">
                                                                    <b-input type="text" v-model="item.versao"></b-input>
                                                                </b-field>
                                                            </div>
                                                            <div class="column">
                                                                <b-field label="Código do Prestador na Operadora">
                                                                    <b-input type="text" v-model="item.codigoPrestadorNaOperadora"></b-input>
                                                                </b-field>
                                                            </div>
                                                            <div class="column">
                                                                <b-field label="Número do Lote">
                                                                    <b-input type="text" v-model="item.numeroDoLote"></b-input>
                                                                </b-field>
                                                            </div>
                                                        </div>
                                                         <div class="columns">
                                                            <div class="column">
                                                                <b-field label="Equipe SADT">
                                                                    <searchIdName :id.sync="funcionarioId" table="funcionario" />
                                                                </b-field>
                                                            </div>
                                                        </div>

                                                        <div class="columns">
                                                            <div class="column">
                                                                <b-button type="is-primary" @click="alterarArquivo(item)">AlterarArquivo</b-button>
                                                            </div>
                                                            <div class="column">
                                                                <b-button type="is-success" @click="downloadArquivo(item.xml, item.nomeArquivo, index)">Fazer Download</b-button>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>                                                                                       
                        </article>
                    </div>                
                </div>
            </form>
            <br />
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type=""
                                      native-type="button"
                                      @click="$router.back();">
                                Voltar
                            </b-button>
                        </p>
                    </div>
                </nav>            
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                model: {
                    seq: null,
                    mensagensAvisos: [],
                    mensagensErros: [],
                    xmls:[]
                },
                funcionarioId: null,
                salvandoDados: false,
                isLoading: false
            };
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
            this.isLoading = true;
            const convenioPadrao = this.$route.params.temConvenioPadrao ? this.$route.params.temConvenioPadrao : false;
            this.$http.get(`/api/financeiro/xmltiss?receberId=${this.$route.params.receberId}&versao=${this.$route.params.versao}&temConvenioPadrao=${convenioPadrao}`)
                .then(res => {
                    this.model = res.data;                      
                    this.isLoading = false;
                })
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'XMLTiss',
                    this.$route.params.receberId
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            alterarArquivo(item) {
                item.alterar = true;
                this.model = {};
                
                let url = '/api/financeiro/xmltiss?receberId=' + this.$route.params.receberId;

                if(item.nomeArquivo)
                    url += '&nomeArquivo=' + item.nomeArquivo;
                if(item.hash)
                    url += '&hash=' + item.hash;
                if(item.sequencialInterno)
                    url += '&sequencialInterno=' + item.sequencialInterno;
                if(item.versao)
                    url += '&versao=' + item.versao;
                if(item.codigoPrestadorNaOperadora)
                    url += '&codigoPrestadorNaOperadora=' + item.codigoPrestadorNaOperadora;
                if(item.numeroDoLote)
                    url += '&numeroDoLote=' + item.numeroDoLote;
                if(this.funcionarioId != null)
                    url += '&funEquipeSadt=' + this.funcionarioId;


                this.isLoading = true;
                this.$http.get(url)
                    .then(res => {
                        this.model = res.data; 
                        this.isLoading= false;                     
                    })
            },
            onSubmit() {
                const newItem = Object.assign({}, this.model);          

                this.isLoading = true;
                this.$http.post('/api/financeiro/receber', newItem)
                    .then(() => {
                        this.$router.push({ name: 'financeiro' });
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar',
                            type: 'is-danger',
                            queue: false
                        });
                        this.isLoading = false;
                    })
            },
            downloadArquivo(item, nomeDoArquivo) {
                const link = document.createElement('a');
                const mimeType = 'text/xml';

                document.body.appendChild(link);

                link.download = nomeDoArquivo;
                link.href = 'data:' + mimeType + ';charset=utf-8,' + encodeURIComponent(item);

                link.click();
            }
        }
    }
</script>