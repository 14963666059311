<template>
    <section>
        
        <b-modal :active.sync="isFiltroModalActive" full-screen>
            <filtros :tipo="'Local'" @filtrar="filtrar($event)" @fechar="fecharModal()"></filtros>
        </b-modal>

        <pesquisas class="mb-2" :pesquisas="pesquisas" :tipo="'Local'"></pesquisas>
        
        <div v-if="isLoading">
            <div class="box">
                <div class="panel">
                    <div class="panel-block columns is-multiline is-mobile">
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                </div>
                            </div>
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <b-skeleton width="80%" animated></b-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <b-skeleton width="80%" animated></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <guias v-if="model != null" @pageChange="pageChange($event)" :lista.sync="model.guias" :tipo="'Local'" :page="page" 
                @filtrar="filtrarModal()" :pageCount="pageCount"
                 @ordenar="ordenar($event)" :ordenar.sync="filter.ordenarPor"></guias>
        </div>
    </section> 
</template>

<script>
    import { mapActions, mapState } from 'vuex'    
    import pesquisas from '@/components/portal/pesquisas.vue'
    import filtros from '@/components/portal/filtros.vue'
    import guias from '@/components/portal/guias.vue'

    export default {
        data(){
            return {
                model: null,
                page: 1,
                pageCount: null,
                pesquisas: [],
                total: null,
                filter: {
                    ordenarPor: 'emissao'
                },
                isLoading: false,
                isFiltroModalActive: false
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {            
            pesquisas,
            filtros,
            guias
        },
        methods: {
            carregarLista(filtrosUrl){
                this.isLoading = true;
                this.$http.get('/api/portal/l' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;
                        this.page = this.model.page;
                        this.pageCount = this.model.pageCount;
                        this.total = this.pageCount * 10;
                        this.isLoading = false;
                    });
            },
            carregarPesquisas(){
                this.$http.get('/api/portal/LocalPesquisasSatisfacao')
                    .then(res => {
                        this.pesquisas = res.data;
                    });
            },
            filtrar(filtros){
                filtros.ordenarPor = this.filter.ordenarPor;

                this.filter = filtros;
                
                let filtrosUrl = '?page=' + this.page;

                if(filtros.filtrarPor){
                    filtrosUrl += '&filtrarPor=' + filtros.filtrarPor;
                }

                if(filtros.ordenarPor){
                    filtrosUrl += '&ordenarPor=' + filtros.ordenarPor;
                }

                if(filtros.datas){
                    filtrosUrl += '&dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                }

                if(filtros.medico != null && filtros.medico.id != null){
                    filtrosUrl += '&medicoId=' + filtros.medico.id;
                }

                if(filtros.convenio != null && filtros.convenio.id != null){
                    filtrosUrl += '&convenioId=' + filtros.convenio.id;
                }

                this.carregarLista(filtrosUrl);
                this.isFiltroModalActive = false;
            },
            filtrarModal(){
                this.isFiltroModalActive = true;
            },
            fecharModal(){
                this.isFiltroModalActive = false;
            },              
            ordenar(ordenar){
                this.filter.ordenarPor = ordenar;
                this.filtrar(this.filter);
            },
            pageChange(page){
                this.page = page;
                if(this.filter != null){
                    this.filtrar(this.filter);
                }
                else {
                    this.carregarLista('?page=' + page);
                }
                
            }
        },
        created() {
            this.carregarLista('?page=' + this.page);
            this.carregarPesquisas();
        }
    }
</script>