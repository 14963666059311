<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ titulo }}</p>
    </header>
    <section class="modal-card-body list is-paddingless is-marginless">
      <div class="panel-block">
        <div class="field">
          <b-switch :value="checked" @input="changed">
            Desativar usuários secundários</b-switch
          >
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-success"
        type="button"
        @click="
          executar();
        "
      >
        Confirmar
      </button>
      <button
        class="button is-pulled-right"
        type="button"
        @click="$parent.close()"
      >
        Fechar
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ['removeSecundarios','titulo','success'],
  computed: {},
  data(){
      return {
          checked: false
      }
  },
  mounted(){
      this.checked = this.removeSecundarios;
  },
  methods: {
    changed(event){
      this.checked = event;
    },
    executar() {
        this.$emit('update:removeSecundarios', this.checked);
        this.$emit('update:success', true);
        this.$parent.close();
    },
  },
};
</script>
