<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{title}}</p>
        </header>
        <section class="modal-card-body">
            <b-field grouped>
                <b-field :label="$t('MODALGEN.QUANTIDADE')">
                    <b-input
                        v-model="quantidade"
                        type="number"
                        >
                    </b-input>
                </b-field>

                <b-field :label="$t('MODALGEN.AGRUPAMENTO')">
                    <b-input
                        v-model="agrupamento"
                        type="number"
                        >
                    </b-input>
                </b-field>
            </b-field>
             <b-field :label="$t('MODALGEN.MODELOETIQUETA')">
                <b-select v-model="modeloSelecionado" expanded>
                    <option  v-for="(item, index) in modelos" v-bind:key="index" :value="item">
                        {{item}}
                    </option>
                </b-select>
            </b-field>

        </section>
        <footer class="modal-card-foot buttons is-right">
            <b-button
                :label="$t('MODALGEN.CANCELAR')"
                @click="$parent.close()" />
            <b-button
                @click="imprimeEtiquetasPreColeta"
                :label="$t('SISTEMA.CONFIRMAR')"
                type="is-primary" />
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            convenioId: Number,
            modelos: Array,            
        },
        data() {
            return {
                quantidade: null,
                agrupamento: null,
                modeloSelecionado: null,
            }
        },
        methods: {
            imprimeEtiquetasPreColeta() {
                if(this.quantidade == null) this.quantidade = 1;
                if(this.agrupamento == null) this.agrupamento = 1;

                this.$notificacaoHub.invoke("EtiquetaPreColeta", this.convenioId, parseInt(this.quantidade), parseInt(this.agrupamento), this.modeloSelecionado)
                .then(() => {
                    this.$buefy.snackbar.open({
                        duration: 2000,
                        message: this.$t('MODALGEN.IMPRIMIRETIQUETA'),
                        type: 'is-success',
                        position: 'is-top-right',
                        queue: false
                    })

                    this.$parent.close();
                })
                .catch(e => {
                    return console.error(e.toString());
                }) 
            }
        }
   
    }
</script>