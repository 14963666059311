<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        
        <b-progress type="is-info" size="is-large"  format="percent"
         :value="percentualConcluido" :precision="2" show-value></b-progress>        
        <div class="columns box">                        
            <div class="column is-5">
                <b-field expanded class="search-area">
                    <b-input :placeholder="$t('EXAMESLISTA.PROCURARPOR') +colunaSelecionada"
                             v-model="procurar"
                             icon="magnify"
                             expanded
                             icon-clickable
                             @icon-click="loadAsyncData"
                             @input="loadAsyncData"></b-input>
                    <b-dropdown aria-role="list" v-model="colunaSelecionada" expanded>
                        <button class="button is-primary dropdown-opcoes" slot="trigger" slot-scope="{ active }">
                            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                        </button>

                        <b-dropdown-item aria-role="listitem" value="Apelido">{{$t('EXAMESLISTA.APELIDO')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Nome">{{$t('EXAMESLISTA.NOME')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Sinonimia">{{$t('EXAMESLISTA.SINONIMIA')}}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" value="Setor">{{$t('EXAMESLISTA.SETOR')}}</b-dropdown-item>
                        <hr />
                        <b-dropdown-item aria-role="listitem" :value="coluna" v-for="coluna in colunas" :key="coluna.id">{{coluna.nome}}</b-dropdown-item>
                    </b-dropdown>                             
                </b-field>
            </div>
            <div class="column">
                <div class="is-flex is-justified-between">

                </div>
            </div>
            <div class="column is-2">
                <form @submit.prevent="abrirCadastroInput">
                    <b-field>
                        <b-input :placeholder="$t('EXAMESLISTA.CODIGO')"
                                 v-model="id"
                                 type="number"
                                 icon-right="edit"
                                 icon-right-clickable
                                 @icon-right-click="abrirCadastroInput">
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 :current-page.sync="page"
                 @page-change="onPageChange"
                 :aria-next-label="$t('EXAMESLISTA.PROXPAGINA')"
                 :aria-previous-label="$t('EXAMESLISTA.PAGANTERIOR')"
                 :aria-page-label="$t('EXAMESLISTA.PAGINA')"
                 :aria-current-label="$t('EXAMESLISTA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column field="id" :label="$t('EXAMESLISTA.ID')">                             
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.id }}
                    </template>                    
                </b-table-column>

                <b-table-column field="Apelido" :label="$t('EXAMESLISTA.APELIDO')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.apelido }}
                    </template>                                          
                </b-table-column>

                <b-table-column  field="Nome" :label="$t('EXAMESLISTA.NOME')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.nome }}
                    </template>                                          
                </b-table-column>

                <b-table-column field="" :label="$t('EXAMESLISTA.ADICIONAL')" >
                    <template v-slot:header="{  }">                                                                              
                            <b-field label="">
                                <b-select :placeholder="$t('EXAMESLISTA.SELECIONECOD')" v-model="colunaDinamica">
                                    <option
                                        v-for="option in colunas"
                                        :value="option.nome"
                                        :key="option.id">
                                        {{ option.nome }}
                                    </option>
                                </b-select>
                            </b-field>                                              
                    </template>
                    <template v-slot="props">
                        {{getCodigo(props.row)}}
                    </template>                                          
                </b-table-column>                          

                <b-table-column  field="Setor" :label="$t('EXAMESLISTA.SETOR')" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        {{ props.row.setor }}
                    </template>                                          
                </b-table-column>      

                <b-table-column  field="desativado" label="">
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props">
                        <b-icon v-show="props.row.desativado" type="is-danger" icon="close-circle" title="Desativado"></b-icon>
                    </template>                      
                    
                </b-table-column>

                <b-table-column  custom-key="actions" >
                    <template v-slot:header="{ column }">                        
                        {{ column.label }}                        
                    </template>
                    <template v-slot="props" >
                        <div style="display: flex;">
                            <b-button icon-left="close" 
                                      :loading="loading" 
                                      type="is-danger" 
                                      size="is-small" 
                                      :title="$t('EXAMESLISTA.NAOREALIZOEXAME')" 
                                      @click.prevent.stop="removerExame(props.row.id)">
                            </b-button>
                            <router-link :to="{ name: 'examevalidacao', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('EXAMESLISTA.EDITAR')">
                                <span @click="saveFilterParams">
                                    <b-icon icon="pencil" size="is-small"></b-icon>
                                </span>
                            </router-link>
                        </div>
                    </template>                      
                </b-table-column>
            </template>


        </b-table>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              native-type="button"
                              :loading="loading"
                              @click="proximoValidar()"
                              icon-left="check-circle">
                        {{$t('EXAMESLISTA.INICIARVALIDACAO')}}
                    </b-button>

                </p>
            </div>
        </nav>        
    </section>
</template>

<style lang="scss" scoped>
    .search-area {
        .dropdown {
            max-width: 2rem;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: this.$route.query.page ?? 1,
                perPage: 20,
                selecionado: null,
                id: null,
                colunaDinamica: null,
                colunas: [],
                colunaSelecionada: 'Apelido',
                percentualConcluido : 0.0
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('EXAMESLISTA.MANUTENCAO'),
                    this.$t('EXAMESLISTA.VALIDACAOEXAMES'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData: debounce(function () {
                let tipoCodigo = 0;
                let fieldName = this.colunaSelecionada;
                const telaValidacaoExames = true;
                const isObject = (obj) => {
                    const type = typeof obj;
                    return type === 'function' || type === 'object' && !!obj;
                };
                if (this.colunaSelecionada && isObject(this.colunaSelecionada)){
                    tipoCodigo = this.colunaSelecionada.id;
                    fieldName = `Codigo_${tipoCodigo}`;
                    this.selecionaColunaTabela();
                }
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `fieldName=${fieldName}`,
                    `tipoCodigo=${tipoCodigo}`,
                    `telaValidacaoExames=${telaValidacaoExames}`                    
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/exames?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})

                        this.colunas = this.model.codigos?.map(c => {
                            return {
                                ...c,
                                toString: () => {
                                    return c.nome;
                                }
                            }
                        });

                        this.percentualConcluido = ( ((data.totalExames - data.totalExamesValidacao)*100) / data.totalExames ).toFixed(2);

                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },1000),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.saveFilterParams();

                this.$router.push({ path: `/manutencao/examevalidacao/${this.selecionado.id}` })
            },
            abrirCadastroInput() {
                this.saveFilterParams();

                this.$router.push({ path: `/manutencao/examevalidacao/${this.id}` })
            },
            getCodigo(row){
                if (!this.colunaDinamica) return '';

                return row.codigos?.find(c => c.exameCodigoTipo?.nome === this.colunaDinamica)?.codigo;
            },
            selecionaColunaTabela(){
                if (this.colunaSelecionada){
                    this.colunaDinamica = this.colunaSelecionada.nome;
                }
            },
            removerExame(exameId){
                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('EXAMEVALIDACAO.MSGDELECAO'),
                    type: 'is-danger',
                    hasIcon: true,
                    cancelText: this.$t('EXAMEVALIDACAO.NAO'),
                    confirmText: this.$t('EXAMEVALIDACAO.SIM'),
                    onConfirm: () => {
                        this.loading = true;
                        this.$http.get(`/api/manutencao/DeletarExame?Id=${exameId}`)
                            .then(res => res.data)
                            .then(data => {
                                if(data){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('Exame deletado da base de dados!'),
                                        type: 'is-warning',
                                        queue: false
                                    })
                                    this.loading = false;
                                    this.loadAsyncData();
                                }
                                else{
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('Erro ao deletar exame da base de dados!'),
                                        type: 'is-warning',
                                        queue: false
                                    })
                                }
                            })
                            .catch(err => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: err.body.title,
                                    type: 'is-danger',
                                    queue: false
                                })
                            });
                    }
                });
            },
            proximoValidar(){            
                const fieldName = this.colunaSelecionada;
                
                const params = [
                    `procurar=${this.procurar}`,
                    `fieldName=${fieldName}`,                    
                ].join('&')

                this.saveFilterParams();

                this.loading = true;
                this.$http.get(`/api/manutencao/proximoExameValidar?${params}`)
                    .then(({ data }) => {
                        this.loading = false;
                        if(data && data>0)
                            this.$router.push({ 
                                name: 'examevalidacao', params: { id: data }
                            });
                    });
            },
            saveFilterParams(){
                localStorage.setItem('exameValidacaoProcurar', this.procurar);
                localStorage.setItem('exameValidacaocolunaSelecionada', this.colunaSelecionada);
            }
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>