import { Doughnut } from 'vue-chartjs'

export default {
    name: 'doughnut-chart',
    extends: Doughnut,
    props: ['chartData', 'chartId'],
    mounted() {
        if (this.chartData == undefined)
            return;

        this.renderChart({
            labels: this.chartData.map((item) => { return item.status }),
            datasets: [{
                data: this.chartData.map((item) => { return item.qtde }),
                backgroundColor: this.chartData.map((item) => { return item.cor })
            }]
        }, {
            responsive: true,
            legend: {
                display: false
            },
            animation: {
                animateScale: true,
                animateRotate: true
            },
            circumference: Math.PI,
            rotation: -Math.PI,
            onClick: this.handle
        })
    },
    methods: {
        handle(point, event) {
            this.$emit('on-selecionarSetorStatus', {
                chartId: this.chartId,
                label: event[0]._model.label
            })
        }
    }
}
