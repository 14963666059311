const adaptador = {
    adaptarDadosLancamentosFinanceiros()
        {
            return (lancamentos) => {
                return lancamentos.map(lancamento => {
                    const lancamentoAdaptado = {
                        id: null,
                        descricao: null,
                        parcelaId: null,
                        parcelaVencimento: null,
                        parcelaValor: null,
                        notaFiscal: null,
                        tipo: null
                    }
                    if (lancamento.parcelaPagar)
                    {
                        lancamentoAdaptado.id = lancamento.parcelaPagar.pagar.id
                        lancamentoAdaptado.descricao = lancamento.parcelaPagar.pagar.descricao
                        lancamentoAdaptado.parcelaId = lancamento.parcelaPagar.id
                        lancamentoAdaptado.parcelaValor = -lancamento.parcelaPagar.valor
                        lancamentoAdaptado.parcelaVencimento = lancamento.parcelaPagar.dataDeVencimento
                        lancamentoAdaptado.notaFiscal = lancamento.parcelaPagar.pagar.notaFiscal
                        lancamentoAdaptado.tipo = 'Pagar'
                    }
                    else
                    {
                        lancamentoAdaptado.id = lancamento.parcelaReceber.receber.id
                        lancamentoAdaptado.descricao = `${lancamento.parcelaReceber.receber.paciente.nome} / ${lancamento.parcelaReceber.receber.convenio.nome}`
                        lancamentoAdaptado.parcelaId = lancamento.parcelaReceber.id
                        lancamentoAdaptado.parcelaValor = lancamento.parcelaReceber.valor
                        lancamentoAdaptado.parcelaVencimento = lancamento.parcelaReceber.dataDeVencimento
                        lancamentoAdaptado.notaFiscal = lancamento.parcelaReceber.receber.notaFiscal
                        lancamentoAdaptado.tipo = 'Receber'
                    }

                    return lancamentoAdaptado
                })
            }
        }
}

export default adaptador