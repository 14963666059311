<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title"> {{ comprovante.nome }}</p>
        </header>
        <section class="modal-card-body">
            <div class="tile is-12" v-for="(m,index) in model" :key="index">
                <b-field :label="m.nome">
                    <b-input v-model="m.valor"></b-input>
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button v-show="!comprovante.rawPrinting" type="is-info" @click="verPdf()" icon-left="eye" :loading="loading">{{$t('COMPROVANTEVARIAVEL.VERPDF')}}</b-button>
            <b-button v-show="comprovante.rawPrinting" type="is-info" @click="imprimirRaw()" :loading="loading">{{$t('COMPROVANTEVARIAVEL.IMPRIMIR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('COMPROVANTEVARIAVEL.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            comprovante: Object,
            guia: Object
        },
        data() {
            return {
                model: [],
                loading: false
            }
        },
        mounted() {
            this.loadVariavel()
        },
        methods: {
            loadVariavel() {
                this.comprovante.variaveis.forEach(variavel => {
                    this.model.push({ nome: variavel.nome, valor: null });
                });
            },
            verPdf() {
                this.loading = true

                const href = "/Guia/Comprovante?id=" + this.guia.id + "&modelo=" + this.comprovante.nome;

                const formData = new FormData();
                this.model.forEach(m => {
                    formData.append(m.nome, m.valor);

                })

                this.$http.post(href, formData, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        const file = new Blob([data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                        this.$emit('close');

                    })
                    .catch((error) => {
                        this.erros.push(error)
                        this.$emit('close')
                    });

            },
            imprimirRaw() {
                this.loading = true

                this.$notificacaoHub.invoke("ComprovanteGuia",
                    this.model.guia.id,
                    this.comprovante.nome,
                    this.model.map(x => x.nome + "=" + x.valor))
                    .then(() => {
                        this.loadingImpressaoComprovante = false
                        this.$emit('close')
                    })
                    .catch(e => {
                        this.$emit('close')
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('COMPROVANTEVARIAVEL.MENSAGEMIMPRESSAO') + e.toString(),
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
            }
        }
    }
</script>