<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="tile is-ancestor centralizar">
            <div class="tile is-12 is-centered is-vertical is-parent">
                <div class="tile is-child box">
                <p class="title">{{subTitulo}}</p>
                </div>
            </div>
        </div>  
        

        <!-- LINHA CABEÇALHO -->
        <div class="b-table" >
            <div class="table-wrapper has-mobile-cards">
                <table class="table  is-hoverable">
                    <thead>
                        <th class="width-15">Unidade</th>                        
                        <th class="width-15">Nome do Convênio</th>                                                
                        <th class="width-5 centralizar">Exames</th>
                        <th class="width-5 centralizar">Exames (%)</th>
                        <th class="width-5 centralizar">Pacientes</th>
                        <th class="width-5 centralizar">Particular</th>                        
                        <th class="width-5 centralizar">Convênio</th>
                        <th class="width-5 centralizar">Desconto</th>
                        <th class="width-5 centralizar">Líquido</th>
                        <th class="width-5 centralizar">Faturado</th>
                    </thead>

                </table>
            </div>
        </div>


        <!-- LINHAS CONTEUDOS -->
        <div class="b-table"  v-for="item in listaUnidades" :key="item.unidadeId">
            <div class="table-wrapper has-mobile-cards">
                <table class="table  is-hoverable">
                    <thead>
                        <th class="tr-linhas">UNIDADE: {{item.unidadeNome}}</th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                        <th class="tr-linhas"></th>
                    </thead>
                    <tbody>                            
                            <tr v-for="convenio in lista.filter(x => x.unidadeDeCadastroId == item.unidadeId)" :key="convenio.convenioId">

                                <td  class="width-15"> {{ convenio.unidadeDeCadastroNome }} </td>
                                <td  class="width-15"> {{ convenio.convenioNome }} </td>                                
                                <td  class="width-5 centralizar"> {{ convenio.quantidadeTotalExames }} </td>
                                <td  class="width-5 centralizar"> {{ ((convenio.quantidadeTotalExames / item.quantidadeTotalExamesConvenio ) * 100).toFixed(2).replace('.', ',') }}% </td>
                                <td  class="width-5 centralizar"> {{ convenio.quantidadePacientes }} </td>
                                <td  class="width-5 centralizar"> {{ convenio.valorTotalParticular.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ convenio.valorTotalConvenio.toFixed(2).replace('.', ',') }} </td>                                
                                <td  class="width-5 centralizar"> {{ convenio.descontoTotalExames.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ ( convenio.valorTotalExames - convenio.descontoTotalExames).toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ convenio.valorTotalExamesFaturados.toFixed(2).replace('.', ',')  }} </td>
                            </tr>
                            
                            <tr class="tr-total">
                                <td  class="width-15"> TOTAL</td>
                                <td  class="width-15">  </td>
                                <td  class="width-5 centralizar"> {{ item.quantidadeTotalExamesConvenio }} </td>
                                <td  class="width-5 centralizar"> 100% </td>
                                <td  class="width-5 centralizar"> {{item.quantidadePacientesConvenio}} </td>
                                <td  class="width-5 centralizar"> {{ item.valorTotalExamesConvenioParticular.toFixed(2).replace('.', ',') }}  </td>
                                <td  class="width-5 centralizar"> {{ item.valorTotalExamesConvenio.toFixed(2).replace('.', ',') }}  </td>
                                <td  class="width-5 centralizar"> {{ item.descontoTotalExamesConvenio.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ ( (item.valorTotalExamesConvenioParticular + item.valorTotalExamesConvenio) - item.descontoTotalExamesConvenio).toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ item.valorTotalExamesFaturados.toFixed(2).replace('.', ',')  }} </td>
                            </tr>
                            

                    </tbody>
                </table>
            </div>
        </div>

        <!-- LINHA TOTAL GERAL -->
        <div class="b-table" >
            <div class="table-wrapper has-mobile-cards">
                <table class="table  is-hoverable">
                    <thead>
                            <tr class="tr-final">
                                <td  class="width-15"> TOTAL GERAL</td>
                                <td  class="width-15">  </td>
                                <td  class="width-5 centralizar"> {{ quantidadeTotalExamesGeral   }}  </td>
                                <td  class="width-5 centralizar">   </td>
                                <td  class="width-5 centralizar"> {{ quantidadePacientesGeral }} </td>
                                <td  class="width-5 centralizar"> {{ valorTotalExamesGeralParticular.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ valorTotalExamesGeralConvenio.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ descontoTotalExamesGeral.toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar"> {{ ( (valorTotalExamesGeralParticular + valorTotalExamesGeralConvenio) - descontoTotalExamesGeral).toFixed(2).replace('.', ',') }} </td>
                                <td  class="width-5 centralizar">  {{ quantidadeTotalExamesFaturadosGeral.toFixed(2).replace('.', ',')  }}</td>
                            </tr>
                    </thead>

                </table>
            </div>
        </div>        
        


        <nav class="level">
            <div class="level-item">
                <p class="buttons">

                    <b-button 
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>                    
                    <b-button type="is-primary"
                                native-type="button"
                                icon-left="download"
                                @click="downloadXls()">
                        Exportar
                    </b-button>                    

                </p>
            </div>
        </nav>
    </section>
</template>


<style >

    .width-15{
        width: 15% !important;
    }
    .width-5{
        width: 5% !important;
    }    
    .tr-final{
        background-color: #d9c8c8 !important;
        font-weight: bolder  !important;
    }
    .tr-linhas{
        background-color: #f8e5e5  !important;
        font-weight: bolder  !important;
    }    

    .tr-total{        
        font-weight: bolder  !important;
    }     

    .centralizar{
        text-align: center  !important;
    }

    .title{
        font-size: 1rem !important;
    }
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    
    export default {
        data() {
            return {
                lista: [],   
                listaUnidades:[],              
                valorTotalExamesGeralConvenio: 0.0,
                valorTotalExamesGeralParticular: 0.0,
                quantidadeTotalExamesGeral: 0.0,
                quantidadeTotalExamesFaturadosGeral: 0.0,
                descontoTotalExamesGeral: 0.0,
                quantidadePacientesGeral: 0,
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                isLoading: true,  
                subTitulo: null                     
            }
        },
        components: {
            titlebar        
        },
        computed: {
            titleStack() {
                return [
                    'Relatórios',
                    'Movimentação de Unidades por Convênio'
                ]
            }
        },
        mounted() {

            this.isLoading = true;
                                    
            if(document.getElementsByClassName('delete').length>0){
                document.getElementsByClassName('delete')[0].click();
            }

            this.$http.post('/api/relatorio/MovimentacaoConveniosUnidades', this.$route.query)
                .then(res => res.data)
                .then(data => {


                    this.lista = data.lista.filter(x=>x.unidadeDeCadastroId !=null);                                        
                    this.subTitulo = data.subTitulo; 
                    this.listaUnidades = data.unidades;      
                    
                    this.lista.forEach(x => {
                        if(x.particular){
                            this.valorTotalExamesGeralParticular += x.valorTotalExames;  
                            x.valorTotalConvenio =  0.0;
                            x.valorTotalParticular =  x.valorTotalExames;  
                        }else{
                            this.valorTotalExamesGeralConvenio += x.valorTotalExames;    
                            x.valorTotalParticular =  0.0;
                            x.valorTotalConvenio =  x.valorTotalExames;  
                        } 

                        this.quantidadeTotalExamesGeral += x.quantidadeTotalExames;
                        this.descontoTotalExamesGeral += x.descontoTotalExames;     
                        this.quantidadeTotalExamesFaturadosGeral += x.valorTotalExamesFaturados;   
                        this.quantidadePacientesGeral += x.quantidadePacientes;                        
                    }) 
                                
                    
                    
                    this.listaUnidades.forEach(u => {

                        const conveniosUnidade = this.lista.filter(x=>x.unidadeDeCadastroId == u.unidadeId );  

                        u.valorTotalExamesConvenio = u.quantidadeTotalExamesConvenio = u.descontoTotalExamesConvenio =u.valorTotalExamesConvenioParticular = u.valorTotalExamesFaturados = u.quantidadePacientesConvenio = 0;
                        conveniosUnidade.forEach(c => {
                    
                            u.valorTotalExamesConvenioParticular += c.valorTotalParticular;                         
                            u.valorTotalExamesConvenio += c.valorTotalConvenio;
                            u.valorTotalExamesFaturados += c.valorTotalExamesFaturados;
                            u.quantidadePacientesConvenio += c.quantidadePacientes;
                                                     
                            u.quantidadeTotalExamesConvenio += c.quantidadeTotalExames;
                            u.descontoTotalExamesConvenio += c.descontoTotalExames;                        

                        });



                    });    
                    
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.lista = [];
                    this.isLoading = false;
                    throw error
                })

        },

        methods: {

                downloadXls(){

                    const unidadeId =  this.$route.query.UnidadeId;
                    const dataInicial =  this.$route.query.dataInicial;
                    const dataFinal =  this.$route.query.dataFinal; 
                    let convenioId =  '';
                    
                    if(this.$route.query.convenioId){
                         this.$route.query.convenioId.forEach(x => {
                            convenioId += x+";";   
                         });
                    }else{
                        convenioId = '';
                       
                    }

                    const getParams = encodeURI("?unidadeId="+unidadeId+"&dataInicial="+dataInicial+"&dataFinal="+dataFinal+"&convenioId="+convenioId);
                    
                    window.open('/api/relatorio/movimentacaoconveniosunidadesxls'+getParams,'_blank');
                    
                }
        }
        
    }
</script>